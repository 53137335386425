import useCurrentUser from '@Employee/Employee/Hooks/UseCurrentUser';
import createRoomAvatarSrc from 'Modules/Chat/Func/CreateRoomAvatarSrc';
import createRoomName from 'Modules/Chat/Func/CreateRoomName';
import isRoomOnline from 'Modules/Chat/Func/IsRoomOnline';
import { OnlineStatus } from 'Modules/Chat/Types/Api';
import { Room } from 'Modules/Chat/Types/ApiModel';
import { ReactNode, useMemo } from 'react';
import { useRouteMatch } from 'react-router';

interface RoomInfo {
  online: boolean;
  name: string | undefined;
  isRead: boolean;
  isGroup: boolean;
  avatar: string | null | undefined | ReactNode;
}

export default function useRoomInfo(room: Room | undefined, onlineUsers: OnlineStatus[], unreadRooms: string[]): RoomInfo {
  const currentUser = useCurrentUser();
  const isCurrentlyActive = Boolean(useRouteMatch('/chat/' + room?.id) && room);

  return useMemo(() => {
    return {
      name: createRoomName(room, currentUser),
      online: isRoomOnline(room, currentUser, onlineUsers),
      isRead: Boolean(isCurrentlyActive || (room && !unreadRooms.includes(room['@id']))),
      isGroup: Boolean(room && room.members.length > 2),
      avatar: createRoomAvatarSrc(room, currentUser),
    };
  }, [currentUser, room, onlineUsers, isCurrentlyActive, unreadRooms]);
}

import useCurrentUser from '@Employee/Employee/Hooks/UseCurrentUser';
import { Feed, FeedType, MercureTokenPayload } from 'Modules/Mercure/Types/Model';
import { get } from 'helpers/Axios';
import jwt_decode from 'jwt-decode';
import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useRef } from 'react';

interface FeedSubscriber<T extends FeedType = FeedType> {
  (event: Feed<T>['payload']): void;
}

type FeedSubscribersMutator<T extends FeedType = FeedType> = (type: T, listener: FeedSubscriber<typeof type>) => void;

interface FeedSubscribers {
  addListener: FeedSubscribersMutator;
  removeListener: FeedSubscribersMutator;
}

type SubscriberStorageItem = {
  type: FeedType;
  subscriber: FeedSubscriber;
};

type SubscribersStorage = SubscriberStorageItem[];
const DEFAULT_LISTENER_CONTEXT_VALUE: FeedSubscribers = {
  addListener: () => void 0,
  removeListener: () => void 0,
};

const FeedSubscribersContext = createContext<FeedSubscribers>(DEFAULT_LISTENER_CONTEXT_VALUE);

export default function MercureFeedProvider({ children }: PropsWithChildren) {
  const previousContext = useContext(FeedSubscribersContext);

  useEffect(() => {
    if (previousContext !== DEFAULT_LISTENER_CONTEXT_VALUE) {
      throw new Error('Cannot use MercureFeedSubscriber as nested component.');
    }
  }, [previousContext]);
  const currentUser = useCurrentUser();
  const subscribersRef = useRef<SubscribersStorage>();
  const lastEventIdRef = useRef<string>();
  const eventSourceRef = useRef<EventSource>();

  const contextValue: FeedSubscribers = useMemo(() => {
    subscribersRef.current = [];
    return {
      addListener: (type, subscriber) => {
        subscribersRef.current?.push({ type, subscriber });
      },
      removeListener: (type, subscriber) => {
        subscribersRef.current = subscribersRef.current?.filter(item => item.subscriber !== subscriber);
      },
    };
  }, []);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    const connect = (token?: string): EventSource | undefined => {
      eventSourceRef.current?.close();
      if (!token) {
        get('/mercure-hub/token').then(token => {
          setTimeout(() => connect(token), 0);
        });

        return;
      }

      const payload = jwt_decode<MercureTokenPayload>(token);
      const url = new URL(process.env.REACT_APP_MERCURE_HUB_BASE_URL as string);
      lastEventIdRef.current && url.searchParams.append('lastEventID', lastEventIdRef.current);
      url.searchParams.append('authorization', token);
      payload.mercure.subscribe.forEach(topic => {
        url.searchParams.append('topic', topic);
      });

      const eventSource = new EventSource(url.toString());

      eventSource.onmessage = message => {
        lastEventIdRef.current = message.lastEventId;
        const feed: Feed = JSON.parse(message.data);
        subscribersRef.current?.forEach(item => {
          item.type === feed.type && item.subscriber(feed.payload);
        });
      };

      eventSource.onerror = function (errorEvent) {
        if (this.readyState === this.CLOSED) {
          const status: number = (errorEvent as any).status;
          status === 401 && connect();
        }
      };

      return (eventSourceRef.current = eventSource);
    };

    connect();

    return () => {
      eventSourceRef.current?.close();
    };
  }, [currentUser]);

  return <FeedSubscribersContext.Provider value={contextValue}>{children}</FeedSubscribersContext.Provider>;
}

export function useMercureFeedListener<T extends FeedType>(type: T, listener: FeedSubscriber<typeof type>, deps: any[]) {
  const feedSubscribersContext = useContext(FeedSubscribersContext);

  useEffect(() => {
    // @ts-ignore
    feedSubscribersContext.addListener(type, listener);
    // @ts-ignore
    return () => feedSubscribersContext.removeListener(type, listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedSubscribersContext, ...deps]);
}

import Contractor, { Contractor as ContractorModel } from '../Contractor/Contractor';
import DocumentNumber from './DocumentNumber';
import CheckboxColumn from '@Components/DataGrid/ColumnView/CheckboxColumn';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import { SelectColumnFilter, SelectModuleRecordColumnFilter } from 'components/DataGrid/Filter';

export type DocumentType = CTMRecord & {
  name?: string;
  defaultRecipient: ContractorModel;
  defaultSupplier: ContractorModel;
};

const columns: CTMListColumn<DocumentType>[] = [
  {
    id: 'name',
    Header: 'Nazwa',
    accessor: 'name',
    filterable: true,
    sortable: true,
  },
  {
    id: 'code',
    Header: 'Kod',
    accessor: 'code',
    filterable: true,
    sortable: true,
  },
  {
    id: 'internal',
    Header: 'Dokument wewnętrzny',
    sortable: false,
    accessor: ({ internal }) => <CheckboxColumn active={internal} />,
    filterable: true,
    Filter: SelectColumnFilter,
    filterOptions: [
      { value: null, label: 'Wszystkie' },
      { value: true, label: 'Tak' },
      { value: '0', label: 'Nie' },
    ],
  },
  {
    id: 'release',
    Header: 'Wydanie',
    sortable: false,
    accessor: ({ release }) => <CheckboxColumn active={release} />,
    filterable: true,
    Filter: SelectColumnFilter,
    filterOptions: [
      { value: null, label: 'Wszystkie' },
      { value: true, label: 'Tak' },
      { value: '0', label: 'Nie' },
    ],
  },
  {
    id: 'defaultRecipient.id',
    Header: 'Domyślny odbiorca',
    accessor: 'defaultRecipient.name',
    filterable: true,
    sortable: true,
    Filter: SelectModuleRecordColumnFilter,
    filterModule: Contractor,
  },
  {
    id: 'defaultSupplier.id',
    Header: 'Domyślny dostawca',
    accessor: 'defaultSupplier.name',
    filterable: true,
    sortable: true,
    Filter: SelectModuleRecordColumnFilter,
    filterModule: Contractor,
  },
  {
    id: 'number.id',
    Header: 'Numeracja',
    accessor: ({ number }) => (number ? `${number.name} [${number.format}]` : null),
    filterable: true,
    sortable: true,
    Filter: SelectModuleRecordColumnFilter,
    filterModule: DocumentNumber,
  },
];

const module: CTMModule<DocumentType> = {
  id: '86878a90-7e9e-482f-8f39-5083f58004e1',
  dataClass: 'CTM\\Warehouse\\Entity\\Document\\DocumentType',
  urlPrefix: 'warehouse-document-types',
  name: 'Typy dokumentów',
  role: 'WAREHOUSE_DOCUMENT',
  api: {
    item: {
      get: ({ id }) => `/warehouse/document-types/${id}`,
      put: ({ id }) => `/warehouse/document-types/${id}`,
      delete: ({ id }) => `/warehouse/document-types/${id}`,
    },
    collection: {
      get: `/warehouse/document-types`,
      post: `/warehouse/document-types`,
    },
  },
  recordLabel: ({ name }) => name,
  form: {
    prepareRecordToSave: record => ({
      ...record,
      number: record.number?.['@id'] ?? record.number ?? null,
      defaultSupplier: record.defaultSupplier?.['@id'] ?? record.defaultSupplier ?? null,
      defaultRecipient: record.defaultRecipient?.['@id'] ?? record.defaultRecipient ?? null,
    }),
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;

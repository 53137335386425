import { CustomFieldComponentInnerProps, CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import ListPicker from '@Components/Form/ListPicker';
import { Field } from '@CustomFields/Field';
import { CoreModuleModule } from '@Modules/CTMModules';
import { useEffect, useState } from 'react';

type FieldFilter = (field: Field) => boolean;

export const CustomFieldSelectorFactory = (
  props: CustomFieldComponentInnerProps<false, Field, any>,
  moduleId: string,
  filter: FieldFilter = () => true,
) => {
  return CustomFieldSelector({ ...props, moduleId, filter });
};

const CustomFieldSelector: CustomFieldComponentType<false, Field, { moduleId: string; filter: FieldFilter }> = props => {
  const { field, value, onChange, readonly, errorKey, moduleId, filter = () => true } = props;
  const [fields, setFields] = useState<Field[]>([]);

  useEffect(() => {
    if (fields.length === 0) {
      CoreModuleModule.api.get({ id: moduleId }).then(configuration => {
        setFields(configuration.fields.filter(filter));
      });
    }
  }, []);

  if (fields.length === 0) {
    return null;
  }

  return (
    <>
      <ListPicker<Field>
        value={value}
        label={field.name}
        options={fields}
        labelerId={field.labelerId}
        optionTrackBy="@id"
        errorKey={errorKey}
        disabled={readonly}
        optionLabel={fieldCfg => `(${fieldCfg.labelerId}) ${(fieldCfg.name ?? '') === '' ? 'Nazwa pola' : fieldCfg.name}`}
        onChange={selectedField => onChange(selectedField, field.id)}
        name={'field'}
      />
    </>
  );
};

export default CustomFieldSelector;

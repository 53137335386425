import { DataGridProps } from '../DataGrid';
import RecordInputReader from './RecordInputReader';
import { CTMRecord } from 'Modules/Core/Types/CTMModule';
import { confirmRemove } from 'common/sweetalerts';
import ListSelector, { ListSelectorRef } from 'components/Form/ListSelector';
import ModalFormWrapper, { ModalFormWrapperRef } from 'components/Form/ModalFormWrapper';
import ModuleForm from 'components/Module/ModuleForm';
import SecuredView from 'components/Theme/Common/SecuredView';
import { useModuleContext } from 'context/ModulesContext';
import { ComponentRef, ForwardedRef, createRef, forwardRef, memo, useImperativeHandle, useMemo, useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

const ModalActionsHeader = ({
  module,
  afterSave,
  list,
  onlyLabel = false,
  moduleFormComponent,
  moduleFormComponentProps,
  moduleFormWrapperProps,
}) => {
  const createModal = createRef<ModalFormWrapperRef>();
  if (!module) {
    return null;
  }

  const afterSaveCb = record => {
    afterSave(record);
    createModal?.current?.close();
  };

  if (onlyLabel || (!module.configuration.form && !moduleFormComponent)) {
    return <>Akcje</>;
  }

  if (moduleFormComponent) {
    moduleFormComponentProps = { ...moduleFormComponentProps, modal: createModal };
  }

  const ModuleFormComponent = moduleFormComponent ?? ModuleForm;

  return (
    <SecuredView role={module.configuration.roleEdit ?? `ROLE_EDIT_${module.configuration.role}`} alternativeContent={'Akcje'}>
      <ModalFormWrapper
        {...moduleFormWrapperProps}
        ref={createModal}
        title={`${module.configuration.name} - Dodaj`}
        form={
          <ModuleFormComponent
            list={list}
            {...moduleFormComponentProps}
            moduleName={module.configuration.urlPrefix}
            id={null}
            showContextActions={false}
            showBackButton={false}
            afterSave={afterSaveCb}
            readonly={false}
          />
        }
      >
        <Button className="btn btn-success btn-sm w-100" color="primary">
          <i className="mdi mdi-plus" />
          &nbsp;Dodaj nowy
        </Button>
      </ModalFormWrapper>
    </SecuredView>
  );
};

const ClearSelection = ({ listRef, onChange }) => {
  return (
    <Button
      color={'warning'}
      size="sm"
      className={'w-100'}
      onClick={() => {
        onChange(null);
        listRef.current?.close();
      }}
    >
      Kliknij aby wyczyścić pole
    </Button>
  );
};

const SelectMultipleValues = (listRef, onMultipleSelect) => {
  const SelectMultipleValuesComponent = ({ selectedFlatRows }) => {
    return (
      <Button
        color={'success'}
        size="sm"
        className={'w-100'}
        onClick={() => {
          onMultipleSelect(selectedFlatRows.map(row => row.original));
          listRef.current?.close();
        }}
      >
        Zatwierdź wybrane opcje
      </Button>
    );
  };

  return SelectMultipleValuesComponent;
};

type ModuleListPickerProps<T extends CTMRecord> = {
  moduleName: string;
  children?: any;
  onChange?: (record: T | null) => void;
  onMultipleSelect?: (records: T[]) => void;
  name?: string;
  label?: string;
  fallback?: string;
  disabled?: boolean;
  disableClear?: boolean;
  disableShowAction?: boolean;
  disableEditAction?: boolean;
  disableRemoveAction?: boolean;
  headerActionsOnlyLabel?: boolean;
  allowMultipleSelect?: boolean;
  selectedIds?: string[];
  overrideUrl?: string;
  moduleFormComponent?: any;
  moduleFormComponentProps?: any;
  moduleFormWrapperProps?: any;
  defaultFilters?: any;
  readerValue?: any;
  dataGridProps?: Partial<DataGridProps>;
};

type ModuleListPickerHandle = {
  open: (paremeters?: any[]) => void;
};
export type ModuleListPickerRef = ComponentRef<typeof ModuleListPicker>;

const ModuleListPicker = forwardRef<ModuleListPickerHandle, ModuleListPickerProps<any>>((props, ref) => {
  const {
    moduleName,
    children,
    onChange,
    onMultipleSelect,
    name = '',
    label = '',
    fallback = '',
    disabled = false,
    disableClear = false,
    disableShowAction = false,
    disableEditAction = false,
    disableRemoveAction = false,
    headerActionsOnlyLabel = false,
    allowMultipleSelect = false,
    selectedIds = [],
    overrideUrl,
    moduleFormComponent,
    moduleFormComponentProps = { overrideFormProps: { trackTabInUrl: false } },
    moduleFormWrapperProps,
    defaultFilters,
    dataGridProps,
    readerValue = null,
  } = props;
  const module = useModuleContext(moduleName);
  if (!module) {
    throw 'Module not found ' + moduleName;
  }
  const list = createRef<ListSelectorRef>();
  const editModal = createRef<ModalFormWrapperRef>();
  const showModal = createRef<ModalFormWrapperRef>();
  useImperativeHandle(ref, () => ({
    open(parameters) {
      list?.current?.open(parameters);
    },
  }));
  const modalListActions = useMemo(
    () => [
      {
        id: 'actionsStickyRight',
        Header: () => (
          <ModalActionsHeader
            list={list.current}
            module={module}
            onlyLabel={headerActionsOnlyLabel}
            moduleFormComponent={moduleFormComponent}
            moduleFormComponentProps={moduleFormComponentProps}
            moduleFormWrapperProps={moduleFormWrapperProps}
            afterSave={record => {
              onChange?.(record);
              list?.current?.close();
            }}
          />
        ),
        disableSortBy: true,
        disableFilters: true,
        disableExpandAction: true,
        accessor: row => (
          <div className={'actions'}>
            <ButtonGroup>
              {onChange && (
                <Button
                  onClick={() => {
                    onChange(row);
                    list.current?.close();
                  }}
                  className="btn btn-info btn-sm"
                >
                  <i className="mdi mdi-cursor-pointer" style={{ padding: '0 20px' }} />
                </Button>
              )}
              {module.configuration.form && (
                <>
                  {!disableShowAction && (
                    <ModalFormWrapper
                      {...moduleFormWrapperProps}
                      ref={showModal}
                      title={`${module.configuration.name} - ${module.configuration?.recordLabel?.(row, [])} - Podgląd`}
                      form={
                        <ModuleForm
                          moduleName={module.configuration.urlPrefix}
                          id={row.id}
                          showContextActions={false}
                          showBackButton={false}
                          showConfigurationSwitcher={false}
                          overrideFormProps={{ trackTabInUrl: false }}
                          afterSave={() => {
                            showModal.current?.close();
                            list.current?.refresh();
                          }}
                          readonly={true}
                        />
                      }
                      wrapperComponent={Button}
                      wrapperProps={{ className: 'btn btn-success btn-sm' }}
                    >
                      <i className="mdi mdi-eye" style={{ padding: '0 4px' }} />
                    </ModalFormWrapper>
                  )}
                  {!disableEditAction && (
                    <SecuredView role={module.configuration.roleEdit ?? `ROLE_EDIT_${module.configuration.role}`}>
                      <ModalFormWrapper
                        {...moduleFormWrapperProps}
                        ref={editModal}
                        title={`${module.configuration.name} - ${module.configuration.recordLabel?.(row, [])} - Edytuj`}
                        form={
                          <ModuleForm
                            moduleName={module.configuration.urlPrefix}
                            id={row.id}
                            showContextActions={false}
                            showBackButton={false}
                            overrideFormProps={{ trackTabInUrl: false }}
                            afterSave={() => {
                              editModal.current?.close();
                              list.current?.refresh();
                            }}
                            readonly={false}
                          />
                        }
                        wrapperComponent={Button}
                        wrapperProps={{ className: 'btn btn-primary btn-sm', color: 'primary' }}
                      >
                        <i className="mdi mdi-cogs" style={{ padding: '0 4px' }} />
                      </ModalFormWrapper>
                    </SecuredView>
                  )}
                  {!disableRemoveAction && (
                    <SecuredView role={`ROLE_REMOVE_${module.configuration.role}`}>
                      <Button
                        onClick={() => confirmRemove(() => module.api.delete({ id: row.id }).then(() => list.current?.refresh()))}
                        className="btn btn-danger  btn-sm"
                      >
                        <i className="mdi mdi-delete" style={{ padding: '0 4px' }} />
                      </Button>
                    </SecuredView>
                  )}
                </>
              )}
            </ButtonGroup>
          </div>
        ),
      },
    ],
    [list, module, onChange, moduleFormComponent, moduleFormWrapperProps],
  );
  const columns = useMemo(() => [...(module?.configuration?.list?.columns ?? []), ...modalListActions], [module, modalListActions]);
  const [queryKey] = useState(uuidv4());
  const computedDataGridProps = useMemo<DataGridProps>(
    () => ({
      url: overrideUrl ?? module.configuration.list?.url ?? module.api.getAllUrl,
      columns,
      className: 'table-clickable',
      responsive: true,
      allowChangeLimit: true,
      disableTop: true,
      disableHeader: false,
      disableFooter: false,
      fullWidth: true,
      defaultPerPage: 20,
      storeFilters: true,
      defaultFilters: defaultFilters ?? module.configuration.list?.defaultFilters,
      defaultOrderBy: module.configuration.list?.defaultOrderBy,
      renderRowSubComponent: params => module.configuration.list?.renderRowSubComponent?.(params, modalListActions),
      canRenderSubRow: module.configuration.list?.canRenderSubRow,
      selectionActions: allowMultipleSelect ? SelectMultipleValues(list, onMultipleSelect) : undefined,
      selectedIds: selectedIds,
      queryKey,
      ...(dataGridProps ?? {}),
    }),
    [columns, module, defaultFilters, dataGridProps],
  );

  let modalStyles = {};

  if (columns.length <= 2) {
    modalStyles = {
      maxWidth: '620px',
    };
  } else if (columns.length <= 3) {
    modalStyles = {
      maxWidth: '650px',
    };
  } else if (columns.length <= 5) {
    modalStyles = {
      maxWidth: '950px',
    };
  }

  return (
    <ListSelector
      ref={list}
      dataGridProps={computedDataGridProps}
      disabled={disabled}
      modalStyles={modalStyles}
      title={`${module.configuration.name} - Lista wyboru`}
      navbar={<>{!disableClear && <ClearSelection listRef={list} onChange={onChange} />}</>}
    >
      {typeof children !== 'undefined' && <>{children}</>}
      {typeof children === 'undefined' && (
        <RecordInputReader readonly={disabled} name={name} value={readerValue} module={module} label={label} fallback={fallback} />
      )}
    </ListSelector>
  );
});
ModuleListPicker.displayName = 'ModuleListPicker';

export default memo(ModuleListPicker) as unknown as <T extends CTMRecord>(
  props: ModuleListPickerProps<T> & { ref?: ForwardedRef<ModuleListPickerRef> },
) => any;

import CTMModule, { CTMListColumn, CTMRecord } from '@Core/Types/CTMModule';
import dayjs from 'dayjs';

export type Text = CTMRecord & {
  text?: string;
};

const columns: CTMListColumn<Text>[] = [
  {
    id: 'date',
    Header: 'Data',
    accessor: ({ date }) => (date ? dayjs(date).locale('pl').format('LLL') : null),
    filterable: false,
    sortable: false,
  },
  {
    id: 'text',
    Header: 'Wiadomość',
    accessor: 'text',
    filterable: false,
    sortable: false,
  },
  {
    id: 'phone',
    Header: 'Numer',
    accessor: 'phone',
    filterable: false,
    sortable: false,
  },
];

const module: CTMModule<Text> = {
  id: 'Text',
  dataClass: 'CTM\\Employee\\Model\\Text',
  urlPrefix: 'employee-text',
  name: 'Wiadomości zewnętrzne',
  role: 'EMPLOYEE',
  api: {
    item: {
      get: ({ id }) => `/employee/texts/${id}`,
      put: ({ id }) => `/employee/texts/${id}`,
      delete: ({ id }) => `/employee/texts/${id}`,
    },
    collection: {
      get: `/employee/texts`,
      post: `/employee/texts`,
    },
  },
  recordLabel: ({ name }) => name,
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [],
  },
};

export default module;

import CRUDModule from '@Core/CRUDModule';
import Employee from '@Employee/Employee/Employee';
import { Employee as EmployeeModel } from '@Employee/Employee/Types/Employee';
import Role, { Role as RoleModel } from '@Employee/Role/Role';
import Text, { Text as TextModel } from '@Employee/Text/Text';

export const EmployeeModule = new CRUDModule<EmployeeModel>(Employee);
export const RoleModule = new CRUDModule<RoleModel>(Role);
export const TextModule = new CRUDModule<TextModel>(Text);

export default {
  [Employee.urlPrefix]: EmployeeModule,
  [Text.urlPrefix]: TextModule,
  [Role.urlPrefix]: RoleModule,
};

//Core
export const GET_MEDIA = '/core/media-files';
export const POST_MEDIA = '/core/media-files';
export const DOWNLOAD_MEDIA = id => `/core/media-files/${id}/download`;
export const RESIZE_MEDIA = (id, width) => `/core/media-files/${id}/resize?width=${width}`;

export const GET_CONFIGURATION = '/core/configuration';
export const PUT_CONFIGURATION = '/core/configuration';
export const GET_APP_CONFIGURATION = '/core/app-configuration';

//CustomField
export const GET_MODULES = `/custom-fields/modules`;
export const GET_MODULE_IMAGES = (moduleId, objectId, format, x, y, name) =>
  `custom-fields/modules/${moduleId}/images/${objectId}.zip?format=${format}&x=${x}&y=${y}&name=${name}`;
export const GET_SECTION_CONTENT = (sectionId, objectId) => `custom-fields/sections/${sectionId}/content/${objectId}.zip`;

// Warehouse / DocumentType
export const GET_WAREHOUSE_DOCUMENT = id => `/warehouse/documents/${id}`;
export const POST_WAREHOUSE_DOCUMENT = `/warehouse/documents`;
export const PUT_WAREHOUSE_DOCUMENT = id => `/warehouse/documents/${id}`;
export const DELETE_WAREHOUSE_DOCUMENT = id => `/warehouse/documents/${id}`;

import { ToastType } from './reducer';
import { ADD_TOAST, REMOVE_TOAST_FROM_QUEUE } from 'store/Toast/actionTypes';

export const removeToastFromQueue = (id: string) => {
  return {
    type: REMOVE_TOAST_FROM_QUEUE,
    payload: id,
  };
};

export const addSingleToast = (toast: ToastType) => {
  return {
    type: ADD_TOAST,
    payload: [toast],
  };
};

import { ModuleProvider } from '../../../context/ModuleContext';
import useCurrentUser from '@Employee/Employee/Hooks/UseCurrentUser';
import { DictionaryCategoryModule, DictionaryModule } from 'Modules/CTMModules';
import ApiForm from 'components/Theme/Common/ApiForm';
import SecuredView from 'components/Theme/Common/SecuredView';
import useErrorHandler from 'helpers/FormHandler';
import { Fragment, memo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { addSingleToast } from 'store/Toast/actions';

const ContextActionsComponent = ({ readonly, row, module }) => {
  const {
    id,
    category: { id: categoryId },
  } = row;
  const EditWrapper = module.configuration.editButtonWrapper ?? Fragment;
  return (
    <>
      {readonly && id && (
        <EditWrapper row={row}>
          <SecuredView role={module.configuration.roleEdit ?? `ROLE_EDIT_${module.configuration.role}`}>
            <Link to={`/modules/core-dictionary/edit/${categoryId}/${id}`} className="btn btn-primary ml-1">
              <i className="mdi mdi-pencil" /> Edycja
            </Link>
          </SecuredView>
        </EditWrapper>
      )}
      {!readonly && id && (
        <SecuredView role={`ROLE_SHOW_${module.configuration.role}|ROLE_EDIT_${module.configuration.role}`}>
          <Link to={`/modules/core-dictionary/show/${categoryId}/${id}`} className="btn btn-primary ml-1">
            <i className="mdi mdi-eye" /> Podgląd
          </Link>
        </SecuredView>
      )}
    </>
  );
};

export const ContextActions = memo(ContextActionsComponent);

const CategoryForm = ({ afterSave, onError, showBackButton = true, readonly = true, showConfigurationSwitcher = true }) => {
  const { categoryId, id } = useParams();
  const dispatch = useDispatch();
  const ApiFormComponent = useRef();
  const errorHandler = useErrorHandler();
  const history = useHistory();
  const currentUser = useCurrentUser();

  const onSubmit = (record, allFields) => {
    const data = DictionaryModule.configuration.form?.prepareRecordToSave
      ? DictionaryModule.configuration.form.prepareRecordToSave(record, allFields)
      : record;

    return new Promise(res => {
      if (id) {
        DictionaryModule.api
          .put(data, { id })
          .then(res => {
            dispatch(addSingleToast({ title: `Zapisano zmiany`, config: { appearance: 'success' } }));
            if (typeof afterSave === 'function') {
              afterSave(res, 'PUT');
            }
          })
          .catch(err => {
            errorHandler(err);
            if (typeof onError === 'function') {
              onError(err);
            } else {
              throw err;
            }
          })
          .finally(() => res(true));
      } else {
        DictionaryModule.api
          .post(data)
          .then(res => {
            dispatch(addSingleToast({ title: `Dodano ${DictionaryModule.configuration.name}`, config: { appearance: 'success' } }));
            if (typeof afterSave === 'function') {
              afterSave(res, 'POST');
            }
            history.push(`/modules/core-dictionary/edit/${categoryId}/${res.id}`);
          })
          .catch(err => {
            errorHandler(err);
            if (typeof onError === 'function') {
              onError(err);
            } else {
              throw err;
            }
          })
          .finally(() => res(true));
      }
    });
  };

  const fetch = async fields => {
    if (!id) {
      let defaultRecord = DictionaryModule.configuration.form?.defaultRecord([], currentUser, {}, {}) ?? {};
      defaultRecord['@formValues'] = defaultRecord['@formValues'] ?? {};
      defaultRecord.category = await DictionaryCategoryModule.api.get({ id: categoryId });
      defaultRecord['@formValues'][fields.find(el => el.propertyPath === 'category')?.id ?? ''] = defaultRecord.category;
      return defaultRecord;
    }
    return DictionaryModule.api.get({ id });
  };

  return (
    <ModuleProvider value={DictionaryModule}>
      <ApiForm
        key={`api-form-${DictionaryModule.configuration.dataClass}-${id}-${categoryId}`}
        ref={ApiFormComponent}
        dataClass={encodeURIComponent(DictionaryModule.configuration.dataClass)}
        recordCallback={async fields => fetch(fields)}
        onSubmit={onSubmit}
        readonly={readonly}
        showBackButton={showBackButton}
        moduleId={DictionaryModule.configuration.id}
        backUrl={`/modules/core-dictionary/list/${categoryId}`}
        showConfigurationSwitcher={showConfigurationSwitcher}
        recordLabel={DictionaryModule.configuration.recordLabel}
        customTabs={DictionaryModule.configuration.form.customTabs}
        fieldComponents={DictionaryModule.configuration.form.fieldComponents ?? {}}
        contextActions={record => <ContextActions readonly={readonly} row={record} module={DictionaryModule} />}
      />
    </ModuleProvider>
  );
};

export default CategoryForm;

import { Col, Container } from '@Components/Theme/Grid';
import useCurrentUser from '@Employee/Employee/Hooks/UseCurrentUser';
import DefaultWarehouseSelector from '@Warehouse/Components/Warehouse/DefaultWarehouseSelector';
import { useWarehouse } from '@Warehouse/Warehouse';
import { Alert, Tooltip } from '@mui/material';
import { useDocumentTypes } from 'pages/Warehouse/Document/useDocumentTypes';
import { Link } from 'react-router-dom';
import { Card } from 'reactstrap';

const CreateDocument = () => {
  const currentUser = useCurrentUser();
  const { isLoading: isLoadingDocumentTypes, data: documentTypes } = useDocumentTypes();
  const { isLoading: isLoadingWarehouse, data: warehouse } = useWarehouse(currentUser.defaults?.warehouse ?? '');

  if (!currentUser.defaults?.warehouse) {
    return (
      <div>
        <Container justifyContent="center">
          <Col xs={12} justifyContent="center" display="flex">
            <h3 className="text-center">Nie wybrano domyślnego magazynu</h3>
          </Col>
          <Col xs={12} justifyContent="center" display="flex">
            <div style={{ maxWidth: 600 }}>
              <Alert variant="outlined">
                Wybór zostanie zapamiętany, aby zmienić domyślnie wybrany magazyn w przyszłości kliknij w swoją nazwę uzytkownika w górnym
                prawym rogu
              </Alert>
            </div>
          </Col>
          <Col xs={12} justifyContent="center" display="flex">
            <div style={{ minWidth: 250, maxWidth: 400 }}>
              <DefaultWarehouseSelector />
            </div>
          </Col>
        </Container>
      </div>
    );
  }

  if (isLoadingDocumentTypes || isLoadingWarehouse || !warehouse || !documentTypes) return 'Loading...';

  const getColor = string => {
    let hash = 0;

    string.split('').forEach(char => {
      hash = char.charCodeAt(0) + ((hash << 5) - hash);
    });

    let colour = '#';

    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      colour += value.toString(16).padStart(2, '0');
    }

    return colour;
  };

  return (
    <>
      <h3 className="text-center">"{warehouse.name}"</h3>
      <div className={'d-flex justify-content-center w-100'} key={warehouse.id}>
        <div className={'flex-wrap justify-content-evenly'} style={{ maxWidth: 1280, border: '2px solid #495057' }}>
          {documentTypes.map(documentType => (
            <Tooltip title={documentType.name} placement={'bottom'} key={documentType.id} arrow={true}>
              <div style={{ padding: 20 }}>
                <Link to={`/warehouse/documents/add/${documentType.id}?warehouse=${warehouse.id}`}>
                  <Card className="document_type-card">
                    <div className="icon" style={{ backgroundColor: getColor(documentType.name) }}>
                      {documentType.code} - {documentType.name}
                    </div>
                  </Card>
                </Link>
              </div>
            </Tooltip>
          ))}
        </div>
      </div>
    </>
  );
};

export default CreateDocument;

import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import { Unit } from '@Manufacture/Unit';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import NumberInput from 'components/Form/MUI/NumberInput';
import { Col, Container } from 'components/Theme/Grid';
import { useModuleContext } from 'context/ModulesContext';
import { useCallback, useEffect, useState } from 'react';

const CalculatorsField: CustomFieldComponentType = ({ field, value, onChange, recordId }) => {
  const UnitsModule = useModuleContext<any, true>('manufacture-units');
  const [units, setUnits] = useState<Unit[]>([]);
  useEffect(() => {
    UnitsModule.api.getAll({ params: { page: 1, limit: 1000 } }).then(res => {
      setUnits(res['hydra:member']);
      if ((value ?? []).length === 0) {
        onChange(
          res['hydra:member'].map(el => ({
            targetUnit: el['@id'],
            calculator: 1,
          })),
          field.id,
        );
      }
    });
  }, []);

  const resolveValue = unitId => {
    return (value ?? []).find(el => el.targetUnit === unitId)?.calculator ?? 1;
  };

  const handleUpdateCalculator = useCallback(
    (unitId, val) => {
      const newValues = [...(value ?? [])];

      const editIndex = newValues.findIndex(el => el.targetUnit === unitId);

      if (editIndex >= 0) {
        newValues[editIndex].calculator = val ?? 1;
      } else {
        newValues.push({ targetUnit: unitId, calculator: val ?? 1 });
      }
      onChange(newValues, field.id);
    },
    [value],
  );

  if (units.length === 0) {
    return null;
  }
  return (
    <Container>
      <Col xs={12} md={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head" colSpan={2} style={{ textAlign: 'center' }}>
                Przelicz wartości w stosunku 1:n
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" style={{ textAlign: 'right' }}>
                Jednostka
              </TableCell>
              <TableCell variant="head" style={{ textAlign: 'left' }}>
                Wartość
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {units
              .filter(el => el['@id'] !== recordId)
              .map((el, i) => (
                <TableRow key={el['@id']}>
                  <TableCell style={{ textAlign: 'right' }}>{el.name}</TableCell>
                  <TableCell style={{ textAlign: 'left' }}>
                    <NumberInput onChange={value => handleUpdateCalculator(el['@id'], value)} value={resolveValue(el['@id'])} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Col>
    </Container>
  );
};

export default CalculatorsField;

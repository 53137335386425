import ColoredIconBox from '@Components/Theme/Common/ColoredIconBox';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MouseEvent, useState } from 'react';

type MenuPickerOption<OptionType = null | undefined | number | string> = {
  label: string;
  value: OptionType;
  background?: null | string;
  icon?: null | string;
  color?: string | null;
};

type MenuPickerProps<OptionType = null | undefined | number | string> = {
  value: MenuPickerOption<OptionType> | OptionType;
  options: MenuPickerOption<OptionType>[];
  placeholder: MenuPickerOption<OptionType>;
  onChange?: (value: MenuPickerOption<OptionType>) => void;
  defaultBackground?: string | null;
  defaultColor?: string | null;
};

export default function MenuPicker<OptionType = null | undefined | number | string>(props: MenuPickerProps<OptionType>) {
  const { value, options, placeholder, onChange, defaultBackground, defaultColor } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectValue = (option: MenuPickerOption<OptionType>) => {
    onChange?.(option);
    handleClose();
  };

  const selectedOption = !value
    ? placeholder
    : options.find(option => {
        if (typeof value === 'object' && value.hasOwnProperty('value')) {
          return option.value === (value as MenuPickerOption<OptionType>).value;
        }

        return option.value === value;
      }) ?? placeholder;

  return (
    <div>
      <ColoredIconBox
        value={selectedOption.label}
        background={selectedOption.background ?? defaultBackground}
        icon={selectedOption.icon}
        color={selectedOption.color ?? defaultColor}
        onClick={handleClick}
        style={{ cursor: 'pointer' }}
      />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map((option, index) => (
          <MenuItem key={index} onClick={() => handleSelectValue(option)}>
            <ColoredIconBox
              value={option.label}
              background={option.background ?? defaultBackground}
              color={option.color ?? defaultColor}
              icon={option.icon}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

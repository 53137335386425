import fieldTypes from '@CustomFields/const/FieldTypes';
import Button from '@mui/material/Button';
import ListPicker from 'components/Form/ListPicker';
import CheckboxInput from 'components/Form/MUI/CheckboxInput';
import TextInput from 'components/Form/MUI/TextInput';
import { Col, Container } from 'components/Theme/Grid';
import FieldCondition from 'pages/Configuration/CustomFields/FieldCondition';
import TypeConfiguration, { DEFAULT_CONFIG } from 'pages/Configuration/CustomFields/TypeConfiguration';
import React, { useState } from 'react';

const MULTI_LANGUAGE_TYPES = ['STRING', 'TEXT', 'COLOR'];

function FieldConfiguration(props) {
  const { field: givenField, onClose, fields, moduleId, onSave } = props;
  const [field, setField] = useState<any>(givenField ?? {});

  const updateFormValue = (value, changedField) => {
    setField({ ...field, [changedField]: value });
  };
  const handleChangeType = (value, changedField) => {
    let config = field.config ?? {};
    if (value !== field.type && DEFAULT_CONFIG.hasOwnProperty(value)) {
      config = DEFAULT_CONFIG[value];
    }

    setField({ ...field, [changedField]: value, config });
  };
  const TypeConfigurationComponent = TypeConfiguration[field.type] ?? React.Fragment;

  const TypeConfigurationProps = TypeConfiguration.hasOwnProperty(field.type)
    ? {
        system: field.system,
        configuration: field.config,
        fields: fields.filter(el => el['@id'] !== field['@id']),
        onConfigurationUpdated: cfg => updateFormValue(cfg, 'config'),
        moduleId: moduleId,
      }
    : {};

  return (
    <Container>
      <Col xs={12} lg={4}>
        <ListPicker
          name="type"
          value={field.type}
          options={fieldTypes}
          optionLabel="label"
          optionTrackBy="val"
          onChange={handleChangeType}
          disabled={field.system}
        />
      </Col>
      <Col xs={12} lg={8}>
        <TextInput name={'name'} value={field.name} disabled={field.system} label={'Nazwa pola'} onChange={updateFormValue} />
      </Col>
      {MULTI_LANGUAGE_TYPES.includes(field.type) && (
        <Col xs={12}>
          <CheckboxInput
            name={'multiLanguage'}
            value={field.multiLanguage}
            disabled={false}
            label={'Pole wielojęzyczne'}
            onChange={updateFormValue}
          />
        </Col>
      )}
      <Col xs={12}>
        <TypeConfigurationComponent {...TypeConfigurationProps} />
      </Col>
      <Col xs={12}>
        <FieldCondition
          value={field.hideConditions ?? []}
          fields={fields.filter(el => el.type === 'READONLY' || el['@id'] != (field?.['@id'] ?? ''))}
          onChange={newVal => updateFormValue(newVal, 'hideConditions')}
          templates={fields
            .filter(el => (el.hideConditions?.length ?? 0) > 0)
            .map(el => ({
              name: el.name ?? 'Nazwa pola',
              value: el.hideConditions.map(hideCondition => ({
                comparedField: hideCondition.comparedField,
                compareMethod: hideCondition.compareMethod,
                value: hideCondition.value,
              })),
            }))}
        />
      </Col>
      <Col xs={12} display="flex" justifyContent="space-between">
        <Button variant="contained" color="info" onClick={() => onClose()}>
          Zamknij bez zapisywania
        </Button>
        <Button variant="contained" color="success" onClick={() => onSave(field)}>
          Zapisz zmiany
        </Button>
      </Col>
    </Container>
  );
}

export default FieldConfiguration;

import { InvoiceGroupProps } from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceDefaultSection';
import InvoiceField from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceField';
import { Col, Container } from '@Components/Theme/Grid';
import CurrencyExchange from '@Core/Services/CurrencyExchange';
import { Paper, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { FC, useEffect } from 'react';

const InvoiceGeneralFieldsGroup: FC<InvoiceGroupProps> = props => {
  const exchangeDate = dayjs(props.values[props.fields.sellDate.id] ?? new Date()).format('YYYY-MM-DD');
  const currency = props.values[props.fields.currency.id] ?? 'PLN';
  const income = props.values[props.fields.income.id];
  useEffect(() => {
    CurrencyExchange.getCurrencyExchangeRate('PLN', currency, exchangeDate).then(result => {
      props.onUpdate(result.getMid(), props.fields.exchangeRate.id);
      props.onUpdate(result.getDate(), props.fields.exchangeInfoDate.id);
    });
  }, [currency, exchangeDate]);

  return (
    <Paper sx={{ marginBottom: 2, minHeight: 180 }}>
      <Container>
        <Col xs={12}>
          <Typography variant="subtitle1" align="center">
            Ogólne
          </Typography>
        </Col>
        <Col xs={12} md={3}>
          <InvoiceField {...props} fieldKey={'status'} />
        </Col>
        <Col xs={12} md={6}>
          <InvoiceField {...props} fieldKey={'number'} readonly={props.readonly || income} />
        </Col>
        <Col xs={12} md={3}>
          <InvoiceField {...props} fieldKey={'currency'} />
        </Col>
        <Col xs={12} md={3}>
          {props.values[props.fields.currency.id] !== 'PLN' && <InvoiceField key={currency} {...props} fieldKey={'exchangeRate'} />}
        </Col>
        <Col xs={12} md={3}>
          {props.values[props.fields.currency.id] !== 'PLN' && <InvoiceField key={currency} {...props} fieldKey={'exchangeInfoDate'} />}
        </Col>
      </Container>
    </Paper>
  );
};

export default InvoiceGeneralFieldsGroup;

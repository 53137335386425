import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { ModuleProvider } from 'context/ModuleContext';
import { useModuleContext } from 'context/ModulesContext';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function RawTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ display: 'flex', flex: '1 100%' }}
    >
      {value === index && <Box sx={{ p: 3, flex: '1 100%' }}>{children}</Box>}
    </div>
  );
}

function Configuration() {
  const { module } = useParams<{ id?: string; module?: string }>();
  const moduleContext = useModuleContext<any, true>(module ?? '', 'id', true);
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (!moduleContext?.configuration.configuration) {
    return <Alert severity="error">Wybrany moduł nie posiada opcji konfiguracji</Alert>;
  }

  const sections = moduleContext.configuration.configuration;
  const sectionKeys = Object.keys(sections);

  return (
    <ModuleProvider value={moduleContext}>
      <Box sx={{ bgcolor: 'background.paper', height: '100%' }}>
        <Box
          sx={theme => ({
            flexGrow: 1,
            backgroundColor: theme.palette.primary.main,
            display: 'flex',
            height: '100%',
            textAlign: 'center',
            padding: 1,
          })}
        >
          <Typography textAlign="center" variant="h6" color="white">
            Ustawienia modułu {moduleContext?.configuration.name}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100%' }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            {sectionKeys.map((el, index) => (
              <Tab key={el} label={sections[el].name} value={index} />
            ))}
          </Tabs>
          {sectionKeys.map((el, index) => {
            const ConfigurationComponent = sections[el].component;

            return (
              <RawTabPanel key={el} value={value} index={index}>
                <ConfigurationComponent />
              </RawTabPanel>
            );
          })}
        </Box>
      </Box>
    </ModuleProvider>
  );
}

export default Configuration;

import Loader from '@Components/Theme/Common/Loader';
import ListPicker from 'components/Form/ListPicker';
import * as url from 'helpers/Api/Url';
import { get } from 'helpers/Axios';
import { useEffect, useState } from 'react';

const RelationTypeConfiguration = ({ configuration, onConfigurationUpdated, system }) => {
  const [modules, setModules] = useState([]);

  const updateConfiguration = (value, field) => {
    onConfigurationUpdated({ ...configuration, [field]: value });
  };

  useEffect(() => {
    const fetchModules = async () => {
      const modulesResponse = await get(url.GET_MODULES, { params: { pagination: false, limit: 1000 } });
      setModules(modulesResponse['hydra:member'].map(el => ({ label: el.name, val: el.dataClass })));
    };
    if (modules.length === 0) {
      fetchModules();
    }
  }, []);

  if (modules.length === 0) {
    return <Loader />;
  }

  return (
    <ListPicker
      label={'Moduł powiązania'}
      name="targetModule"
      value={configuration?.targetModule ?? null}
      options={modules}
      optionLabel="label"
      optionTrackBy="val"
      onChange={updateConfiguration}
      disableGroupMargin={true}
      disabled={system}
    />
  );
};

export default RelationTypeConfiguration;

import useIsGranted from 'common/hooks/isGranted';
import React from 'react';

const EditWrapper = ({ row, children }: { row?: { id: 'string'; locked: boolean }; children: JSX.Element }): null | JSX.Element => {
  const isGrantedToLocked = useIsGranted('ROLE_LOCK_MANUFACTURE_PRODUCT');

  if (!isGrantedToLocked && row?.locked) {
    return null;
  }
  return <React.Fragment>{children}</React.Fragment>;
};

export default EditWrapper;

import { ModuleProvider } from '../../context/ModuleContext';
import { useModuleContext } from '../../context/ModulesContext';
import ModuleTemplatesBuilder, { ModuleTemplatesBuilderHandle } from 'Modules/CustomFields/ModuleTemplatesBuilder';
import { Col, Container } from 'components/Theme/Grid';
import { useRef } from 'react';
import { withRouter } from 'react-router-dom';

const SingleModuleConfiguration = ({ match }) => {
  const module = useModuleContext<any, true>(match?.params?.module ?? '', 'id', true);
  const moduleTemplatesBuilder = useRef<ModuleTemplatesBuilderHandle>(null);
  return (
    <Container>
      <Col xs={12} justifyItems={'center'} style={{ textAlign: 'center' }}>
        <ModuleProvider value={module}>
          <ModuleTemplatesBuilder ref={moduleTemplatesBuilder} afterSave={() => false} initialOpened={true}>
            <button className="btn btn-primary ml-1" onClick={() => moduleTemplatesBuilder.current?.open()}>
              <i className={'mdi mdi-arrow-left-bold-box-outline'} /> Otwórz tryb konfiguracji ponownie
            </button>
          </ModuleTemplatesBuilder>
        </ModuleProvider>
      </Col>
    </Container>
  );
};

export default withRouter(SingleModuleConfiguration);

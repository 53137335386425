import { InvoicePosition, sumPositions } from '@Accountancy/Invoice/Invoice';
import { InvoiceGroupProps } from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceDefaultSection';
import NumberInput from '@Components/Form/MUI/NumberInput';
import TextInput from '@Components/Form/MUI/TextInput';
import ModuleListPicker from '@Components/Module/ModuleListPicker';
import { Col, Container } from '@Components/Theme/Grid';
import { Product } from '@Manufacture/Product';
import { DeleteForeverOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Button, InputAdornment, Paper, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC, useEffect } from 'react';

type PositionsRowProps = InvoiceGroupProps & {
  position: InvoicePosition;
  index: number;
  onRowRemove: (index: number) => void;
  onRowUpdate: (index: number, position: InvoicePosition) => void;
};

const FooterCell = styled(TableCell)(({ theme }) => ({
  fontSize: '0.9rem',
}));
const PlaceholderCell = styled(TableCell)(({ theme }) => ({
  border: 'none',
}));

const roundMoney = (value: string | number) => parseFloat(`${parseFloat(`${value}`).toFixed(2)}`);

const PositionsRow: FC<PositionsRowProps> = props => {
  const currency: string = props.values?.[props.fields.currency.id] ?? [];
  const exchangeCurrency: string = props.values?.[props.fields.exchangeCurrency.id] ?? [];

  const handleInputChange = (value, name: keyof InvoicePosition) => {
    switch (name) {
      case 'priceTotalGross':
        handleInputChange(roundMoney(value / (1 + (props.position.tax?.value ?? 0) / 100)), 'priceTotalNet');
        break;
      case 'priceTotalNet':
        handleInputChange(roundMoney(value / props.position.quantity), 'priceUnitNet');
        break;
      case 'quantity':
        props.onRowUpdate(props.index, {
          ...props.position,
          quantity: value,
          priceTotalNet: roundMoney(props.position.priceUnitNet * value),
          priceUnitGross: roundMoney(props.position.priceUnitNet * (1 + (props.position.tax?.value ?? 0) / 100)),
          priceTotalGross: roundMoney(props.position.priceUnitNet * value * (1 + (props.position.tax?.value ?? 0) / 100)),
        });
        break;
      case 'priceUnitNet':
        props.onRowUpdate(props.index, {
          ...props.position,
          priceUnitNet: roundMoney(value),
          priceTotalNet: roundMoney(value * props.position.quantity),
          priceUnitGross: roundMoney(value * (1 + (props.position.tax?.value ?? 0) / 100)),
          priceTotalGross: roundMoney(value * props.position.quantity * (1 + (props.position.tax?.value ?? 0) / 100)),
        });
        break;
      case 'tax':
        props.onRowUpdate(props.index, {
          ...props.position,
          tax: value,
          priceUnitGross: roundMoney(props.position.priceUnitNet * (1 + (value?.value ?? 0) / 100)),
          priceTotalGross: roundMoney(props.position.priceUnitNet * props.position.quantity * (1 + (value?.value ?? 0) / 100)),
        });
        break;
      default:
        props.onRowUpdate(props.index, { ...props.position, [name]: value });
        break;
    }
  };

  const handleProductSelect = (row: null | Product) => {
    if (!row) {
      props.onRowUpdate(props.index, { ...props.position, product: null, name: '' });
      return;
    }

    props.onRowUpdate(props.index, { ...props.position, product: row, name: row.name ?? '', unit: row.unit });
  };

  return (
    <>
      <TableRow>
        <TableCell sx={{ width: 40, textAlign: 'center' }}>
          <DeleteForeverOutlined color="error" cursor="pointer" onClick={() => props.onRowRemove(props.index)} />
        </TableCell>
        <TableCell>
          <TextInput
            disabled={props.readonly}
            value={props.position.name}
            onChange={val => handleInputChange(val, 'name')}
            inputProps={{
              endAdornment: !props.readonly && (
                <InputAdornment position="end">
                  <ModuleListPicker<Product>
                    disabled={props.readonly}
                    moduleName={'manufacture-products'}
                    onChange={handleProductSelect}
                    readerValue={null}
                  >
                    <ListAltIcon color="primary" cursor="pointer" />
                  </ModuleListPicker>
                </InputAdornment>
              ),
            }}
          />
        </TableCell>
        <TableCell sx={{ width: 80 }}>
          <NumberInput
            disabled={props.readonly}
            value={props.position.quantity}
            onChange={val => handleInputChange(val, 'quantity')}
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ModuleListPicker
                    disabled={props.readonly}
                    moduleName={'manufacture-units'}
                    onChange={val => handleInputChange(val, 'unit')}
                    readerValue={props.position.unit}
                  >
                    {props.position.unit?.name ?? 'Szt.'}
                  </ModuleListPicker>
                </InputAdornment>
              ),
            }}
          />
        </TableCell>
        <TableCell sx={{ width: 170 }}>
          <NumberInput
            disabled={props.readonly}
            value={props.position.priceUnitNet}
            onChange={val => handleInputChange(val, 'priceUnitNet')}
            inputProps={{
              endAdornment: <InputAdornment position="end">{props.values[props.fields.currency.id]}</InputAdornment>,
            }}
          />
        </TableCell>
        <TableCell sx={{ width: 100 }}>
          <ModuleListPicker
            disabled={props.readonly}
            moduleName={'core-accountancy-value-added-taxes'}
            onChange={val => handleInputChange(val, 'tax')}
            fallback={'Wybierz'}
            readerValue={props.position.tax}
          />
        </TableCell>
        <TableCell sx={{ width: 170 }}>
          <NumberInput
            disabled={props.readonly}
            value={props.position.priceTotalNet}
            onChange={val => handleInputChange(val, 'priceTotalNet')}
            inputProps={{
              endAdornment: <InputAdornment position="end">{props.values[props.fields.currency.id]}</InputAdornment>,
            }}
          />
        </TableCell>
        <TableCell sx={{ width: 170 }}>
          <NumberInput
            disabled={props.readonly}
            value={props.position.priceTotalGross}
            onChange={val => handleInputChange(val, 'priceTotalGross')}
            inputProps={{
              endAdornment: <InputAdornment position="end">{props.values[props.fields.currency.id]}</InputAdornment>,
            }}
          />
        </TableCell>
      </TableRow>
      {exchangeCurrency !== currency && (
        <TableRow>
          <TableCell sx={{ width: 40, textAlign: 'center' }}></TableCell>
          <TableCell></TableCell>
          <TableCell sx={{ width: 80 }}></TableCell>
          <TableCell sx={{ width: 170 }}>
            <NumberInput
              disabled={true}
              value={parseFloat((props.position.priceUnitNet * props.values[props.fields.exchangeRate.id]).toFixed(2))}
              onChange={val => handleInputChange(val, 'priceUnitNet')}
              inputProps={{
                endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
              }}
            />
          </TableCell>
          <TableCell sx={{ width: 100 }}></TableCell>
          <TableCell sx={{ width: 170 }}>
            <NumberInput
              disabled={true}
              value={parseFloat((props.position.priceTotalNet * props.values[props.fields.exchangeRate.id]).toFixed(2))}
              onChange={val => handleInputChange(val, 'priceTotalNet')}
              inputProps={{
                endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
              }}
            />
          </TableCell>
          <TableCell sx={{ width: 170 }}>
            <NumberInput
              disabled={true}
              value={parseFloat((props.position.priceTotalGross * props.values[props.fields.exchangeRate.id]).toFixed(2))}
              onChange={val => handleInputChange(val, 'priceTotalGross')}
              inputProps={{
                endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
              }}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const PositionsGroup: FC<InvoiceGroupProps> = props => {
  const positions: InvoicePosition[] = props.values?.[props.fields.positions.id] ?? [];
  const currency: string = props.values?.[props.fields.currency.id] ?? [];
  const exchangeCurrency: string = props.values?.[props.fields.exchangeCurrency.id] ?? [];

  const addNewRow = () => {
    props.onUpdate([...positions, { quantity: 1, tax: positions?.[0]?.tax ?? null }], props.fields.positions.id);
  };

  useEffect(() => {
    if (positions.length === 0) {
      props.onUpdate([{ quantity: 1 }], props.fields.positions.id);
    }
  }, [positions.length]);

  const handeRemove = (index: number) => {
    props.onUpdate(
      positions.filter((_, i) => i !== index),
      props.fields.positions.id,
    );
  };
  const handleUpdate = (index: number, position: InvoicePosition) => {
    props.onUpdate(
      positions.map((el, i) => (i === index ? position : el)),
      props.fields.positions.id,
    );
  };

  return (
    <Paper sx={{ padding: 2 }}>
      <Container>
        <Col xs={12}>
          <Typography variant="subtitle1" align="center">
            Pozycje
          </Typography>
        </Col>
        <Col xs={12}>
          <Table padding="none">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: 40, textAlign: 'center' }}>lp.</TableCell>
                <TableCell>Nazwa</TableCell>
                <TableCell sx={{ width: 150 }}>Ilość</TableCell>
                <TableCell sx={{ width: 170 }}>Cenna netto</TableCell>
                <TableCell sx={{ width: 100 }}>VAT</TableCell>
                <TableCell sx={{ width: 170 }}>Wartość netto</TableCell>
                <TableCell sx={{ width: 170 }}>Wartość brutto</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {positions.map((el, index) => (
                <PositionsRow key={index} {...props} position={el} index={index} onRowRemove={handeRemove} onRowUpdate={handleUpdate} />
              ))}
            </TableBody>
            {!props.readonly && (
              <TableFooter>
                <TableRow>
                  <PlaceholderCell></PlaceholderCell>
                  <TableCell>
                    <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={() => addNewRow()} fullWidth={true}>
                      Dodaj pozycję
                    </Button>
                  </TableCell>
                  <PlaceholderCell colSpan={5}></PlaceholderCell>
                </TableRow>
                <TableRow>
                  <PlaceholderCell colSpan={7}></PlaceholderCell>
                </TableRow>
                <TableRow>
                  <PlaceholderCell colSpan={4}></PlaceholderCell>
                  <FooterCell>Suma Netto</FooterCell>
                  <FooterCell sx={{ textAlign: 'right', paddingRight: 1 }}>
                    {sumPositions(positions, 'priceTotalNet').toFixed(2)} {currency !== exchangeCurrency && `${currency}`}
                  </FooterCell>
                  <FooterCell sx={{ textAlign: currency == exchangeCurrency ? 'left' : 'right' }}>
                    {currency == exchangeCurrency && `${currency}`}
                    {currency !== exchangeCurrency &&
                      `${(sumPositions(positions, 'priceTotalNet') * props.values[props.fields.exchangeRate.id]).toFixed(
                        2,
                      )} ${exchangeCurrency}`}
                  </FooterCell>
                </TableRow>
                <TableRow>
                  <PlaceholderCell colSpan={4}></PlaceholderCell>
                  <FooterCell>Suma VAT</FooterCell>
                  <FooterCell sx={{ textAlign: 'right', paddingRight: 1 }}>
                    {(sumPositions(positions, 'priceTotalGross') - sumPositions(positions, 'priceTotalNet')).toFixed(2)}{' '}
                    {currency !== exchangeCurrency && `${currency}`}
                  </FooterCell>
                  <FooterCell sx={{ textAlign: currency == exchangeCurrency ? 'left' : 'right' }}>
                    {currency == exchangeCurrency && `${currency}`}
                    {currency !== exchangeCurrency &&
                      `${(
                        (sumPositions(positions, 'priceTotalGross') - sumPositions(positions, 'priceTotalNet')) *
                        props.values[props.fields.exchangeRate.id]
                      ).toFixed(2)} ${exchangeCurrency}`}
                  </FooterCell>
                </TableRow>
                <TableRow>
                  <PlaceholderCell colSpan={4}></PlaceholderCell>
                  <FooterCell>Suma Brutto</FooterCell>
                  <FooterCell sx={{ textAlign: 'right', paddingRight: 1 }}>
                    {sumPositions(positions, 'priceTotalGross').toFixed(2)} {currency !== exchangeCurrency && `${currency}`}
                  </FooterCell>
                  <FooterCell sx={{ textAlign: currency == exchangeCurrency ? 'left' : 'right' }}>
                    {currency == exchangeCurrency && `${currency}`}
                    {currency !== exchangeCurrency &&
                      `${(sumPositions(positions, 'priceTotalGross') * props.values[props.fields.exchangeRate.id]).toFixed(
                        2,
                      )} ${exchangeCurrency}`}
                  </FooterCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </Col>
      </Container>
    </Paper>
  );
};

export default PositionsGroup;

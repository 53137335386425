import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';

export type DeliveryMethod = CTMRecord & {
  id?: string;
  name: string;
};

const columns: CTMListColumn<DeliveryMethod>[] = [
  {
    id: 'name',
    filterable: true,
    sortable: true,
    Header: 'Nazwa',
    noBreak: true,
    accessor: 'name',
  },
];

const module: CTMModule<DeliveryMethod> = {
  id: '6495c3d1-b65b-4616-8ab3-6a9af0ea3d25',
  dataClass: 'CTM\\Delivery\\Entity\\DeliveryMethod',
  urlPrefix: 'delivery-method',
  name: 'Metody dostawy',
  role: 'DELIVERY_METHOD',
  api: {
    item: {
      get: ({ id }) => `/delivery/delivery-methods/${id}`,
      put: ({ id }) => `/delivery/delivery-methods/${id}`,
      delete: ({ id }) => `/delivery/delivery-methods/${id}`,
    },
    collection: {
      get: `/delivery/delivery-methods`,
      post: `/delivery/delivery-methods`,
    },
  },
  recordLabel: (record, allFields) => record.name,
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'name', desc: false }],
    storeFilters: false,
    board: false,
  },
  form: {},
};

export default module;

import CRUDModule from '@Core/CRUDModule';
import useFetchModuleData from '@Core/Hooks/useFetchModuleData';
import CTMModule, { CTMListColumn, CTMRecord, FormConfiguration, ListConfiguration } from '@Core/Types/CTMModule';
import { Card, Tooltip } from '@mui/material';
import { confirmRemove } from 'common/sweetalerts';
import DataGrid, { DataGridHandle, DataGridProps } from 'components/DataGrid';
import ModalFormWrapper, { ModalFormHandle } from 'components/Form/ModalFormWrapper';
import ModuleForm from 'components/Module/ModuleForm';
import SecuredView from 'components/Theme/Common/SecuredView';
import { useModuleContext } from 'context/ModulesContext';
import useErrorHandler from 'helpers/FormHandler';
import { FC, Fragment, RefObject, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup, CardBody } from 'reactstrap';
import { useAppSelector } from 'store';

type ModalActionsHeaderWithModalProps = {
  module?: any;
  afterSave?: any;
  list?: any;
  moduleFormComponent?: any;
  moduleFormComponentProps?: any;
  moduleFormWrapperProps?: any;
  overrideFormProps?: any;
};

const ModalActionsHeaderWithModal: FC<ModalActionsHeaderWithModalProps> = ({
  module,
  afterSave,
  list,
  moduleFormComponent,
  moduleFormComponentProps,
  moduleFormWrapperProps,
  overrideFormProps,
}) => {
  const createModal = useRef<ModalFormHandle>();
  const errorHandler = useErrorHandler();

  if (!module) {
    return null;
  }

  if (!module.configuration.form && !moduleFormComponent) {
    return <>Akcje</>;
  }

  if (moduleFormComponent) {
    moduleFormComponentProps = { ...moduleFormComponentProps, modal: createModal };
  }

  const ModuleFormComponent = moduleFormComponent ?? ModuleForm;

  return (
    <SecuredView role={module.configuration.roleEdit ?? `ROLE_EDIT_${module.configuration.role}`} alternativeContent={'Akcje'}>
      <ModalFormWrapper
        {...moduleFormWrapperProps}
        ref={createModal}
        title={`${module.configuration.name} - Dodaj`}
        form={
          <ModuleFormComponent
            list={list}
            {...moduleFormComponentProps}
            moduleName={module.configuration.urlPrefix}
            id={null}
            showContextActions={false}
            showBackButton={false}
            afterSave={record => {
              afterSave?.(record, { list, createModal });
              if (module.configuration.list.closeAfterListAction ?? true) {
                list?.current?.refresh();
                createModal?.current?.close();
              }
            }}
            onError={errorHandler}
            readonly={false}
            overrideFormProps={overrideFormProps}
          />
        }
      >
        <Button className="btn btn-success btn-sm w-100" color="primary">
          <i className="mdi mdi-plus" />
          &nbsp;Dodaj nowy
        </Button>
      </ModalFormWrapper>
    </SecuredView>
  );
};

export type InlineActionsHeaderProps = {
  listRef: RefObject<DataGridHandle>;
  module: CRUDModule<CTMModule<any>>;
  override: any;
  overrideFormProps: ModuleListProps<any>['overrideFormProps'];
  useModal: boolean;
};

export const InlineActionsHeader: FC<InlineActionsHeaderProps> = ({ module, override, listRef, useModal, overrideFormProps = {} }) => {
  if (!module) {
    return null;
  }

  if (module.configuration.form?.disableCreateNewRecord || overrideFormProps?.disableCreateNewRecord) {
    return <>Akcje</>;
  }

  if (useModal) {
    return <ModalActionsHeaderWithModal module={module} overrideFormProps={overrideFormProps} list={listRef} />;
  }

  return (
    <SecuredView role={module.configuration.roleEdit ?? `ROLE_EDIT_${module.configuration.role}`} alternativeContent={'Akcje'}>
      <Link to={override?.createUrl ?? module.createUrl} className="btn btn-success btn-sm w-100" color="primary">
        <i className="mdi mdi-plus" />
        &nbsp;Dodaj nowy
      </Link>
    </SecuredView>
  );
};

export const InlineListActions = (
  errorHandler,
  listRef: RefObject<DataGridHandle>,
  editModal: RefObject<ModalFormHandle>,
  showModal: RefObject<ModalFormHandle>,
  module: CRUDModule<CTMModule<any>>,
  override: any,
  overrideFormProps: ModuleListProps<any>['overrideFormProps'],
  useModal = false,
  moduleFormWrapperProps = {},
): CTMListColumn<any>[] => {
  if (!module || !module.configuration.form) {
    return [];
  }
  const EditWrapper = module.configuration.editButtonWrapper ?? Fragment;
  return [
    {
      id: 'actionsStickyRight',
      Header: () => (
        <InlineActionsHeader
          module={module}
          override={override}
          useModal={useModal}
          listRef={listRef}
          overrideFormProps={overrideFormProps}
        />
      ),
      disableSortBy: true,
      disableFilters: true,
      disableExpandAction: true,
      accessor: row =>
        useModal ? (
          <div className={'actions'}>
            <ButtonGroup>
              {module.configuration.form && (
                <>
                  {!module.configuration.form.disableShowRecord && (
                    <ModalFormWrapper
                      {...moduleFormWrapperProps}
                      ref={showModal}
                      title={`${module.configuration.name} - ${module.configuration.recordLabel?.(row, [])} - Podgląd`}
                      form={
                        <ModuleForm
                          moduleName={module.configuration.urlPrefix}
                          id={row.id}
                          showContextActions={false}
                          showBackButton={false}
                          showConfigurationSwitcher={false}
                          afterSave={() => {
                            showModal?.current?.close();
                            listRef?.current?.refresh();
                          }}
                          onError={errorHandler}
                          readonly={true}
                          overrideFormProps={{ ...overrideFormProps, recordCallback: undefined }}
                        />
                      }
                      wrapperComponent={Button}
                      wrapperProps={{ className: 'btn btn-success btn-sm' }}
                    >
                      <Tooltip title={'Podgląd'} placement="top" arrow>
                        <i className="mdi mdi-eye" style={{ padding: '0 4px' }} />
                      </Tooltip>
                    </ModalFormWrapper>
                  )}
                  {!(module.configuration.form.disableEditRecord || overrideFormProps?.disableEditRecord) && (
                    <SecuredView role={module.configuration.roleEdit ?? `ROLE_EDIT_${module.configuration.role}`}>
                      <ModalFormWrapper
                        {...moduleFormWrapperProps}
                        ref={editModal}
                        title={`${module.configuration.name} - ${module.configuration.recordLabel?.(row, [])} - Edytuj`}
                        form={
                          <ModuleForm
                            moduleName={module.configuration.urlPrefix}
                            id={row.id}
                            showContextActions={false}
                            showBackButton={false}
                            afterSave={() => {
                              if (module.configuration.list?.closeAfterListAction ?? true) {
                                editModal?.current?.close();
                                listRef?.current?.refresh();
                              }
                            }}
                            onError={errorHandler}
                            overrideFormProps={{ ...overrideFormProps, recordCallback: undefined }}
                            readonly={false}
                          />
                        }
                        wrapperComponent={Button}
                        wrapperProps={{ className: 'btn btn-primary btn-sm', color: 'primary' }}
                      >
                        <Tooltip title={'Edycja'} placement="top" arrow>
                          <i className="mdi mdi-cogs" style={{ padding: '0 4px' }} />
                        </Tooltip>
                      </ModalFormWrapper>
                    </SecuredView>
                  )}
                  {!(module.configuration.form.disableRemoveRecord || overrideFormProps?.disableRemoveRecord) && (
                    <SecuredView role={`ROLE_REMOVE_${module.configuration.role}`}>
                      <Button
                        onClick={() => confirmRemove(() => module.api.delete({ id: row.id }).then(() => listRef.current?.refresh()))}
                        className="btn btn-danger  btn-sm"
                      >
                        <Tooltip title={'Usuń'} placement="top" arrow>
                          <i className="mdi mdi-delete" style={{ padding: '0 4px' }} />
                        </Tooltip>
                      </Button>
                    </SecuredView>
                  )}
                </>
              )}
            </ButtonGroup>
          </div>
        ) : (
          <ButtonGroup>
            {!overrideFormProps?.disableCustomActions &&
              module.configuration.list?.inlineComponentPrependActions &&
              module.configuration.list?.inlineComponentPrependActions(row, listRef.current)}
            {!(module.configuration.form?.disableShowRecord || overrideFormProps?.disableShowRecord) && (
              <Tooltip title={'Podgląd'} placement="top" arrow>
                <Link to={override?.showUrl ? override.showUrl(row) : module.showUrl(row.id)} className="btn btn-success btn-sm">
                  <i className="mdi mdi-eye" />
                </Link>
              </Tooltip>
            )}
            {!(module.configuration.form?.disableEditRecord || overrideFormProps?.disableEditRecord) && (
              <EditWrapper {...(typeof module.configuration.editButtonWrapper === 'undefined' ? {} : { row })}>
                <SecuredView role={module.configuration.roleEdit ?? `ROLE_EDIT_${module.configuration.role}`}>
                  <Link to={override?.editUrl ? override.editUrl(row) : module.editUrl(row?.id)} className="btn btn-primary btn-sm">
                    <Tooltip title={'Edycja'} placement="top" arrow>
                      <i className="mdi mdi-cogs" />
                    </Tooltip>
                  </Link>
                </SecuredView>
              </EditWrapper>
            )}
            {!(module.configuration.form?.disableRemoveRecord || overrideFormProps?.disableRemoveRecord) && (
              <SecuredView role={`ROLE_REMOVE_${module.configuration.role}`}>
                <Button
                  onClick={() =>
                    confirmRemove(() => module.api.delete({ id: row?.id }))
                      .then(() => listRef.current?.refresh())
                      .catch(() => {})
                  }
                  className="btn btn-danger  btn-sm"
                >
                  <Tooltip title={'Usuń'} placement="top" arrow>
                    <i className="mdi mdi-delete" />
                  </Tooltip>
                </Button>
              </SecuredView>
            )}
            {!overrideFormProps?.disableCustomActions &&
              module.configuration.list?.inlineComponentAppendActions &&
              module.configuration.list?.inlineComponentAppendActions(row, listRef.current)}
          </ButtonGroup>
        ),
    },
  ];
};

export type ModuleListProps<T extends CTMRecord = object> = {
  moduleName: string;
  useModal?: boolean;
  defaultFilters?: any;
  overrideFormProps?: FormConfiguration<T> & {
    disableCustomActions?: boolean;
    recordCallback?: (fields: any) => T | object;
  };
  overrideListProps?: Omit<ListConfiguration<T>, 'columns'> & {
    columns?: ((columns: CTMListColumn[]) => CTMListColumn<T>[]) | ListConfiguration<T>['columns'];
    disableTop?: boolean;
    fullWidth?: boolean;
    responsive?: boolean;
    className?: string;
    queryKey?: string;
    minRows?: number;
    defaultPerPage?: number;
    perPageOptions?: number[];
  };
};

const ModuleList: FC<ModuleListProps> = ({ moduleName, useModal = false, defaultFilters, overrideFormProps, overrideListProps }) => {
  const module = useModuleContext<CTMModule<CTMRecord>, true>(moduleName);
  const list = useRef<DataGridHandle>(null);
  const editModal = useRef<ModalFormHandle>(null);
  const showModal = useRef<ModalFormHandle>(null);
  const errorHandler = useErrorHandler();
  const { data: moduleData } = useFetchModuleData(module.configuration.id);

  const columns = useMemo(
    () => [
      ...(typeof overrideListProps?.columns === 'function'
        ? overrideListProps.columns((module.configuration.list?.columns ?? []) as CTMListColumn[])
        : overrideListProps?.columns ?? module?.configuration?.list?.columns ?? []),
      ...InlineListActions(errorHandler, list, editModal, showModal, module, undefined, overrideFormProps, useModal),
    ],
    [module],
  );
  const queryKey = useAppSelector(module.configuration.list?.resolveQueryKey ? module.configuration.list?.resolveQueryKey : () => false);

  const ListPrepend = module.configuration.list?.prependComponent;
  const exports = [...(moduleData?.exports ?? []), ...(module.configuration?.list?.customExports ?? [])];

  delete overrideListProps?.columns;

  const dataGridProps: DataGridProps = {
    url: module.configuration.list?.url ?? module.api.getAllUrl,
    fullWidth: false,
    responsive: true,
    disableTop: false,
    className: 'table-clickable',
    exports: exports,
    defaultPerPage: 20,
    perPageOptions: [10, 20, 30, 40, 50],
    defaultOrderBy: module.configuration.list?.defaultOrderBy,
    renderRowSubComponent: module.configuration.list?.renderRowSubComponent,
    defaultFilters: defaultFilters ?? module.configuration.list?.defaultFilters,
    onRowClick: module.configuration.list?.onRowClick,
    rowStylesCallback: module.configuration.list?.rowStylesCallback,
    canRenderSubRow: module.configuration.list?.canRenderSubRow,
    selectionActions: module.configuration.list?.selectionActions,
    queryKey: queryKey,
    storeFilters: module.configuration.list?.storeFilters,
    prependButton: module.configuration.list?.prependButton,
    headerOverride: module.configuration.list?.headerOverride,
    ...overrideListProps,
    columns: columns,
  };

  return (
    <Card>
      <CardBody style={{ padding: 0 }}>
        {!overrideListProps?.disableTop && ListPrepend && <ListPrepend listRef={list} />}
        <DataGrid ref={list} {...dataGridProps} />
      </CardBody>
    </Card>
  );
};

export default ModuleList;

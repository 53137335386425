import { InvoiceModule } from '@Accountancy/Accountancy';
import useDepartment from '@Accountancy/Department/Hooks/useDepartment';
import { useFetchDepartments } from '@Accountancy/Department/Hooks/useFetchDepartments';
import { InvoiceTypes } from '@Accountancy/Invoice/InvoiceTypes';
import MenuPicker from '@Components/Form/MUI/MenuPicker';
import { Box } from '@mui/material';
import { memo, useMemo } from 'react';
import { useHistory } from 'react-router';

const AllDepartmentsOption = { label: 'Wszystkie departamenty', background: '#303030', icon: 'bank', value: undefined };

const InvoiceTypeOptions = InvoiceTypes.map(el => ({ label: el.label, value: el.type }));

function InvoiceContextHeader() {
  const history = useHistory();
  const { department, changeDepartment } = useDepartment();
  const { data } = useFetchDepartments();

  const departmentOptions = useMemo(
    () => [AllDepartmentsOption, ...(data ?? []).map(el => ({ label: el.name, icon: el.icon, background: el.color, value: el['@id'] }))],
    [data],
  );

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" className="w-100" style={{ padding: '0 12px' }}>
      <Box>
        <MenuPicker<undefined | string>
          value={department}
          options={departmentOptions}
          placeholder={AllDepartmentsOption}
          onChange={newDepartment => changeDepartment(newDepartment.value)}
        />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <div style={{ marginRight: 12 }}>
          <MenuPicker<string | null>
            value={null}
            options={InvoiceTypeOptions}
            placeholder={{ label: 'Przychód', value: null, background: '#c8e6c9', icon: 'plus', color: '#1b5e20' }}
            defaultColor={'#303030'}
            defaultBackground={'#fff'}
            onChange={option => history.push(`${InvoiceModule.createUrl}?income=true&type=${option.value}`)}
          />
        </div>
        <div>
          <MenuPicker<string | null>
            value={null}
            options={InvoiceTypeOptions}
            placeholder={{ label: 'Wydatek', value: null, background: '#ffcdd2', icon: 'minus', color: '#b71c1c' }}
            defaultColor={'#303030'}
            defaultBackground={'#fff'}
            onChange={option => history.push(`${InvoiceModule.createUrl}?income=false&type=${option.value}`)}
          />
        </div>
      </Box>
    </Box>
  );
}

export default memo(InvoiceContextHeader);

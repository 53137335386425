import { CoreModuleModule } from '../../CTMModules';
import { Module } from '@CustomFields/CoreModule';
import { useQuery } from 'react-query';

export type ModuleResponse<T = Module> = {
  data: undefined | T;
  isLoading: boolean;
  error: any;
};
const useFetchModuleData = (moduleId: string): ModuleResponse => {
  const { data, isLoading, isLoadingError, error } = useQuery(moduleId, () => CoreModuleModule.api.get({ id: moduleId }), {
    cacheTime: 10 * 60 * 1000,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: true,
    refetchInterval: false,
  });

  return {
    data: isLoadingError ? undefined : data,
    isLoading: isLoading || isLoadingError,
    error,
  };
};

export const useProductModule = () => useFetchModuleData('7ae85ce1-0b95-4c3a-9ff8-f95474af4683');

export default useFetchModuleData;

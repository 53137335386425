import { FC } from 'react';
import { Button } from 'reactstrap';

const LabelerId: FC<{ labelerId: number }> = ({ labelerId }) => {
  return (
    <Button color={'success'} style={{ padding: '0 5px', borderRadius: '100px', marginRight: '5px' }} className="labeler-id">
      {labelerId}
    </Button>
  );
};

export default LabelerId;

import EditableField from '../../components/DataGrid/Field/EditableField';
import { SelectColumnFilter, SelectModuleRecordColumnFilter } from '../../components/DataGrid/Filter';
import { extractIRIFromRecord, setValuesToFormRecord } from '../../helpers/ModuleUtils';
import { SelectCountryFilter } from './Component/Filter/SelectCountryFilter';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import { getParamByParam } from 'iso-country-currency';

export type DeliveryFee = CTMRecord & {
  id?: string;
};

const types = {
  PACKAGE: 'Paczka',
  PALLET: 'Paleta',
};

const columns: CTMListColumn<DeliveryFee>[] = [
  {
    id: 'method.id',
    filterable: true,
    sortable: true,
    Header: 'Dostawca',
    noBreak: true,
    accessor: 'method.name',
    Filter: SelectModuleRecordColumnFilter,
    filterModuleName: 'delivery-method',
  },
  {
    id: 'type',
    filterable: true,
    sortable: true,
    Header: 'Rodzaj przesyłki',
    noBreak: true,
    accessor: ({ type }) => types[type] ?? 'Nieznany',
    Filter: SelectColumnFilter,
    filterOptions: [
      { value: null, label: 'Wszystkie' },
      ...Object.entries(types).map((item: string[]) => ({ value: item[0], label: item[1] })),
    ],
  },
  {
    id: 'country',
    filterable: true,
    sortable: true,
    Header: 'Kraje',
    noBreak: true,
    accessor: ({ countryFrom, countryTo }) => (
      <div>
        <span className={'fi fi-' + countryFrom.toLowerCase()}></span>
        &nbsp;{countryFrom} → <span className={'fi fi-' + countryTo.toLowerCase()}></span>
        &nbsp;{countryTo}
      </div>
    ),
    Filter: SelectCountryFilter,
  },
  {
    id: 'weightFrom',
    filterable: false,
    sortable: true,
    Header: 'Waga',
    noBreak: true,
    minWidth: 160,
    accessor: ({ weightFrom, weightTo }) => `${weightFrom ?? 0} - ${weightTo ?? 0} kg`,
  },
  {
    id: 'value',
    filterable: false,
    sortable: true,
    Header: 'Wartość',
    noBreak: true,
    minWidth: 160,
    accessor: 'value',
    Cell: ({ row }) => (
      <EditableField
        id={row.original.id}
        currentValue={row.original.value}
        fieldName={'value'}
        moduleName={'delivery-fee'}
        type={'number'}
        renderLabel={value => `${value} ${getParamByParam('currency', row.original.currency ?? 'PLN', 'symbol')}`}
        beforeInit={value => (value ?? 0.0) / 100}
        beforeSave={value => Math.round(value * 100)}
      />
    ),
  },
];

const module: CTMModule<DeliveryFee> = {
  id: '382fce38-b622-4199-9346-99f9127b288a',
  dataClass: 'CTM\\Delivery\\Entity\\DeliveryFee',
  urlPrefix: 'delivery-fee',
  name: 'Cennik dostawy',
  role: 'DELIVERY_FEE',
  api: {
    item: {
      get: ({ id }) => `/delivery/delivery-fees/${id}`,
      put: ({ id }) => `/delivery/delivery-fees/${id}`,
      delete: ({ id }) => `/delivery/delivery-fees/${id}`,
    },
    collection: {
      get: `/delivery/delivery-fees`,
      post: `/delivery/delivery-fees`,
    },
  },
  recordLabel: (record, allFields) =>
    record.method
      ? record.countryFrom +
        ' -> ' +
        record.countryTo +
        ' ' +
        record.method?.name +
        ' ' +
        record.type +
        ' ' +
        record.weightFrom +
        ' - ' +
        record.weightTo +
        'kg'
      : 'Nowa stawka',
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'weightFrom', desc: false }],
    storeFilters: false,
    board: false,
  },
  form: {
    prepareRecordToSave: (record, allFields) => {
      return {
        ...setValuesToFormRecord(record, allFields, {
          value: Math.round((record.value ?? 0) * 100),
        }),
        method: extractIRIFromRecord(record?.method),
      };
    },
    prepareFetchedRecord: (record, allFields) => {
      return setValuesToFormRecord(record, allFields, {
        value: (record.value ?? 0) / 100,
      });
    },
  },
};

export default module;

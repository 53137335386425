import { HighlightOff } from '@mui/icons-material';
import { ComponentRef, Fragment, forwardRef, useImperativeHandle, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';

type ModalFormProps = {
  id?: string;
  wrapperProps?: any;
  wrapperComponent?: any;
  form?: any;
  children: any;
  modalStyles?: any;
  title: string;
  onOpen?: (id: string) => void;
};

export type ModalFormHandle = {
  open: () => void;
  close: () => void;
  isOpened: () => boolean;
};

const ModalFormWrapper = forwardRef<ModalFormHandle, ModalFormProps>((props, ref) => {
  const { id, wrapperProps = {}, wrapperComponent = null, form = null, children, modalStyles = {}, title, onOpen = undefined } = props;
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open() {
      setOpen(true);
      onOpen?.(id ?? '');
    },
    close() {
      setOpen(false);
    },
    isOpened() {
      return true === open;
    },
  }));

  const Wrapper = ({ children, ...props }) => {
    if (wrapperComponent) {
      const WrapperComponent = wrapperComponent ?? Fragment;
      return <WrapperComponent {...(WrapperComponent === Fragment ? {} : props)}>{children}</WrapperComponent>;
    }
    return <div {...props}>{children}</div>;
  };

  if (!open) {
    return (
      <Wrapper
        {...wrapperProps}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          setOpen(true);
          onOpen?.(id ?? '');
        }}
      >
        {children}
      </Wrapper>
    );
  }

  return (
    <>
      <Wrapper
        {...wrapperProps}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          setOpen(true);
        }}
      >
        {children}
      </Wrapper>
      <Modal
        isOpen={open}
        centered={true}
        size={'xl'}
        backdrop={true}
        toggle={() => setOpen(!open)}
        style={{ maxWidth: '90vw', maxHeight: '90vw', ...(modalStyles ?? {}) }}
        className={'form-modal'}
      >
        <ModalBody>
          {open && (
            <>
              <div className="modal-header-nav-bar">
                <div className={'modal-header-title'}>{title}</div>
                <div>&nbsp;</div>
                <div>
                  <div className={'modal-header-close'}>
                    <HighlightOff onClick={() => setOpen(false)} />
                  </div>
                </div>
              </div>
              <div>{typeof form === 'function' ? form() : form}</div>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
});
ModalFormWrapper.displayName = 'ModalFormWrapper';

export type ModalFormWrapperRef = ComponentRef<typeof ModalFormWrapper>;
export default ModalFormWrapper;

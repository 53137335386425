import useCurrentUser from '@Employee/Employee/Hooks/UseCurrentUser';
import createRoomName from 'Modules/Chat/Func/CreateRoomName';
import { Room } from 'Modules/Chat/Types/ApiModel';
import { AxiosError } from 'axios';
import { ApiCollection, get } from 'helpers/Axios';
import { useQuery } from 'react-query';

export const FavoriteRoomListQueryKey = ['/chat/rooms/favorite'];

type RoomsCollection = ApiCollection<Room>;

export default function useFavoriteRoomsList() {
  const currentUser = useCurrentUser();
  const query = useQuery<RoomsCollection, AxiosError, Room[]>({
    queryKey: FavoriteRoomListQueryKey,
    queryFn: () => get<ApiCollection<Room>>('/chat/rooms/favorite'),
    select: data =>
      data['hydra:member'].sort((a, b) => {
        const [nameA, nameB] = [createRoomName(a, currentUser), createRoomName(b, currentUser)];
        return nameA?.localeCompare(nameB || '') || 0;
      }),
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    staleTime: Infinity,
  });

  return {
    ...query,
    data: query.data || [],
  };
}

import { Node, ProductNode, TreeIngredients, buildTreeFromProduct, buildTreeLayout } from './FlowUtils';
import useStore from './store';
import { useProductModule } from '@Core/Hooks/useFetchModuleData';
import { HighlightOff } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { ProductModule } from 'Modules/CTMModules';
import { Field } from 'Modules/CustomFields/Field';
import { Product, RequiredProduct } from 'Modules/Manufacture/Product';
import CustomField from 'components/CustomFields/CustomField';
import ListPicker from 'components/Form/ListPicker';
import { axiosApi } from 'helpers/Axios';
import { useCallback, useMemo, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

type FieldToOverride = {
  field: Field;
  value: any;
};

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

type FieldOptionLabel = { name: string; ['@id']: string };
const DuplicatePartOfTree = () => {
  const [duplicationInProgress, setDuplicationInProgress] = useState<boolean>();
  const [progress, setProgress] = useState<number>(0);
  const [actionsCount, setActionsCount] = useState<number>(0);
  const productModule = useProductModule();
  const { nodesToDuplicate, productNodes, nodes, edges, nodesToDuplicateModal, updateState } = useStore(
    state => ({
      updateState: state.updateState,
      nodesToDuplicate: state.nodesToDuplicate,
      edges: state.edges,
      nodesToDuplicateModal: state.nodesToDuplicateModal,
      nodes: state.nodes,
      productNodes: state.nodes.filter(el => el.type === 'productNode'),
    }),
    shallowEqual,
  );
  const [fieldsToOverride, setFieldsToOverride] = useState<FieldToOverride[]>([]);
  const fieldOptions: FieldOptionLabel[] = useMemo(() => {
    const selectedFields = fieldsToOverride.map(f => f.field.id);
    return (productModule.data?.fields ?? [])
      .map<FieldOptionLabel>(field => ({
        name: `[${field.type}] ${field.name}`,
        ['@id']: field.id ?? '',
      }))
      .filter(el => !selectedFields.includes(el['@id']));
  }, [productModule, fieldsToOverride]);

  const setOpen = newState => updateState({ nodesToDuplicateModal: newState });

  const handleSelectNewFieldToOverride = (fieldId: string) => {
    setFieldsToOverride([
      ...fieldsToOverride,
      {
        field: productModule.data?.fields.find(el => el.id === fieldId) as Field,
        value: null,
      },
    ]);
  };

  const extractParents = (currentNode: Node) => {
    const currentNodeParents = edges
      .filter(edge => edge.source === currentNode.id)
      .map(edge => productNodes.find(node => node.id === edge.target));
    const parentParents = currentNodeParents.map(parent => extractParents(parent as Node));

    return [...currentNodeParents, ...parentParents.flat()];
  };
  const copyProduct = (productId: string): Promise<{ data: Product }> => {
    return axiosApi.post(`manufacture/products/copy`, { id: productId });
  };
  const duplicateProducts = async () => {
    setDuplicationInProgress(true);
    const nodesToCopy: { [key: string]: string } = nodesToDuplicate.reduce<any>((prev, curr) => {
      prev[curr.data.product?.id ?? ''] = curr.id;
      return prev;
    }, {});
    const productsToCopy = Object.keys(nodesToCopy);
    setActionsCount(productsToCopy.length * 3);

    const topLvlNodes = nodesToDuplicate.filter(node => {
      const parents = extractParents(node);
      return !parents.some(parent => Object.values(nodesToCopy).includes(parent.id));
    });

    const copyResponses = await Promise.all(
      productsToCopy.map(async productId => copyProduct(productId).finally(() => setProgress(prev => prev + 1))),
    );
    await Promise.all(
      copyResponses.map(async (response, index) => {
        const sourceNode = productNodes.find(node => node.id === nodesToCopy[productsToCopy[index]]);
        const sourceNodeChildrens = edges
          .filter(edge => edge.target === sourceNode?.id)
          .map(edge => productNodes.find(node => node.type === 'productNode' && node.id === edge.source) as ProductNode);
        const putData = {
          '@template': response.data['@template'],
          '@formValues': fieldsToOverride.reduce<any>((prev, curr) => {
            prev[curr.field.id ?? ''] = curr.value;

            return prev;
          }, {}),
          requiredProducts: sourceNodeChildrens.map(node => {
            const isCopied = productsToCopy.findIndex(productId => productId === node.data.product?.id);
            return {
              quantity: node.data.quantity,
              product: (isCopied === -1 ? node.data.product : copyResponses[isCopied].data)?.['@id'],
              unit: (isCopied === -1 ? node.data.product : copyResponses[isCopied].data)?.unit ?? null,
            } as RequiredProduct;
          }),
        };
        return ProductModule.api
          .put(ProductModule.configuration.form?.prepareRecordToSave?.(putData, []) ?? {}, { id: response.data.id })
          .finally(() => setProgress(prev => prev + 1));
      }),
    );
    const getResponses = await Promise.all(
      copyResponses.map(response => ProductModule.api.get({ id: response.data.id }).finally(() => setProgress(prev => prev + 1))),
    );
    const preparedTree: TreeIngredients = { nodes: [], edges: [] };
    topLvlNodes.forEach(sourceNode => {
      const copyIndex = productsToCopy.findIndex(productId => productId === sourceNode.data.product?.id);
      const productResponse = getResponses[copyIndex];
      const sameNodeProducts = productNodes
        .filter(el => el.type === 'productNode' && el.data.product?.id === sourceNode.data.product?.id)
        .map(el => el.id);
      sameNodeProducts.forEach(nodeToExtend => {
        const upperProduct = edges.find(edge => edge.source === nodeToExtend)?.target;
        buildTreeFromProduct(
          { ['@id']: uuidv4(), product: productResponse, quantity: 1, unit: productResponse?.unit, productFilter: null, id: uuidv4() },
          preparedTree,
          upperProduct ?? '',
        );
      });
    });
    const { nodes: layoutedNodes, edges: layoutedEdges } = buildTreeLayout(
      [...nodes, ...preparedTree.nodes],
      [...edges, ...preparedTree.edges],
    );
    updateState({
      nodes: layoutedNodes,
      edges: layoutedEdges,
    });
  };

  const handleFieldValueUpdate = useCallback((val, fieldId) => {
    setFieldsToOverride(prevFieldsToOverride => {
      return prevFieldsToOverride.map(fieldToOverride => {
        if (fieldToOverride.field.id === fieldId) {
          return { ...fieldToOverride, value: val };
        }
        return fieldToOverride;
      });
    });
  }, []);

  const handleClose = () => {
    setProgress(0);
    setActionsCount(0);
    setDuplicationInProgress(false);
    setFieldsToOverride([]);
    setOpen(false);
  };

  return (
    <Modal
      isOpen={nodesToDuplicateModal}
      centered={true}
      size={'xl'}
      backdrop={true}
      style={{ maxWidth: '800px', maxHeight: '90vw' }}
      className={'form-modal'}
    >
      {nodesToDuplicateModal && (
        <ModalBody>
          <div className="modal-header-nav-bar">
            <div className={'modal-header-title'}>Duplikacja Towarów</div>
            <div>&nbsp;</div>
            {!duplicationInProgress && (
              <div>
                <div className={'modal-header-close'}>
                  <HighlightOff onClick={() => setOpen(false)} />
                </div>
              </div>
            )}
          </div>
          {duplicationInProgress && (
            <div className="loading-overlay p-3">
              {progress !== actionsCount && (
                <>
                  Trwa duplikacja...
                  <br />
                </>
              )}
              <LinearProgressWithLabel value={Math.round((progress / actionsCount) * 100)} />
              {progress === actionsCount && (
                <div className="text-center p-3">
                  <Typography variant="h6">Duplikacja zakończona pomyślnie</Typography>
                  <button className="btn btn-primary" onClick={handleClose}>
                    Zamknij
                  </button>
                </div>
              )}
            </div>
          )}
          {!duplicationInProgress && (
            <>
              <div style={{ padding: 12 }}>
                <div className={'blocks-to-duplicate'}>
                  <Typography variant="h5">Wybrane towary:</Typography>
                  <ul>
                    {nodesToDuplicate.map(node => (
                      <li key={node.id}>
                        <Typography variant="h6">{node.data.product?.name}</Typography>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div style={{ padding: 12 }}>
                <Typography variant="h5">Wybierz pola do nadpisania</Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nazwa pola</TableCell>
                      <TableCell>Wartość</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fieldsToOverride.map((fieldToOverride, index) => (
                      <TableRow key={fieldToOverride.field['@id']}>
                        <TableCell>{fieldToOverride.field.name}</TableCell>
                        <TableCell>
                          <CustomField
                            value={fieldToOverride.value}
                            disableHideRules={true}
                            field={{ ...fieldToOverride.field, _tabindex: index }}
                            onUpdate={handleFieldValueUpdate}
                            customProps={{ disableGroupMargin: true }}
                            fields={[]}
                            values={{}}
                            readonly={false}
                            components={{}}
                            size={12}
                            recordId={''}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={1}>
                        <ListPicker
                          key={`picker_${fieldsToOverride.length}`}
                          label={'Wybierz pole do nadpisania'}
                          onChange={handleSelectNewFieldToOverride}
                          name={''}
                          value={null}
                          options={fieldOptions}
                        />
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </div>

              <div className="modal-footer">
                <Typography variant="h6">
                  Będzie to kopia wybranych produktów, wraz z ich strukturą. Czy na pewno chcesz kontynuować?
                </Typography>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    duplicateProducts();
                  }}
                >
                  Duplikuj
                </button>
                <button className="btn btn-secondary" onClick={handleClose}>
                  Anuluj
                </button>
              </div>
            </>
          )}
        </ModalBody>
      )}
    </Modal>
  );
};

export default DuplicatePartOfTree;

import useFormError from '../useFormError';
import { BaseInputProps } from './Types';
import TextField from '@mui/material/TextField';
import { OutlinedTextFieldProps } from '@mui/material/TextField/TextField';
import * as React from 'react';

interface TextProps extends BaseInputProps {
  value?: null | string;
  style?: React.CSSProperties;
  placeholder?: string;
  className?: string;
  helpText?: string;
  textarea?: boolean;
  labelProps?: OutlinedTextFieldProps['InputLabelProps'];
  inputProps?: OutlinedTextFieldProps['InputProps'];
  maxRows?: number;
  onChange?: (value: undefined | string | null, name: undefined | string) => void;
  name?: string;
  namePrefix?: string;
}

const Text = (props: TextProps) => {
  const {
    className,
    style,
    value,
    inputProps,
    labelProps,
    label,
    onChange,
    name,
    disabled,
    placeholder,
    helpText,
    textarea,
    maxRows,
    namePrefix,
    inputRef,
  } = props;
  const [hasErrors, errorMessage] = useFormError((namePrefix ? namePrefix + '.' : '') + (name ?? ''));

  return (
    <TextField
      className={className}
      style={style}
      label={label}
      name={name}
      disabled={disabled}
      multiline={textarea === true}
      value={value ?? ''}
      helperText={hasErrors ? errorMessage : helpText ? helpText : undefined}
      error={hasErrors}
      placeholder={placeholder}
      onChange={e => (onChange ? onChange(e.target.value, name) : console.warn('Text component not implement onChange callback'))}
      variant="outlined"
      maxRows={maxRows}
      InputLabelProps={{ shrink: !!value, ...(labelProps ?? {}) }}
      InputProps={inputProps}
      inputRef={inputRef}
    />
  );
};

export default Text;

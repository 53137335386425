import CRUDModule from 'Modules/Core/CRUDModule';
import { CTMStrictRecord } from 'Modules/Core/Types/CTMModule';
import { createContext, useContext, useMemo } from 'react';

interface ModulesContextValue {
  modules: { [key: string]: CRUDModule<CTMStrictRecord> };
}

const ModulesContext = createContext<ModulesContextValue>({
  modules: {},
});

export const useModulesContext = () => {
  return useContext<ModulesContextValue>(ModulesContext);
};

export function useModuleContext<
  ItemModel extends CTMStrictRecord,
  ThrowOnError extends boolean = false,
  ListItemModel extends CTMStrictRecord = Partial<ItemModel>,
>(
  module: string,
  findBy: null | 'dataClass' | 'id' = null,
  throwError = false,
): ThrowOnError extends true ? CRUDModule<ItemModel, ListItemModel> : null | CRUDModule<ItemModel, ListItemModel> {
  const ctx = useModulesContext();

  return useMemo<CRUDModule<ItemModel, ListItemModel>>((): CRUDModule<ItemModel, ListItemModel> => {
    if (!findBy) {
      const moduleContext = ctx.modules[module] ?? null;

      if (!moduleContext && throwError) {
        throw 'Module not found ' + module;
      }

      //@ts-ignore
      return moduleContext;
    }

    const moduleContext = Object.values(ctx.modules).find(el => el.configuration[findBy] === module) ?? null;

    if (!moduleContext && throwError) {
      throw 'Module not found ' + module;
    }

    //@ts-ignore
    return moduleContext;
  }, [module, findBy]);
}

export default ModulesContext;

import { CSSProperties, MouseEventHandler } from 'react';

type ColoredIconBoxProps = {
  icon?: string | null;
  background?: string | null;
  color?: string | null;
  value: string;
  style?: CSSProperties;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

function ColoredIconBox({ icon, background, value, style, onClick, color }: ColoredIconBoxProps) {
  return (
    <div
      className="badge font-size-14 w-100"
      style={{
        padding: 6,
        minWidth: 150,
        background: `${background || '#edf0f5'}`,
        display: 'flex',
        alignItems: 'center',
        color: `${color || '#fff'}`,
        ...style,
      }}
      onClick={onClick}
    >
      <span className="badge font-size-16" style={{ background: `${background || '#edf0f5'}`, color: `${color || '#fff'}` }}>
        <i className={`mdi mdi-${icon}`} />
      </span>
      <span>{value}</span>
    </div>
  );
}

export default ColoredIconBox;

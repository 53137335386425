import useCurrentUser from '@Employee/Employee/Hooks/UseCurrentUser';
import { Box, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { MessageCollectionElement } from 'Modules/Chat/Types/Api';
import moment from 'moment';
import { memo } from 'react';

export default memo(function Message({ message }: { message: MessageCollectionElement }) {
  const isCurrentUser = useCurrentUser()?.id === message.author.id;
  const imageAttachments = message.attachments.filter(a => a.file.mimeType.startsWith('image'));
  const fileAttachments = message.attachments.filter(a => !imageAttachments.includes(a));

  return (
    <Stack alignSelf={isCurrentUser ? 'flex-end' : 'flex-start'} alignItems={isCurrentUser ? 'flex-end' : 'flex-start'} maxWidth="80%">
      <Paper sx={{ p: 1, background: 'rgba(116, 120, 141, 0.1)' }} elevation={0}>
        <Typography variant="subtitle2" fontWeight="bold" color="primary" textAlign={isCurrentUser ? 'right' : 'left'}>
          {message.author.firstName} {message.author.lastName}
          <Typography variant="subtitle2" component="span" color="text.secondary">
            &nbsp;{moment(message.createdAt).calendar()}
          </Typography>
        </Typography>
        {message.content}
      </Paper>
      {imageAttachments.length === 1 && (
        <Paper elevation={2} sx={{ mt: 1, borderRadius: 5 }}>
          <a href={imageAttachments[0].file.contentUrl} target="_blank" rel="noreferrer">
            <img
              key={imageAttachments[0].id}
              src={imageAttachments[0].file.contentUrl}
              alt={imageAttachments[0].file.originalName}
              style={{ maxWidth: 480, maxHeight: 200, borderRadius: 20 }}
            />
          </a>
        </Paper>
      )}
      {imageAttachments.length > 1 && (
        <Stack
          direction="row"
          flexWrap="wrap"
          justifyContent={isCurrentUser ? 'flex-end' : 'flex-start'}
          spacing={2}
          marginTop={1}
          maxWidth="100%"
          rowGap={2}
        >
          {imageAttachments.map(attachment => (
            <Paper key={attachment.id} elevation={6} sx={{ borderRadius: 5 }}>
              <a href={attachment.file.contentUrl} target="_blank" rel="noreferrer">
                <img
                  src={attachment.file.contentUrl}
                  alt={attachment.file.originalName}
                  style={{ width: 125, height: 125, objectFit: 'cover', borderRadius: 20 }}
                />
              </a>
            </Paper>
          ))}
        </Stack>
      )}
      {fileAttachments.length > 0 && (
        <Stack spacing={1} marginTop={1} alignItems={isCurrentUser ? 'flex-end' : 'flex-start'}>
          {fileAttachments.map(attachment => (
            <Paper sx={{ p: 1, background: 'rgba(116, 120, 141, 0.1)', borderRadius: 5 }} elevation={6} key={attachment.id}>
              <a href={attachment.file.contentUrl} target="_blank" children={attachment.file.originalName} rel="noreferrer" />
            </Paper>
          ))}
          <Paper />
        </Stack>
      )}
    </Stack>
  );
});

export function MessagePlaceholder({ index }: { index: number }) {
  return (
    <Box alignSelf={index % 4 !== 0 ? 'flex-end' : 'flex-start'} maxWidth="80%">
      <Paper sx={{ p: 1, background: 'rgba(116, 120, 141, 0.1)' }} elevation={0}>
        <Stack alignItems={index % 4 !== 0 ? 'flex-end' : 'flex-start'} width="100%">
          <Skeleton width={220} />
          <Skeleton width={300} />
          <Skeleton width={300} />
          <Skeleton width={300} />
        </Stack>
      </Paper>
    </Box>
  );
}

// MEDIA MAX: WIDTH: 480, HEIGHT: 200
// MORE THAN ONE IMAGE 125x125

import { Warehouse } from '@Warehouse/Warehouse';
import { WarehouseModule } from 'Modules/CTMModules';
import { useQuery } from 'react-query';

export default function useWarehouses() {
  const query = useQuery<Partial<Warehouse>[]>(
    [WarehouseModule.configuration.urlPrefix],
    () => WarehouseModule.api.getAll({ params: { pagination: false } }).then(res => res['hydra:member']),
    {
      cacheTime: 10 * 60 * 1000,
      staleTime: 10 * 60 * 1000,
      refetchOnWindowFocus: true,
      refetchInterval: false,
    },
  );

  return {
    ...query,
    data: query.data || [],
  };
}

import { axiosApi } from '../../../../helpers/Axios';
import { downloadBlobFile } from '../../../../helpers/File';
import { ProcurementOrderModule } from '@Modules/CTMModules';
import { AdsClick, Print } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';
import moment from 'moment';
import { MouseEvent, useState } from 'react';


const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
    PopoverClasses={{ root: 'table-selectable-actions' }}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export default function ProcurementOrdersSelection(props) {
  const { selectedFlatRows } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadAll = (status: number | null = null): void => {
    const allParameters = selectedFlatRows.reduce((total: any[], current: any, currentIndex: number) => {
      total['id[' + currentIndex + ']'] = current.original.id;
      return total;
    }, {});

    if (status) {
      allParameters['newStatus'] = status;
    }

    const query: any = Object.keys(allParameters)
      .map(k => `${k}=${encodeURIComponent(allParameters[k])}`)
      .join('&');

    axiosApi
      .get(ProcurementOrderModule.configuration.api.collection.get + '?' + query, {
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
        },
      })
      .then(response => {
        downloadBlobFile(response.data, `Stos_Zamówień_${moment().format('YYYY_MM_DD_HH_mm_ss')}.pdf`);
      });
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Wybierz akcję
      </Button>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => downloadAll(1500)} disableRipple>
          <AdsClick /> Pobierz wydruki i rozpocznij
        </MenuItem>
        <MenuItem onClick={() => downloadAll(null)} disableRipple>
          <Print /> Pobierz wydruki
        </MenuItem>
      </StyledMenu>
    </div>
  );
}

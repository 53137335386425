type FieldType = {
  val: string;
  label: string;
};

export const fieldTypes: FieldType[] = [
  { val: 'DIVIDER', label: 'Belka' },
  { val: 'STRING', label: 'Text' },
  { val: 'TEXT', label: 'Text (duże pole)' },
  { val: 'NUMBER', label: 'Numeryczne' },
  { val: 'IMAGE', label: 'Zdjęcie' },
  { val: 'FILE', label: 'Plik' },
  { val: 'JSON', label: 'Json(Tablica)' },
  { val: 'RELATION', label: 'Powiązanie do innego modułu' },
  { val: 'DICTIONARY', label: 'Pola wyboru z słownika' },
  { val: 'YOU_TUBE', label: 'Link do filmu youtube' },
  { val: 'BOOLEAN', label: 'Checkbox' },
  { val: 'COLOR', label: 'Wybór koloru' },
  { val: 'READONLY', label: 'Pole tylko do odczytu' },
  { val: 'MATH', label: 'Pole matematyczne' },
  { val: 'CURRENCY', label: 'Pole wyboru waluty' },
  { val: 'COUNTRY', label: 'Pole wyboru kraju' },
  { val: 'CF_IMAGE', label: 'Zdjęcie z galerii' },
  { val: 'PLACEHOLDER', label: 'Dedykowana obsługa pola, w celu zmiany skontaktuj się z programistą' },
];

export const FieldTypesIndexed: { [key: string]: string } = fieldTypes.reduce(
  (prev, curr) => ({
    ...prev,
    [curr.val]: curr.label,
  }),
  {},
);

export default fieldTypes;

import ModuleList from '@Components/Module/ModuleList';
import { FC } from 'react';

const BaseLinkerIntegration: FC = kj => {
  return (
    <>
      <ModuleList moduleName={'integration-base_linker'} defaultFilters={undefined} overrideFormProps={{}} useModal={true} />
    </>
  );
};

export default BaseLinkerIntegration;

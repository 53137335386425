import CTMModule, { CTMListColumn, CTMRecord } from '@Core/Types/CTMModule';
import PermissionPicker from '@Employee/Role/Components/PermissionPicker';

export type Role = CTMRecord & {
  name?: string;
};

const columns: CTMListColumn<Role>[] = [
  {
    id: 'name',
    Header: 'Rola',
    accessor: 'name',
    filterable: true,
    sortable: true,
  },
];

const module: CTMModule<Role> = {
  id: '743f7aa7-a231-4274-a8b0-18f73b617d08',
  dataClass: 'CTM\\Employee\\Entity\\Role',
  urlPrefix: 'employee-role',
  name: 'Pracownicy',
  role: 'ROLE',
  api: {
    item: {
      get: ({ id }) => `/employee/roles/${id}`,
      put: ({ id }) => `/employee/roles/${id}`,
      delete: ({ id }) => `/employee/roles/${id}`,
    },
    collection: {
      get: `/employee/roles`,
      post: `/employee/roles`,
    },
  },
  recordLabel: ({ name }) => name,
  form: {
    fieldComponents: {
      permissions: PermissionPicker,
    },
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;

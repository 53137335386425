import { Field } from './Field';
import { Template } from '@CustomFields/Template';
import CTMModule, { CTMStrictRecord } from 'Modules/Core/Types/CTMModule';
import { pickValueFromRecordByPropertyPath } from 'helpers/ModuleUtils';

type ModuleTemplate = Pick<Template, '@id' | 'name' | 'defaultValues' | 'id' | 'image' | 'system'>;

export type Module = CTMStrictRecord & {
  ['@id']: string;
  id: string;
  name: string;
  dataClass: string;
  relationFieldLabel: string;
  fields: Field[];
  exports: any[];
  exportConfigurations: any[];
  listEndpoint: string;
  system: boolean;
  templates: ModuleTemplate[];
};

interface CustomModuleItemPaths {
  getTemplates: ({ id }: { id: string | number }) => string;
  getConfiguration: ({ id }: { id: string | number }) => string;
  putConfiguration: ({ id }: { id: string | number }) => string;
}

interface CustomModuleCollectionPaths {
  getSections: ({ id }: { id: string | number }) => string;
}

const module: CTMModule<Module, CustomModuleItemPaths, CustomModuleCollectionPaths> = {
  id: '96e7c6b8-b5b9-4053-99c3-e9f6436a84ca',
  dataClass: 'CTM\\CustomField\\Entity\\Module',
  urlPrefix: 'core-MODULE',
  name: 'Moduły',
  role: null,
  api: {
    item: {
      get: ({ id }) => `/custom-fields/modules/${id}`,
      getTemplates: ({ id }) => `/custom-fields/modules/${id}/templates`,
      getConfiguration: ({ id }) => `/custom-fields/modules/${id}/configuration`,
      putConfiguration: ({ id }) => `/custom-fields/modules/${id}/configuration`,
      put: ({ id }) => `/custom-fields/modules/${id}`,
      delete: ({ id }) => `/custom-fields/modules/${id}`,
    },
    collection: {
      get: `/custom-fields/modules`,
      post: `/custom-fields/modules`,
      getSections: ({ id }) => `/custom-fields/module-sections?tab.template.module.id=${id}`,
    },
  },
  recordLabel: (record, allFields) => {
    const { name } = pickValueFromRecordByPropertyPath<Module, any>(record, ['name'], allFields);
    return record.name ?? name ? `${name ?? ''}` : null;
  },
  list: {
    columns: [
      {
        id: 'name',
        filterable: true,
        sortable: true,
        Header: 'Nazwa',
        accessor: 'name',
      },
    ],
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;

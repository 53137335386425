import { SET_DEPARTMENT } from 'store/Accountancy/ActionTypes';

export const LocalStorageKey = 'accountancy_department';

export type AccountancyState = {
  departmentIRI: string | undefined;
};
const initState = (): AccountancyState => ({
  departmentIRI: localStorage.getItem(LocalStorageKey) === 'undefined' ? undefined : localStorage.getItem(LocalStorageKey) ?? undefined,
});

const initialState = initState();

const Configuration = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEPARTMENT:
      localStorage.setItem(LocalStorageKey, action.payload);
      state = {
        ...state,
        departmentIRI: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Configuration;

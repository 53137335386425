import * as CTMModules from '../../CTMModules';
import { get } from 'helpers/Axios';
import { useQuery } from 'react-query';

export type ModuleConfigResponse<T = object> = {
  data: undefined | { configuration: T };
  isLoading: boolean;
  error: any;
};
function useFetchModuleConfig<T>(moduleId: string): ModuleConfigResponse<T> {
  const { data, isLoading, isLoadingError, error } = useQuery(
    `module-config-${moduleId}`,
    () => get(CTMModules.CoreModuleModule.configuration.api.item.getConfiguration({ id: moduleId })),
    {
      cacheTime: 10 * 60 * 1000,
      staleTime: 10 * 60 * 1000,
      refetchOnWindowFocus: true,
      refetchInterval: false,
    },
  );

  return {
    data: isLoadingError ? undefined : data,
    isLoading: isLoading || isLoadingError,
    error,
  };
}

export default useFetchModuleConfig;

import useCurrentUser from '@Employee/Employee/Hooks/UseCurrentUser';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Avatar, AvatarGroup, Badge, CardHeader, IconButton } from '@mui/material';
import useChatManipulationFunctions from 'Modules/Chat/Hooks/UseChatManipulationFunctions';
import useOnlineUsers from 'Modules/Chat/Hooks/UseOnlineUsersQuery';
import useRoomInfo from 'Modules/Chat/Hooks/UseRoomInfo';
import { Room } from 'Modules/Chat/Types/ApiModel';
import { useCallback } from 'react';

interface HeaderProps {
  room: Room;
}
export default function Header({ room }: HeaderProps) {
  const currentUser = useCurrentUser();
  const onlineUsers = useOnlineUsers();
  const info = useRoomInfo(room, onlineUsers, []);
  const avatars = room.members.length > 1 ? room.members.filter(m => m.user.id !== currentUser?.id) : room.members;
  const { toggleRoomIsFavorite, toggleRoomIsHidden } = useChatManipulationFunctions();
  const handleFavoriteClick = useCallback(() => {
    toggleRoomIsFavorite(room['@id'], !room.isFavorite);
  }, [room, toggleRoomIsFavorite]);
  return (
    <CardHeader
      avatar={
        <>
          <IconButton component="a" href="#chat-rooms-container" sx={{ display: { md: 'none' } }}>
            <ArrowBackIosIcon />
          </IconButton>
          <Badge variant="dot" color={info.online ? 'success' : undefined}>
            <AvatarGroup max={4}>
              {avatars.map(member => (
                <Avatar key={member.id} src={member.user.image?.contentUrl} />
              ))}
            </AvatarGroup>
          </Badge>
        </>
      }
      action={<IconButton onClick={handleFavoriteClick}>{room.isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}</IconButton>}
      title={info.name}
      titleTypographyProps={{ fontWeight: 'bold' }}
      sx={{ flexGrow: 0, flexShrink: 1, flexBasis: 'auto' }}
    />
  );
}

import { useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { useAppSelector } from 'store';
import { removeToastFromQueue } from 'store/Toast/actions';

const ToastConsumer = () => {
  const queue = useAppSelector(root => root.Toast.queue, shallowEqual);
  const toast = useToasts();
  const dispatch = useDispatch();

  useEffect(() => {
    if (queue.length > 0) {
      const firstToast = queue[0];
      toast.addToast(firstToast.title, firstToast.config ?? {});
      dispatch(removeToastFromQueue(firstToast.__id));
    }
  }, [queue, dispatch, toast, removeToastFromQueue]);
};
const ToastContainer = () => {
  return (
    <ToastProvider autoDismiss={true} autoDismissTimeout={5500} placement="top-center">
      <ToastConsumer />
    </ToastProvider>
  );
};

export default ToastContainer;

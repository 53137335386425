import { Tooltip } from '@mui/material';
import { trimString } from 'helpers/Format';
import { FC, useState } from 'react';

interface TooltipFieldProps {
  trim: number;
  text: string;
}

const TooltipField: FC<TooltipFieldProps> = ({ trim, text }) => {
  const [open, setOpen] = useState(false);

  return (
    <Tooltip open={open} title={text} onClose={() => setOpen(false)}>
      <span className="text-nowrap" onClick={() => setOpen(true)}>
        {trimString(text, trim)}
      </span>
    </Tooltip>
  );
};

export default TooltipField;

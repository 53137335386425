import ModuleList from '@Components/Module/ModuleList';
import { Box } from '@mui/material';

function InvoiceDepartmentsConfiguration() {
  return (
    <>
      <Box>
        <ModuleList moduleName={'accountancy-department'} defaultFilters={undefined} overrideFormProps={{}} useModal={true} />
      </Box>
    </>
  );
}

export default InvoiceDepartmentsConfiguration;

import { EmployeeWithImageURL } from '@Employee/Employee/Types/Employee';
import { queryClient } from 'App';
import jwt_decode from 'jwt-decode';
import {
  API_ERROR,
  CHANGE_DEFAULT_WAREHOUSE,
  LOGIN_SUCCESS,
  LOGIN_USER,
  LOGIN_USER_BY_PIN,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  SET_PERMISSIONS,
} from 'store/auth/login/actionTypes';

export function decodeUserJWT(jwt) {
  const decoded = jwt_decode<any>(jwt);

  return {
    ...decoded,
    details: {
      ...decoded.details,
      '@id': `/employee/employees/${decoded.details.id}`,
    },
  };
}

const getLoggedInUser = () => {
  const user = localStorage.getItem('authUser');
  if (user) {
    try {
      return JSON.parse(user);
    } catch (e) {
      return null;
    }
  }
  return null;
};

const loggedInUser = getLoggedInUser();

type AuthenticatedUser = {
  iat: number;
  exp: number;
  roles: string[];
  email: string;
  details: EmployeeWithImageURL;
};

export type LoginState = {
  error: string;
  loginType: 'FORM' | 'PIN';
  loading: boolean;
  user: AuthenticatedUser;
  token: string | null;
  refreshToken: string | null;
  permissions: string[];
};
const initState = (): LoginState => ({
  error: '',
  loginType: 'FORM',
  loading: false,
  user: loggedInUser?.token ? decodeUserJWT(loggedInUser?.token) : null,
  token: loggedInUser?.token || null,
  refreshToken: loggedInUser?.refresh_token || null,
  permissions: [],
});
const initialState = initState();

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loginType: 'FORM',
        error: '',
        loading: true,
      };
      break;
    case LOGIN_USER_BY_PIN:
      state = {
        ...state,
        loginType: 'PIN',
        error: '',
        loading: true,
      };
      break;
    case CHANGE_DEFAULT_WAREHOUSE:
      state = {
        ...state,
        user: {
          ...state.user,
          details: {
            ...(state.user.details ?? {}),
            defaults: {
              warehouse: action.payload.newWarehouse,
            },
          },
        },
      };
      break;
    case LOGIN_SUCCESS:
      localStorage.setItem('authUser', JSON.stringify(action.payload));
      state = {
        ...state,
        loading: false,
        token: action.payload.token,
        refreshToken: action.payload.refresh_token,
        user: decodeUserJWT(action.payload.token),
      };
      break;
    case LOGOUT_USER:
    case LOGOUT_USER_SUCCESS:
      queryClient.clear();
      localStorage.removeItem('authUser');
      state = {
        ...state,
        ...initState(),
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case SET_PERMISSIONS:
      state = {
        ...state,
        permissions: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;

import { ModuleProvider } from '../../context/ModuleContext';
import { CTMStrictRecord } from '@Core/Types/CTMModule';
import ModuleForm from 'components/Module/ModuleForm';
import SecuredView from 'components/Theme/Common/SecuredView';
import { useModuleContext } from 'context/ModulesContext';
import { useErrorHandler } from 'helpers/FormHandler';
import { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const InlineForm: FC<{ readonly: boolean; forceModule?: string }> = ({ readonly, forceModule }) => {
  const { id, module } = useParams<{ id?: string; module?: string }>();
  const errorHandler = useErrorHandler();
  const history = useHistory();
  const moduleContext = useModuleContext<CTMStrictRecord, true>(module ?? forceModule ?? '', null, true);
  const role = moduleContext?.configuration.role
    ? (id ? (readonly ? 'ROLE_SHOW_' : 'ROLE_EDIT_') : 'ROLE_CREATE_') + moduleContext?.configuration.role
    : 'ROLE_EMPLOYEE';
  return (
    <ModuleProvider value={moduleContext}>
      <SecuredView role={role} displayError={true}>
        <ModuleForm
          moduleName={module}
          id={id}
          showContextActions={true}
          afterSave={(record, type) => {
            if (type === 'POST') {
              history.push(moduleContext?.editUrl(record.id));
            }
          }}
          onError={err => {
            errorHandler(err);
          }}
          readonly={readonly}
        />
      </SecuredView>
    </ModuleProvider>
  );
};

export default InlineForm;

import { EmployeeModule } from '@Employee/Employee';
import logo from 'assets/images/logo-light.png';
import BaseInput from 'components/Form/BaseInput';
import { post } from 'helpers/Axios';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Alert, Col, Container, Row } from 'reactstrap';

const LostPin = () => {
  document.title = 'Przypomnienie PIN-u | CTM';

  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const submitLostPin = () => {
    setError('');
    if (phone === '') {
      setError('Podaj numer telefonu');
      return;
    }

    post(EmployeeModule.configuration.api.item.postLostPassword, { phone })
      .then(() => {
        setSuccess('Przypomnienie PIN zostało wysłane');
      })
      .catch(() => {
        setError('Wystąpił problem z przypomnieniem PIN-u, skontaktuj się z administratorem.');
      });
  };

  return (
    <Container fluid className="p-0">
      <Row className="p-0 m-0">
        <Col xl={9} className="p-0">
          <div className="auth-full-bg pt-lg-5 p-4">
            <div className="w-100">
              <div className="bg-overlay"></div>
            </div>
          </div>
        </Col>
        <Col xl={3}>
          <div className="auth-full-page-content p-md-5 p-4">
            <div className="w-100">
              <div className="d-flex flex-column h-100">
                <div className="mb-4 mb-md-5">
                  <a href="/" className="d-block auth-logo text-center">
                    <img src={logo} />
                  </a>
                </div>
                <div className="my-auto text-center" style={{ maxWidth: 360, margin: '0 auto' }}>
                  <div>
                    <h5 className="text-primary">Zapomniałeś pinu?</h5>
                    <p className="text-muted">Podaj numer telefonu aby otrzymać swój numer pin.</p>
                  </div>
                  {error !== '' && <Alert color="danger">{error}</Alert>}
                  {success !== '' && <Alert color="success">{success}</Alert>}
                  <div className="mt-1">
                    <BaseInput
                      size={{ md: 12 }}
                      className={''}
                      value={phone}
                      type={'phone'}
                      name="phone"
                      onChange={value => setPhone(value)}
                      inputProps={{
                        placeholder: 'Numer telefonu',
                      }}
                    />
                  </div>
                  <div className="text-center">
                    <button className="btn btn-primary btn-block" onClick={submitLostPin}>
                      Wyślij przypomnienie
                    </button>
                  </div>
                  <div className="text-center mt-2">
                    <Link to="/login" className="text-muted">
                      <i className="mdi mdi-lock me-1" />
                      Zaloguj się
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(LostPin);

LostPin.propTypes = {
  history: PropTypes.object,
};

import { Button, Popover, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { MouseEvent, useState } from 'react';

interface ProductQuantityDetail {
  name: string;
  quantity: number;
}
interface ProductQuantityProps {
  quantity: number;
  unit?: { name?: string };
  details: ProductQuantityDetail[];
}

const ProductQuantity = ({ quantity, details, unit }: ProductQuantityProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const buttonStyle = { borderRadius: '5px', padding: '2px', minWidth: '30px', color: '#fff', width: '100%' };

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Tooltip title="Stan magazynowy">
          <Button variant={'contained'} color={'info'} style={buttonStyle}>
            {quantity}
          </Button>
        </Tooltip>
        {/*{unit?.name ?? 'szt'}*/}
      </Typography>
      {details.length > 0 && (
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell variant={'head'}>Magazyn</TableCell>
                <TableCell variant={'head'}>Ilość</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {details.map((el, i) => (
                <TableRow key={i}>
                  <TableCell>{el.name}</TableCell>
                  <TableCell>
                    {el.quantity}&nbsp;
                    {unit?.name ?? 'szt'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Popover>
      )}
    </div>
  );
};

export default ProductQuantity;

import { combineReducers } from 'redux';
import Accountancy, { AccountancyState } from 'store/Accountancy/Reducer';
import AppConfiguration, { AppConfigurationState } from 'store/AppConfiguration/Configuration/Reducer';
import EmployeeConfiguration, { EmployeeConfigurationState } from 'store/Employee/Configuration/Reducer';
import Lightbox, { LightboxState } from 'store/Lightbox/Lightbox';
import Root, { RootAppState } from 'store/Root/Root';
import TaskManagement, { TaskManagementState } from 'store/TaskManagement/Reducer';
import Toast, { ToastState } from 'store/Toast/reducer';
import Login, { LoginState } from 'store/auth/login/reducer';
import FormErrors, { FormErrorsState } from 'store/form/errors/reducer';
import Layout, { LayoutState } from 'store/layout/reducer';

export type RootState = {
  AppConfiguration: AppConfigurationState;
  Layout: LayoutState;
  Login: LoginState;
  FormErrors: FormErrorsState;
  EmployeeConfiguration: EmployeeConfigurationState;
  Toast: ToastState;
  TaskManagement: TaskManagementState;
  Accountancy: AccountancyState;
  Lightbox: LightboxState;
  Root: RootAppState;
};

const rootReducer = combineReducers<RootState>({
  AppConfiguration,
  Layout,
  Login,
  FormErrors,
  EmployeeConfiguration,
  Toast,
  TaskManagement,
  Accountancy,
  Lightbox,
  Root,
});

export default rootReducer;

import { createModuleColumnConfig, setValuesToFormRecord } from '../../../helpers/ModuleUtils';
import DepartmentModule, { Department } from '@Accountancy/Department/Department';
import InvoiceContextHeader from '@Accountancy/Invoice/Components/ContextHeader/InvoiceContextHeader';
import DataGridHeader from '@Accountancy/Invoice/Components/List/DataGridHeader';
import InvoiceDefaultsConfiguration from '@Accountancy/Invoice/Configuration/InvoiceDefaultsConfiguration';
import InvoiceDepartmentsConfiguration from '@Accountancy/Invoice/Configuration/InvoiceDepartmentsConfiguration';
import InvoiceNumerationConfiguration from '@Accountancy/Invoice/Configuration/InvoiceNumerationConfiguration';
import { InvoiceStatus, statusLabel } from '@Accountancy/Invoice/InvoiceStatuses';
import { InvoiceType, InvoiceTypes, typeLabel } from '@Accountancy/Invoice/InvoiceTypes';
import InvoiceDefaultSection from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceDefaultSection';
import MoneyView from '@Components/View/MoneyView';
import Contractor, { Contractor as ContractorModel } from '@Contractor/Contractor';
import { ValueAddedTax } from '@Core/Accountancy/ValueAddedTax';
import useFetchModuleConfig from '@Core/Hooks/useFetchModuleConfig';
import Address from '@Core/Types/Address';
import { Field } from '@CustomFields/Field';
import { EmployeeWithImageURL } from '@Employee/Employee/Types/Employee';
import { Product } from '@Manufacture/Product';
import { Unit } from '@Manufacture/Unit';
import { ArrowLeftOutlined, ArrowRightOutlined, AttachMoneyOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import CTMModule, { CTMListColumn, CTMStrictRecord } from 'Modules/Core/Types/CTMModule';
import moment from 'moment/moment';
import { LocalStorageKey } from 'store/Accountancy/Reducer';

export type GTU =
  | 'GTU_01'
  | 'GTU_02'
  | 'GTU_03'
  | 'GTU_04'
  | 'GTU_05'
  | 'GTU_06'
  | 'GTU_07'
  | 'GTU_08'
  | 'GTU_09'
  | 'GTU_10'
  | 'GTU_11'
  | 'GTU_12'
  | 'GTU_13';

export type InvoiceConfiguration = {
  InvoiceDefaults: {
    innerContractor: null | string | ContractorModel;
    issueAddress: Address;
  };
  InvoiceNumeration: {
    types: Record<InvoiceType, string>;
    numberLength: number;
  };
};

export type InvoiceContractor = {
  name: string;
  taxNumberPrefix: string | null;
  taxNumber: string | null;
  backAccount: string | null;
  backName: string | null;
  street: string | null;
  house: string | null;
  flat: string | null;
  post: string | null;
  city: string | null;
  district: string | null;
  province: string | null;
  country: string | null;
  email: string | null;
  fax: string | null;
  phone: string | null;
  www: string | null;
};

export type InvoicePosition = {
  id: string;
  invoice: Invoice | null;
  product: Product | null;
  name: string;
  additionalInfo: string | null;
  description: string | null;
  code: string | null;
  GTU: GTU | null;
  discount: number;
  quantity: number;
  unit: Unit | null;
  priceUnitNet: number;
  priceUnitGross: number;
  priceTotalNet: number;
  priceTotalGross: number;
  priceUnitNetHomeCurrency: number;
  priceUnitGrossHomeCurrency: number;
  priceTotalNetHomeCurrency: number;
  priceTotalGrossHomeCurrency: number;
  tax: ValueAddedTax | null;
};

export type Invoice = CTMStrictRecord & {
  id: string;
  type: InvoiceType;
  number: string;
  income: boolean;
  oss: boolean;
  splitPayment: boolean;
  status: InvoiceStatus;
  amountPaidGross: number;
  amountPaidNet: number;
  amountPaidGrossHomeCurrency: number;
  amountPaidNetHomeCurrency: number;
  totalPriceNet: number;
  totalPriceVat: number;
  totalPriceVatHomeCurrency: number;
  totalPriceGross: number;
  totalPriceGrossHomeCurrency: number;
  totalPriceNetHomeCurrency: number;
  currency: string;
  exchangeCurrency: string;
  exchangeRate: number;
  exchangeInfoDate: string;
  lang: string;
  secondLang: string;
  description: null | string;
  ownDescription: null | string;
  gtuCodes: string[];
  parent: null | Partial<Invoice>;
  createdFrom: null | Partial<Invoice>;
  correction: null | Partial<Invoice>;
  issueDate: string;
  sellDate: string;
  paymentDeadline: string;
  paymentDate: string;
  issueAddress: Address;
  seller: null | ContractorModel;
  buyer: null | ContractorModel;
  recipient: null | ContractorModel;
  sellerRaw: InvoiceContractor;
  buyerRaw: InvoiceContractor;
  recipientRaw: InvoiceContractor;
  positions: InvoicePosition[];
  department: null | string | Department;
};

export const sumPositions = (positions: InvoicePosition[], field: keyof InvoicePosition): number => {
  const sum = positions.reduce((prev, curr) => {
    const partValue = curr[field];
    if (typeof partValue === 'number') {
      return prev + partValue;
    }
    return prev;
  }, 0);
  return parseFloat(sum.toFixed(2));
};

const columns: CTMListColumn<Invoice>[] = [
  {
    id: 'department.id',
    filterable: true,
    sortable: true,
    forceHidden: column => true,
    Header: 'Typ',
    minWidth: 160,
    flex: 1,
    ...createModuleColumnConfig('department', DepartmentModule),
  },
  {
    id: 'number',
    Header: 'Numer',
    accessor: ({ number, income }) => (
      <Box display="flex">
        {income ? <ArrowRightOutlined color="success" /> : <ArrowLeftOutlined color="error" />}
        <AttachMoneyOutlined color={income ? 'success' : 'error'} />
        <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
          {number}
        </Typography>
      </Box>
    ),
    filterable: true,
    sortable: true,
  },
  {
    id: 'type',
    Header: 'Typ',
    accessor: ({ type }) => typeLabel(type),
    filterable: true,
    sortable: true,
    noBreak: true,
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: ({ status }) => statusLabel(status),
    filterable: true,
    sortable: true,
    noBreak: true,
  },
  {
    id: 'seller.id',
    Header: 'Sprzedawca',
    filterable: true,
    sortable: true,
    minWidth: 160,
    ...createModuleColumnConfig('seller', Contractor),
  },
  {
    id: 'buyer.id',
    Header: 'Kupujący',
    filterable: true,
    sortable: true,
    minWidth: 160,
    ...createModuleColumnConfig('buyer', Contractor),
  },
  {
    id: 'totalPriceNetHomeCurrency',
    Header: 'Netto',
    accessor: ({ totalPriceNetHomeCurrency, totalPriceNet, currency, exchangeRate, issueDate, exchangeInfoDate }) => (
      <MoneyView
        value={totalPriceNet}
        homeCurrencyValue={totalPriceNetHomeCurrency}
        currency={currency}
        exchangeDate={exchangeInfoDate ?? issueDate}
        exchangeRate={exchangeRate}
      />
    ),
    filterable: true,
    sortable: true,
  },
  {
    id: 'totalPriceVatHomeCurrency',
    Header: 'VAT',
    accessor: ({ totalPriceVatHomeCurrency, totalPriceVat, currency, exchangeRate, issueDate, exchangeInfoDate }) => (
      <MoneyView
        value={totalPriceVat}
        homeCurrencyValue={totalPriceVatHomeCurrency}
        currency={currency}
        exchangeDate={exchangeInfoDate ?? issueDate}
        exchangeRate={exchangeRate}
      />
    ),
    filterable: true,
    sortable: true,
  },
  {
    id: 'totalPriceGrossHomeCurrency',
    Header: 'Brutto',
    accessor: ({ totalPriceGrossHomeCurrency, totalPriceGross, currency, exchangeRate, issueDate, exchangeInfoDate }) => (
      <MoneyView
        value={totalPriceGross}
        homeCurrencyValue={totalPriceGrossHomeCurrency}
        currency={currency}
        exchangeDate={exchangeInfoDate ?? issueDate}
        exchangeRate={exchangeRate}
      />
    ),
    filterable: true,
    sortable: true,
  },
  {
    id: 'amountPaidGrossHomeCurrency',
    Header: 'Opłacona kwota',
    accessor: ({ amountPaidGrossHomeCurrency }) => (
      <MoneyView value={amountPaidGrossHomeCurrency} homeCurrencyValue={amountPaidGrossHomeCurrency} currency={'PLN'} />
    ),
    filterable: true,
    sortable: true,
    noBreak: true,
  },
  {
    id: 'issueDate',
    Header: 'Data wystawienia',
    accessor: ({ issueDate }) => moment(issueDate).format('lll'),
    filterable: true,
    sortable: true,
    noBreak: true,
  },
];

export type InvoiceModule = CTMModule<Invoice>;

const module: InvoiceModule = {
  id: '76c6b67d-634b-4fa3-9ee1-24b7b30cadcd',
  dataClass: 'CTM\\Accountancy\\Entity\\Invoice',
  urlPrefix: 'accountancy-invoice',
  name: 'Faktury',
  role: 'ACCOUNTANCY_INVOICE',
  api: {
    item: {
      get: ({ id }) => `/accountancy/invoices/${id}`,
      put: ({ id }) => `/accountancy/invoices/${id}`,
      delete: ({ id }) => `/accountancy/invoices/${id}`,
    },
    collection: {
      get: `/accountancy/invoices`,
      post: `/accountancy/invoices`,
    },
  },
  recordLabel: ({ number, income, type }) =>
    `${InvoiceTypes.find(el => el.type === type)?.label} (${income ? 'Przychód' : 'Koszt'}) ${number ? ` - ${number}` : ''}`,
  form: {
    disableCreateNewRecord: true,
    sectionComponents: {
      ['7abe5de2-bdc3-4423-abd1-f5eda3897f03']: InvoiceDefaultSection,
    },
    defaultRecord: (allFields: Field[], currentUser: EmployeeWithImageURL, queryParams: any, config: InvoiceConfiguration) => {
      const income = queryParams?.income === 'true';
      const rawPrefix = income ? 'sellerRaw' : 'buyerRaw';
      return setValuesToFormRecord({ '@formValues': {} }, allFields, {
        income,
        type: queryParams?.type ?? InvoiceTypes[0].type,
        [income ? 'seller' : 'buyer']: config.InvoiceDefaults.innerContractor,
        ...(typeof config.InvoiceDefaults.innerContractor === 'object'
          ? {
              [`${rawPrefix}.name`]: config.InvoiceDefaults.innerContractor?.name,
              [`${rawPrefix}.taxNumberPrefix`]: config.InvoiceDefaults.innerContractor?.tin.prefix,
              [`${rawPrefix}.taxNumber`]: config.InvoiceDefaults.innerContractor?.tin.number,
              [`${rawPrefix}.post`]: config.InvoiceDefaults.innerContractor?.address.post,
              [`${rawPrefix}.city`]: config.InvoiceDefaults.innerContractor?.address.city,
              [`${rawPrefix}.province`]: config.InvoiceDefaults.innerContractor?.address.province,
              [`${rawPrefix}.district`]: config.InvoiceDefaults.innerContractor?.address.district,
              [`${rawPrefix}.street`]: config.InvoiceDefaults.innerContractor?.address.street,
              [`${rawPrefix}.house`]: config.InvoiceDefaults.innerContractor?.address.house,
              [`${rawPrefix}.flat`]: config.InvoiceDefaults.innerContractor?.address.flat,
              [`${rawPrefix}.country`]: config.InvoiceDefaults.innerContractor?.address.country,
            }
          : {}),
        ['issueAddress.post']: config.InvoiceDefaults.issueAddress.post,
        ['issueAddress.city']: config.InvoiceDefaults.issueAddress.city,
        ['issueAddress.province']: config.InvoiceDefaults.issueAddress.province,
        ['issueAddress.district']: config.InvoiceDefaults.issueAddress.district,
        ['issueAddress.street']: config.InvoiceDefaults.issueAddress.street,
        ['issueAddress.house']: config.InvoiceDefaults.issueAddress.house,
        ['issueAddress.flat']: config.InvoiceDefaults.issueAddress.flat,
        ['issueAddress.country']: config.InvoiceDefaults.issueAddress.country,
      });
    },
    forceReadonlyField: (field, values, fields) => {
      return field.propertyPath === 'number' && values?.[fields.find(el => el.propertyPath === 'income')?.id ?? ''] === true;
    },
    prepareFetchedRecord: (record, allFields) => {
      return setValuesToFormRecord(record, allFields, {
        positions: record.positions?.map(el => ({
          ...el,
          priceUnitNet: el.priceUnitNet / 100,
          priceUnitGross: el.priceUnitGross / 100,
          priceTotalNet: el.priceTotalNet / 100,
          priceTotalGross: el.priceTotalGross / 100,
        })),
      });
    },
    prepareRecordToSave: (record, allFields) => {
      const isUpdate = record.hasOwnProperty('@id');

      return {
        ...record,
        seller: record.seller?.['@id'] ?? record.seller,
        buyer: record.buyer?.['@id'] ?? record.buyer,
        department: isUpdate
          ? undefined
          : localStorage.getItem(LocalStorageKey) === 'undefined'
          ? null
          : localStorage.getItem(LocalStorageKey),
        recipient: record.recipient?.['@id'] ?? record.recipient,
        positions: record.positions?.map(el => ({
          ...el,
          unit: el.unit?.['@id'] ?? el.unit,
          tax: el.tax?.['@id'] ?? el.tax,
          product: el.product?.['@id'] ?? el.product,
          priceUnitNet: parseInt(`${el.priceUnitNet * 100}`),
          priceUnitGross: parseInt(`${el.priceUnitGross * 100}`),
          priceTotalNet: parseInt(`${el.priceTotalNet * 100}`),
          priceTotalGross: parseInt(`${el.priceTotalGross * 100}`),
        })),
      };
    },
  },
  list: {
    headerOverride: DataGridHeader,
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'issueDate', desc: true }],
    resolveQueryKey: rootstate => `accountancy-invoices-${rootstate.Accountancy.departmentIRI}`,
  },
  configuration: {
    InvoiceDefaults: {
      name: 'Ogólne',
      component: InvoiceDefaultsConfiguration,
    },
    InvoiceNumeration: {
      name: 'Numeracja',
      component: InvoiceNumerationConfiguration,
    },
    Departments: {
      name: 'Działy',
      component: InvoiceDepartmentsConfiguration,
    },
  },
  contextHeader: InvoiceContextHeader,
};

export const useInvoiceConfiguration = () => useFetchModuleConfig<InvoiceConfiguration>(module.id);

export default module;

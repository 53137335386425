import { FieldType } from '@Components/CustomFields/FieldTypes';
import CTMModule, { CTMStrictRecord } from 'Modules/Core/Types/CTMModule';
import { pickValueFromRecordByPropertyPath } from 'helpers/ModuleUtils';

export type Field<T = any> = CTMStrictRecord & {
  ['@id']: string;
  id: string;
  name: string;
  defaultValue: any;
  propertyPath: string | (keyof T & string);
  type: FieldType;
  config: any;
  system: boolean;
  position: number;
  labelerId: number;
  multiLanguage: boolean;
  hideRules: any[];
};

const module: CTMModule<Field> = {
  id: 'FieldModule',
  dataClass: 'CTM\\CustomField\\Entity\\Field',
  urlPrefix: 'core-fields',
  name: 'Moduły',
  role: null,
  api: {
    item: {
      get: ({ id }) => `/custom-fields/fields/${id}`,
      put: ({ id }) => `/custom-fields/fields/${id}`,
      delete: ({ id }) => `/custom-fields/fields/${id}`,
    },
    collection: {
      get: `/custom-fields/fields`,
      post: `/custom-fields/fields`,
    },
  },
  recordLabel: (record, allFields) => {
    const { name } = pickValueFromRecordByPropertyPath<Field, any>(record, ['name'], allFields);
    return name ? `${name ?? ''}` : null;
  },
};

export default module;

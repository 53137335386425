import InheredSection from '@Components/CustomFields/InheredSection';
import { FormConfiguration } from '@Core/Types/CTMModule';
import { Template } from '@CustomFields/Template';
import CustomSection from 'components/CustomFields/CustomSection';
import { FC } from 'react';
import { Row } from 'reactstrap';

export type CustomTabSectionProps = Omit<CustomTabProps, 'tab'> & {
  section: CustomTabProps['tab']['sections'][0];
  useCollapse: boolean;
};

const CustomTabSection: FC<CustomTabSectionProps> = ({
  values,
  onUpdate,
  readonly,
  fieldComponents,
  sectionComponents,
  recordId,
  forceReadonlyField,
  section,
  useCollapse,
}) => {
  if (sectionComponents?.hasOwnProperty(section.id)) {
    const SectionOverrideComponent = sectionComponents[section.id];

    return (
      <SectionOverrideComponent
        section={section}
        values={values}
        components={fieldComponents}
        useCollapse={useCollapse}
        useGridLayout={section.useGridLayout}
        onUpdate={onUpdate}
        readonly={readonly}
        recordId={recordId}
        forceReadonlyField={forceReadonlyField}
      />
    );
  }

  if (section['@type'] === 'ModuleSection') {
    return (
      <CustomSection
        section={section}
        values={values}
        components={fieldComponents}
        useCollapse={useCollapse}
        useGridLayout={section.useGridLayout}
        onUpdate={onUpdate}
        readonly={readonly}
        recordId={recordId}
        forceReadonlyField={forceReadonlyField}
      />
    );
  }

  return <InheredSection section={section} values={values} />;
};

export type CustomTabProps = {
  tab: Template['tabs'][0];
  values: { [key: string]: any };
  onUpdate: (value: any, field: string) => void;
  readonly: boolean;
  fieldComponents: FormConfiguration['fieldComponents'];
  sectionComponents: FormConfiguration['sectionComponents'];
  recordId?: string;
  forceReadonlyField?: FormConfiguration['forceReadonlyField'];
};
const CustomTab: FC<CustomTabProps> = props => {
  const { tab, values, onUpdate, readonly, fieldComponents, sectionComponents, recordId, forceReadonlyField } = props;
  return (
    <>
      <Row>
        <div className="accordion">
          {tab.sections.map(section => (
            <CustomTabSection
              key={section['@id']}
              values={values}
              onUpdate={onUpdate}
              readonly={readonly}
              fieldComponents={fieldComponents}
              sectionComponents={sectionComponents}
              recordId={recordId}
              forceReadonlyField={forceReadonlyField}
              section={section}
              useCollapse={tab.sections.length > 1}
            />
          ))}
        </div>
      </Row>
    </>
  );
};

export default CustomTab;

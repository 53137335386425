import { Field } from '@CustomFields/Field';
import { RemoveCircle } from '@mui/icons-material';
import { Button, TableCell, TableRow } from '@mui/material';
import CustomField from 'components/CustomFields/CustomField';
import ListPicker from 'components/Form/ListPicker';

const FieldConditionRow = ({ fieldShowCondition, fields, onChange, onRemove }) => {
  const resolveFieldConfiguration = fieldId => fields.find(v => v['@id'] === fieldId);
  const cmpOptions = [
    {
      value: 'eq',
      label: 'jest równe',
      isAllowed: field => true,
    },
    {
      value: 'neq',
      label: 'różni się od',
      isAllowed: field => true,
    },
    {
      value: 'like',
      label: 'zawiera',
      isAllowed: field => true,
    },
    {
      value: 'nlike',
      label: 'Nie zawiera',
      isAllowed: field => true,
    },
  ];

  return (
    <>
      <TableRow>
        <TableCell style={{ width: 35 }}>
          <Button size={'small'} startIcon={<RemoveCircle style={{ color: '#b00020' }} />} onClick={onRemove} />
        </TableCell>
        <TableCell>
          <ListPicker<Field>
            name={''}
            value={fieldShowCondition.comparedField}
            label={'Pole'}
            options={fields}
            optionTrackBy="@id"
            optionLabel={fieldCfg => `${(fieldCfg.name ?? '') === '' ? 'Nazwa pola' : fieldCfg.name}`}
            onChange={val =>
              onChange({
                ...fieldShowCondition,
                comparedField: val,
              })
            }
          />
        </TableCell>
        <TableCell>
          <ListPicker
            name={''}
            value={fieldShowCondition.compareMethod}
            label={'Warunek'}
            options={cmpOptions.filter(option =>
              option.isAllowed(fieldShowCondition.comparedField?.['@id'] ?? fieldShowCondition.comparedField),
            )}
            optionTrackBy="value"
            optionLabel="label"
            onChange={val => onChange({ ...fieldShowCondition, compareMethod: val })}
          />
        </TableCell>
        <TableCell>
          {resolveFieldConfiguration(fieldShowCondition.comparedField?.['@id'] ?? fieldShowCondition.comparedField) && (
            <CustomField
              value={fieldShowCondition.value}
              disableHideRules={true}
              field={resolveFieldConfiguration(fieldShowCondition.comparedField?.['@id'] ?? fieldShowCondition.comparedField)}
              onUpdate={val =>
                onChange({
                  ...fieldShowCondition,
                  value: !val ? val : (val.hasOwnProperty('@id') ? val['@id'] : val) + '',
                })
              }
              customProps={{ disableGroupMargin: true }}
              fields={[]}
              values={{}}
              readonly={false}
              components={{}}
              size={12}
              recordId={null}
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default FieldConditionRow;

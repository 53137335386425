import { useAppDispatch } from '../../../../../store';
import { addSingleToast } from '../../../../../store/Toast/actions';
import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import Loader from '@Components/Theme/Common/Loader';
import useMediaDownload from '@Core/Hooks/useMediaDownload';
import { DeliveryMethod as DeliveryMethodModel } from '@Delivery/DeliveryMethod';
import ClientOrder, { ClientOrderPackage } from '@Ecommerce/ClientOrder';
import { DeleteForeverOutlined, DownloadOutlined, OpenInNewOutlined } from '@mui/icons-material';
import { Alert, Button, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  textAlign: 'center',
  padding: 2,
}));

const OrderPackages: CustomFieldComponentType<true, ClientOrderPackage[] | null> = ({
  field,
  fields,
  value,
  values,
  onChange,
  readonly,
  recordId,
}) => {
  const { downloadMedia } = useMediaDownload();
  const dispatch = useAppDispatch();
  const deliveryMethodFieldId: string = fields.find(f => f.propertyPath === 'deliveryMethod')?.id ?? '';
  const deliveryMethod: DeliveryMethodModel | null = values?.[deliveryMethodFieldId] ?? null;
  const ids = values?.ids ?? {};
  const isBaseLinker = Object.keys(ids).some(key => key.includes('BaseLinker_'));
  const [generating, setGenerating] = useState<boolean>(false);
  const packages = Object.values(value ?? []);

  const generatePackages = () => {
    if (!ids.ecm) {
      return;
    }
    setGenerating(true);
    ClientOrder.api.custom
      .generatePackages(ids.ecm)
      .then(clientOrder => {
        dispatch(addSingleToast({ title: `Utworzono przesyłkę`, config: { appearance: 'success' } }));
        onChange(clientOrder.packages, field.id);
      })
      .catch(error => {
        dispatch(addSingleToast({ title: `Błąd podczas generowania przesyłki`, config: { appearance: 'error' } }));
        if (error.hasOwnProperty('response') && error.response.status === 422) {
          dispatch(addSingleToast({ title: error.response.data?.['hydra:description'], config: { appearance: 'error' } }));
        } else {
          dispatch(addSingleToast({ title: error.message, config: { appearance: 'error' } }));
        }
      })
      .finally(() => setGenerating(false));
  };

  if (packages.length === 0) {
    if (isBaseLinker && deliveryMethod?.baseLinkerServiceCode && ids.ecm && !readonly) {
      return (
        <Grid container={true}>
          <Grid xs={8}>
            <Alert severity="warning">Brak przesyłek, wygeneruj</Alert>
          </Grid>
          <Grid xs={4} justifyContent="center" justifyItems="center" display="flex">
            {generating && <Loader>Twa generowanie...</Loader>}
            {!generating && (
              <Button color="primary" variant="contained" onClick={generatePackages}>
                Wygeneruj
              </Button>
            )}
          </Grid>
        </Grid>
      );
    }
    return <div>Brak przesyłek</div>;
  }

  const deletePackage = index => {
    const newPackages = packages.filter((_, key) => key !== index);
    onChange(newPackages, field.id);

    dispatch(
      addSingleToast({
        title: `Usunięto przesyłkę. Dopiero po zapisaniu będzie możliwe wygenerowanie nowej`,
        config: { appearance: 'success' },
      }),
    );
  };

  return (
    <div style={{ overflowX: 'auto' }}>
      <Table style={{ minWidth: 640 }}>
        <TableHead>
          <TableRow>
            <CustomTableCell colSpan={3}></CustomTableCell>
            <CustomTableCell colSpan={4}>Wymiary / waga</CustomTableCell>
            <CustomTableCell colSpan={4}></CustomTableCell>
          </TableRow>
          <TableRow>
            <CustomTableCell>Kurier</CustomTableCell>
            <CustomTableCell>Numer u kuriera</CustomTableCell>
            <CustomTableCell>Etykieta</CustomTableCell>
            <CustomTableCell>Szerokość</CustomTableCell>
            <CustomTableCell>Głębokość</CustomTableCell>
            <CustomTableCell>Wysokość</CustomTableCell>
            <CustomTableCell>Waga</CustomTableCell>
            <CustomTableCell>Typ</CustomTableCell>
            <CustomTableCell>Status</CustomTableCell>
            <CustomTableCell>Link śledzenia</CustomTableCell>
            <CustomTableCell>Usuń</CustomTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {packages.map(
            (item: ClientOrderPackage, key) =>
              typeof item.label !== 'string' && (
                <TableRow key={key}>
                  <CustomTableCell>{item.courierCode}</CustomTableCell>
                  <CustomTableCell>{item.courierPackageNumber}</CustomTableCell>
                  <CustomTableCell>
                    {item.label?.contentUrl && (
                      <Grid display="flex" justifyContent="space-around">
                        <span style={{ cursor: 'pointer' }} onClick={() => typeof item.label === 'object' && downloadMedia(item.label)}>
                          <DownloadOutlined color="success" />
                        </span>
                        <a href={item.label.contentUrl} target="_blank" rel="noreferrer">
                          <OpenInNewOutlined color="primary" />
                        </a>
                      </Grid>
                    )}
                    {!item.label?.contentUrl && 'Brak'}
                  </CustomTableCell>
                  <CustomTableCell>{item.packageWidth || 0}cm</CustomTableCell>
                  <CustomTableCell>{item.packageLength || 0}cm</CustomTableCell>
                  <CustomTableCell>{item.packageThickness || 0}cm</CustomTableCell>
                  <CustomTableCell>{item.weigthKg || 0}kg</CustomTableCell>
                  <CustomTableCell>{item.packageType}</CustomTableCell>
                  <CustomTableCell>{item.trackingStatus}</CustomTableCell>
                  <CustomTableCell>
                    {item.trackingUrl && (
                      <a href={item.trackingUrl} target="_blank" rel="noreferrer">
                        <OpenInNewOutlined color="primary" />
                      </a>
                    )}
                    {!item.trackingUrl && 'Brak'}
                  </CustomTableCell>
                  <CustomTableCell style={{ maxWidth: 50 }}>
                    <DeleteForeverOutlined color="error" style={{ cursor: 'pointer' }} onClick={() => deletePackage(key)} />
                  </CustomTableCell>
                </TableRow>
              ),
          )}
        </TableBody>
      </Table>
    </div>
  );
};

OrderPackages.provideAllValues = true;
export default OrderPackages;

import { CURRENCIES_INDEXED } from '../Form/CurrencySelectSingle';
import Tooltip from '@mui/material/Tooltip';
import CurrencyExchange from 'Modules/Core/Services/CurrencyExchange';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';

interface MoneyViewProps {
  value: number;
  rate?: number;
  homeCurrencyValue?: number;
  exchangeRate?: number;
  currency: string;
  exchangeDate?: string;
  isInteger?: boolean;
}

const MoneyView: FC<MoneyViewProps> = props => {
  const { value, currency = 'PLN', isInteger = true, homeCurrencyValue, exchangeDate, exchangeRate } = props;
  const [convertedValue, setConvertedValue] = useState<number | null>(homeCurrencyValue ?? (props.rate ? props.rate * value : null));
  useEffect(() => {
    if (props.rate) {
      return;
    }
    if (!homeCurrencyValue && currency && currency !== 'PLN') {
      try {
        if (exchangeRate) {
          setConvertedValue(exchangeRate * value);
        } else {
          CurrencyExchange.getCurrencyExchangeRate('PLN', currency, exchangeDate ?? moment().format('YYYY-MM-DD')).then(exchangeRate => {
            setConvertedValue(exchangeRate.exchangeBuy(value));
          });
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [convertedValue, exchangeDate, currency, value]);

  useEffect(() => {
    if (homeCurrencyValue !== null) {
      setConvertedValue(homeCurrencyValue ?? null);
    }
  }, [homeCurrencyValue]);

  return (
    <>
      {isInteger ? (value / 100).toFixed(2) : value.toFixed(2)} {CURRENCIES_INDEXED[currency]?.symbol}
      {currency !== 'PLN' && convertedValue !== null && (
        <>
          <br />
          {exchangeDate && (
            <Tooltip title={`Przeliczone wg. kursu PLN/${currency} na dzień ${moment(exchangeDate).format('ll')}`}>
              <div>
                {isInteger ? (convertedValue / 100).toFixed(2) : convertedValue.toFixed(2)} {CURRENCIES_INDEXED['PLN']?.symbol}
              </div>
            </Tooltip>
          )}
          {!exchangeDate && (
            <>
              {isInteger ? (convertedValue / 100).toFixed(2) : convertedValue.toFixed(2)} {CURRENCIES_INDEXED['PLN']?.symbol}
            </>
          )}
        </>
      )}
    </>
  );
};

export default MoneyView;

import useCurrentUser from '@Employee/Employee/Hooks/UseCurrentUser';
import { Add, HighlightOff, Report } from '@mui/icons-material';
import { ClientOrderModule } from 'Modules/CTMModules';
import TextInput from 'components/Form/MUI/TextInput';
import { Col, Container } from 'components/Theme/Grid';
import { put } from 'helpers/Axios';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody } from 'reactstrap';
import { addSingleToast } from 'store/Toast/actions';

interface ReportTroubleProps {
  clientOrderId: string;
  initialTrouble: string | null;
}

const ReportTrouble: FC<ReportTroubleProps> = ({ clientOrderId, initialTrouble }) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  const [open, setOpen] = useState<boolean>(false);
  const [trouble, setTrouble] = useState<string | null>(initialTrouble);
  const [form, setForm] = useState<{ trouble: string | null }>({ trouble: '' });
  const [loading, setLoading] = useState<boolean>(false);

  const updateFormValue = (value: string | null | undefined, field: string | undefined): void => {
    if (typeof field !== 'string') {
      return;
    }

    setForm({ ...form, [field]: value });
  };

  const save = (): void => {
    setLoading(true);
    form.trouble = form.trouble + '\n' + currentUser?.firstName + ' ' + currentUser?.lastName;
    put(ClientOrderModule.configuration.api.item.trouble({ id: clientOrderId }), form).then(() => {
      setTrouble(form.trouble);
      setOpen(false);
      dispatch(addSingleToast({ title: `Problem został zgłoszony.`, config: { appearance: 'success' } }));

      setLoading(false);
    });
  };

  if (trouble || loading) {
    return <></>;
  }

  return (
    <>
      <Button variant="contained" color="error" className={'btn btn-accent ml-1'} onClick={() => setOpen(true)}>
        <Report style={{ fontSize: '0.8rem' }} /> Zgłoś problem
      </Button>
      <Modal
        style={{ maxWidth: '400px' }}
        isOpen={open}
        centered={true}
        size={'sm'}
        backdrop={true}
        toggle={() => setOpen(false)}
        backdropClassName={'asd'}
      >
        <ModalBody className="py-3 px-5">
          <HighlightOff style={{ position: 'absolute', top: 10, right: 10 }} onClick={() => setOpen(false)} />
          <h3>Zgłaszanie problemu</h3>
          <Container spacing={2}>
            <Col xs={12}>
              <TextInput maxRows={5} textarea={true} label={'Opis'} value={form.trouble} name="trouble" onChange={updateFormValue} />
            </Col>
            <Col xs={12}>
              <Button variant={'contained'} color={'success'} style={{ width: '100%' }} onClick={save}>
                <Add /> Zgłoś
              </Button>
            </Col>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ReportTrouble;

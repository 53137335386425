import Department, { Department as DepartmentModel } from '@Accountancy/Department/Department';
import Invoice, { Invoice as InvoiceModel } from '@Accountancy/Invoice/Invoice';
import CRUDModule from '@Core/CRUDModule';

export const InvoiceModule = new CRUDModule<InvoiceModel>(Invoice);
export const DepartmentModule = new CRUDModule<DepartmentModel>(Department);

export default {
  [Invoice.urlPrefix]: InvoiceModule,
  [Department.urlPrefix]: DepartmentModule,
};

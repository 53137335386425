import { ModuleContext } from 'context/ModuleContext';
import { useContext } from 'react';

function ModuleContextHeader() {
  const moduleContext = useContext(ModuleContext);

  if (!moduleContext) {
    return null;
  }

  const ContextHeaderComponent = moduleContext.module.configuration.contextHeader;

  if (!ContextHeaderComponent) {
    return null;
  }
  return <ContextHeaderComponent />;
}

export default ModuleContextHeader;

import Loader from '@Components/Theme/Common/Loader';
import CRUDModule from '@Core/CRUDModule';
import useFetchModuleConfig from '@Core/Hooks/useFetchModuleConfig';
import useFetchModuleData from '@Core/Hooks/useFetchModuleData';
import { CTMStrictRecord } from '@Core/Types/CTMModule';
import { Module } from '@CustomFields/CoreModule';
import { PropsWithChildren, createContext, useContext, useMemo } from 'react';

type ModulesContextValue<T extends CTMStrictRecord = any, C = object> = {
  module: CRUDModule<T>;
  moduleData: Module;
  moduleConfig: C;
};

export const ModuleContext = createContext<null | ModulesContextValue<any, any>>(null);

export function ModuleProvider<T extends CTMStrictRecord = any, C = object>(
  props: PropsWithChildren<{ value: ModulesContextValue<T, C>['module'] }>,
) {
  const { isLoading: isLoadingModule, data: moduleData } = useFetchModuleData(props.value.configuration.id);
  const { isLoading: isLoadingModuleConfig, data: moduleConfig } = useFetchModuleConfig<C>(props.value.configuration.id);

  const context = useMemo<ModulesContextValue<T, C>>(() => {
    if (!moduleData || !moduleConfig) {
      return {} as any;
    }

    return {
      isLoading: isLoadingModule || isLoadingModuleConfig,
      module: props.value,
      moduleData: moduleData,
      moduleConfig: moduleConfig.configuration,
    };
  }, [isLoadingModule, isLoadingModuleConfig, moduleData, moduleConfig]);

  if (isLoadingModule || !moduleData || isLoadingModuleConfig || !moduleConfig) {
    return <Loader iconsClasses="font-size-14" />;
  }

  return <ModuleContext.Provider value={context}>{props.children}</ModuleContext.Provider>;
}

export function useModule<T extends CTMStrictRecord = any, C = object>(): ModulesContextValue<T, C> {
  const module = useContext<null | ModulesContextValue<T, C>>(ModuleContext);
  if (!module) {
    throw new Error('useModule must be used within a ModuleProvider');
  }
  return module;
}

export function useModuleConfig<T extends CTMStrictRecord = any>(): ModulesContextValue<T>['module']['configuration'] {
  const { module } = useModule<T>();
  return module.configuration;
}

export function useModuleData<T extends CTMStrictRecord = any>(): Module {
  const module = useModule<T>();
  return module.moduleData;
}

export function useModuleFields<T extends CTMStrictRecord = any>(): Module['fields'] {
  const module = useModuleData<T>();
  return module.fields;
}

export function useModuleTemplates<T extends CTMStrictRecord = any>(): Module['templates'] {
  const module = useModuleData<T>();
  return module.templates;
}

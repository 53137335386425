import InvoiceContractorFieldsGroup from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceContractorFieldsGroup';
import InvoiceDateFieldsGroup from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceDateFieldsGroup';
import InvoiceGeneralFieldsGroup from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceGeneralFieldsGroup';
import InvoiceLocationFieldsGroup from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceLocationFieldsGroup';
import InvoicePositionsGroup from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoicePositionsGroup';
import useInvoiceFields, { InvoiceFields } from '@Accountancy/Invoice/Sections/useInvoiceFields';
import { CustomSectionComponent } from '@Components/CustomFields/CustomSection';
import { Col, Container } from '@Components/Theme/Grid';
import classnames from 'classnames';
import { useModuleFields } from 'context/ModuleContext';
import { useState } from 'react';
import { Collapse } from 'reactstrap';

export type InvoiceGroupProps = {
  fields: InvoiceFields;
  values: { [key: string]: any };
  onUpdate: (value: any, field: string) => void;
  readonly: boolean;
};

const InvoiceDefaultSection: CustomSectionComponent = props => {
  const { section, values, onUpdate, readonly, useCollapse } = props;
  const moduleFields = useModuleFields();
  const [expanded, setExpanded] = useState(true);
  const indexedNamedFields = useInvoiceFields(moduleFields);

  return (
    <>
      <div className={classnames({ 'accordion-item cf-tab': useCollapse })} key={section.id}>
        {useCollapse && (
          <h2 className="accordion-header cf-tab" id={section.id}>
            <div
              className={classnames('accordion-button', { collapsed: !expanded })}
              onClick={() => setExpanded(!expanded)}
              style={{ cursor: 'pointer' }}
            >
              {section.name || 'Nazwa sekcji'}
            </div>
          </h2>
        )}
        <Collapse isOpen={expanded} className="accordion-collapse custom-field-section-collapse">
          <div
            className={classnames('custom-section', { 'accordion-body': useCollapse })}
            style={{ background: '#f5f5f9', padding: '30px 12px' }}
          >
            <Container>
              <Col xs={12} md={6}>
                <InvoiceGeneralFieldsGroup fields={indexedNamedFields} values={values} onUpdate={onUpdate} readonly={readonly} />
              </Col>
              <Col xs={12} md={6}>
                <InvoiceDateFieldsGroup fields={indexedNamedFields} values={values} onUpdate={onUpdate} readonly={readonly} />
                <InvoiceLocationFieldsGroup fields={indexedNamedFields} values={values} onUpdate={onUpdate} readonly={readonly} />
              </Col>
              <Col xs={12} md={6}>
                <InvoiceContractorFieldsGroup
                  fieldPrefix="seller"
                  fields={indexedNamedFields}
                  values={values}
                  onUpdate={onUpdate}
                  readonly={readonly}
                />
              </Col>
              <Col xs={12} md={6}>
                <InvoiceContractorFieldsGroup
                  fieldPrefix="buyer"
                  fields={indexedNamedFields}
                  values={values}
                  onUpdate={onUpdate}
                  readonly={readonly}
                />
              </Col>
              <Col xs={12}>
                <InvoicePositionsGroup fields={indexedNamedFields} values={values} onUpdate={onUpdate} readonly={readonly} />
              </Col>
            </Container>
          </div>
        </Collapse>
      </div>
    </>
  );
};

export default InvoiceDefaultSection;

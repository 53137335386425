import * as CoreApi from 'helpers/Api/CoreHelper';
import { call, put, takeEvery } from 'redux-saga/effects';
import { FETCH_APP_CONFIGURATION } from 'store/AppConfiguration/Configuration/ActionTypes';
import { fetchAppConfigurationFailed, fetchAppConfigurationSuccess } from 'store/AppConfiguration/Configuration/Actions';

function* getAppConfiguration() {
  try {
    const response = yield call(context => CoreApi.getAppConfiguration(), {});
    yield put(fetchAppConfigurationSuccess(response ?? {}));
  } catch (error) {
    yield put(fetchAppConfigurationFailed());
  }
}

function* configurationSaga() {
  yield takeEvery(FETCH_APP_CONFIGURATION, getAppConfiguration);
}

export default configurationSaga;

import CustomSection from '@Components/CustomFields/CustomSection';
import Loader from '@Components/Theme/Common/Loader';
import { ModuleProvider } from 'context/ModuleContext';
import { useModuleContext } from 'context/ModulesContext';
import { useQuery } from 'react-query';

const InheredSection = ({ section, values }) => {
  const inheredFieldId = section.inheredFromField?.id ?? '';
  const value = values[inheredFieldId] ?? null;
  const valueId = value?.id ?? value ?? null;
  const targetModuleClass = section.inheredFromField?.config?.targetModule ?? null;

  const targetModule = useModuleContext(targetModuleClass ?? '', 'dataClass', false);

  const { data: recordData, isLoading } = useQuery([targetModuleClass, valueId], () => targetModule?.api.get({ id: valueId }), {
    enabled: !!valueId || !targetModule,
  });

  if (isLoading || !targetModule) {
    return <Loader />;
  }
  return (
    <div>
      <ModuleProvider value={targetModule}>
        <CustomSection
          section={{ ...section.inheredSection, name: section.name }}
          key={section.id}
          values={recordData?.['@formValues'] ?? {}}
          components={targetModule?.configuration?.form?.fieldComponents ?? {}}
          useCollapse={true}
          useGridLayout={section.inheredSection.useGridLayout}
          onUpdate={console.log}
          readonly={true}
          recordId={valueId}
          forceReadonlyField={undefined}
        />
      </ModuleProvider>
    </div>
  );
};

export default InheredSection;

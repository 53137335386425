import { useModuleContext } from '../../../context/ModulesContext';
import { TemplateModule } from '../../CTMModules';
import { Field } from '../Field';
import ImageUpload from '@Components/Form/ImageUpload';
import { RemoveCircle } from '@mui/icons-material';
import { Button, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material';
import CustomField from 'components/CustomFields/CustomField';
import ListPicker from 'components/Form/ListPicker';
import TextInput from 'components/Form/MUI/TextInput';
import { Col, Container } from 'components/Theme/Grid';
import { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';

function DefaultValueRow(props) {
  const { defaultValue, fields, onRemove, moduleId } = props;
  const moduleContext = useModuleContext(moduleId, 'id');
  const resolveFieldConfiguration = fieldId => fields.find(v => v['@id'] === fieldId);

  const handleChangeValue = val => {
    const newVal = !val ? val : val.hasOwnProperty('@id') ? val['@id'] : val;

    props.updateDefaultValue({ ...defaultValue, value: newVal });
  };

  return (
    <TableRow>
      <TableCell>
        <Button size={'small'} startIcon={<RemoveCircle style={{ color: '#b00020' }} />} onClick={() => onRemove(defaultValue)} />
      </TableCell>
      <TableCell>{defaultValue.field?.name}</TableCell>
      <TableCell>
        {resolveFieldConfiguration(defaultValue.field?.['@id']) && (
          <CustomField
            value={defaultValue.value}
            disableHideRules={true}
            field={resolveFieldConfiguration(defaultValue.field?.['@id'])}
            onUpdate={handleChangeValue}
            customProps={{ disableGroupMargin: true }}
            fields={[]}
            values={{}}
            readonly={false}
            components={moduleContext?.configuration?.form?.fieldComponents ?? {}}
            size={12}
            recordId={''}
          />
        )}
      </TableCell>
    </TableRow>
  );
}

function ConfigureTemplateModalBody(props) {
  const { templateId, fields, moduleId } = props;
  const [name, setName] = useState<string>('');
  const [image, setImage] = useState<string | null>(null);
  const [defaultValues, setDefaultValues] = useState<any[]>([]);
  const [selectedField, setSelectedField] = useState<null | Field>(null);

  useEffect(() => {
    TemplateModule.api.get({ id: templateId }).then(res => {
      setName(res.name);
      setImage(res.image);
      setDefaultValues(res.defaultValues ?? []);
    });
  }, [templateId]);

  const handleAddNewDefaultValue = () => {
    setDefaultValues([...defaultValues, { field: fields.find(el => el['@id'] === selectedField), value: null }]);
    setSelectedField(null);
  };
  const updateDefaultValue = newVal => {
    setDefaultValues(prev =>
      prev.map(el => {
        if (el.field?.['@id'] === newVal.field?.['@id']) {
          el = { ...el, ...newVal };
        }
        return el;
      }),
    );
  };
  const onRemove = removedVal => {
    setDefaultValues(prev => prev.filter(el => el.field?.['@id'] !== removedVal.field?.['@id']));
  };
  const allowedFields = (): Field[] => {
    const usedIds = defaultValues.map(el => el.field.id);

    return fields.filter(el => !usedIds.includes(el.id));
  };

  return (
    <Modal isOpen={true} centered={true} backdrop={true} toggle={() => false} size="lg">
      <ModalBody>
        <Container>
          <Col xs={12}>
            <h4 style={{ textAlign: 'center' }}>Konfiguracja szablonu</h4>
          </Col>
          <Col xs={12}>
            <TextInput label={'Nazwa szablonu'} value={name} onChange={val => setName(val ?? '')} placeholder="Nazwa szablonu" />
          </Col>
          <Col xs={12} style={{ height: 200 }}>
            <ImageUpload value={image} onChange={val => setImage(val ?? '')} fullHeight={true} fullInputHeight={true} label={'Ikona'} />
          </Col>

          <Col xs={12} display="flex" justifyContent="space-between">
            <div style={{ textAlign: 'center', width: '100%' }}>
              <h4>Domyślne wartości szablonu</h4>
            </div>
          </Col>
          <Col xs={12} display="flex" justifyContent="space-between">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Pole</TableCell>
                  <TableCell>Wartość</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {defaultValues.map((el, index) => (
                  <DefaultValueRow
                    key={index}
                    defaultValue={el}
                    fields={fields}
                    moduleId={moduleId}
                    updateDefaultValue={updateDefaultValue}
                    onRemove={onRemove}
                  />
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={2}>
                    <ListPicker<Field>
                      value={selectedField}
                      className={'_'}
                      options={allowedFields()}
                      optionTrackBy="@id"
                      optionLabel={fieldCfg => `${(fieldCfg.name ?? '') === '' ? 'Nazwa pola' : fieldCfg.name}`}
                      onChange={val => setSelectedField(val)}
                      name={''}
                    />
                  </TableCell>
                  <TableCell>
                    <Button variant="contained" color="primary" disabled={selectedField === null} onClick={handleAddNewDefaultValue}>
                      Dodaj nową domyślną wartość
                    </Button>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Col>

          <Col xs={12} display="flex" justifyContent="space-between">
            <Button variant="contained" color="info" onClick={() => props.setOpened(false)}>
              Anuluj
            </Button>
            <Button variant="contained" color="success" onClick={() => props.handleSave(name, image, defaultValues)}>
              Zapisz
            </Button>
          </Col>
        </Container>
      </ModalBody>
    </Modal>
  );
}

export function ConfigureTemplateModal(props) {
  const { children, templateId, fields, afterSaved, moduleId } = props;
  const [opened, setOpened] = useState<boolean>(false);

  const handleSave = (name, image, defaultValues) => {
    TemplateModule.api
      .put(
        {
          name,
          image,
          defaultValues: defaultValues.map(el => ({ ...el, field: el.field['@id'], value: el.value ? el.value + '' : null })),
        },
        { id: templateId },
      )
      .then(res => {
        afterSaved(res);
        setOpened(false);
      });
  };

  return (
    <>
      <span onClick={() => setOpened(true)}>{children}</span>
      {opened && (
        <ConfigureTemplateModalBody
          setOpened={setOpened}
          handleSave={handleSave}
          templateId={templateId}
          fields={fields}
          moduleId={moduleId}
        />
      )}
    </>
  );
}

export default ConfigureTemplateModal;

import { useModuleConfig } from '../../context/ModuleContext';
import { CoreModuleModule, TemplateModule } from '../CTMModules';
import ModuleTemplatesTemplate from './ConfigurationComponents/ModuleTemplatesTemplate';
import { Module } from './CoreModule';
import FieldsSection from './ModalComponents/FieldsSection';
import HeaderActions from './ModalComponents/HeaderActions';
import TemplateSelector from './ModalComponents/TemplateSelector';
import { Template } from './Template';
import { CircularProgress, Paper } from '@mui/material';
import { confirmRemove } from 'common/sweetalerts';
import { Col, Container } from 'components/Theme/Grid';
import { get } from 'helpers/Axios';
import { HTMLAttributes, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { addSingleToast } from 'store/Toast/actions';

interface ModuleTemplatesBuilderProps {
  children: JSX.Element;
  initialOpened?: boolean;
  templateId?: string;
  wrapperProps?: HTMLAttributes<any>;
  afterSave: () => void;
}

interface ModuleTemplatesBuilderModalProps {
  opened: boolean;
  moduleId: string;
  templateId?: string;
  onModalToggle: () => void;

  afterSave: () => void;
}

function ModuleTemplatesBuilderModal(props: ModuleTemplatesBuilderModalProps): JSX.Element {
  const { opened, onModalToggle, moduleId, templateId, afterSave } = props;
  const [moduleData, setModuleData] = useState<null | Module>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<null | string>(templateId ?? null);
  const templateContainer = useRef<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (opened) {
      fetchConfig();
    } else {
      setModuleData(null);
      setTemplates([]);
    }
  }, [opened]);

  const fetchModule = () => {
    CoreModuleModule.api.get({ id: moduleId }).then(res => setModuleData(res as Module));
  };
  const fetchTemplates = (changeTemplate = true) => {
    get(CoreModuleModule.configuration.api.item.getTemplates({ id: moduleId })).then(res => {
      setTemplates(res.templates);
      if (changeTemplate) {
        setSelectedTemplate(templateId ?? res.templates?.[0]?.id);
      } else {
        templateContainer.current?.resetInit();
      }
    });
  };
  const fetchConfig = (changeTemplate = true) => {
    fetchModule();
    fetchTemplates(changeTemplate);
  };

  const handleChangeTemplate = newTemplate => {
    if (templateContainer.current?.hasUnsavedChanges()) {
      confirmRemove(
        () => {
          return new Promise<boolean>(res => {
            setSelectedTemplate(newTemplate);
            res(true);
          });
        },
        'Masz niezapisane zmiany w szablonie',
        'Czy jesteś pewien że chcesz porzucić konfigurację szablonu bez zapisywania zmian?',
        false,
      );
    } else {
      setSelectedTemplate(newTemplate);
    }
  };
  const handleRemoveTemplate = () => {
    const currentTemplateData = templateContainer.current?.getTemplate();

    if (currentTemplateData.system) {
      dispatch(
        addSingleToast({
          title: `Szablonu systemowego nie można skasować.`,
          config: { appearance: 'error' },
        }),
      );
    } else {
      confirmRemove(
        () => {
          return new Promise<boolean>(res => {
            const removeId = currentTemplateData.id;
            const newTemplates = templates.filter(el => el.id !== removeId);
            setTemplates(newTemplates);
            setSelectedTemplate(newTemplates[0].id ?? '');
            TemplateModule.api.delete({ id: removeId }).then(() => {
              res(true);
            });
          });
        },
        `Czy jesteś pewien że chcesz skasować szablon "${currentTemplateData.name}"`,
        'Tej akcji nie można cofnąć?',
        true,
      );
    }
  };
  const onToggle = () => {
    if (templateContainer.current?.hasUnsavedChanges()) {
      confirmRemove(
        () => {
          return new Promise<boolean>(res => {
            onModalToggle();
            res(true);
          });
        },
        'Masz niezapisane zmiany w szablonie',
        'Czy jesteś pewien że chcesz porzucić konfigurację szablonu bez zapisywania zmian?',
        false,
      );
    } else {
      onModalToggle();
    }
  };
  const onSave = close => {
    setLoading(true);
    templateContainer.current
      ?.save()
      .then(() => {
        if (close) {
          onModalToggle();
        } else {
          fetchConfig(false);
        }
        afterSave();
      })
      .finally(() => setLoading(false));
  };

  const onNewTemplate = newTemplate => {
    setTemplates(prev => [...prev, newTemplate]);
    setSelectedTemplate(newTemplate.id);
  };

  return (
    <Modal isOpen={opened} centered={true} fullscreen={true} backdrop={true} toggle={onToggle}>
      <ModalBody style={{ background: '#2a3042' }}>
        {moduleData === null && (
          <div style={{ textAlign: 'center', padding: 12 }}>
            <CircularProgress size={64} />
          </div>
        )}
        {moduleData !== null && (
          <>
            <Container>
              <Col style={{ width: 250 }}>
                <TemplateSelector
                  loading={loading}
                  templates={templates}
                  selectedTemplate={selectedTemplate}
                  handleChangeTemplate={handleChangeTemplate}
                  style={{ padding: '2px 0', background: '#192031', color: '#fff' }}
                />
              </Col>
              <Col style={{ width: 'calc(100vw - 281px)' }}>
                <HeaderActions
                  loading={loading}
                  onSave={onSave}
                  templates={templates}
                  onRemove={handleRemoveTemplate}
                  onModalToggle={onToggle}
                  onNewTemplate={onNewTemplate}
                  templateId={selectedTemplate}
                  templateUpdated={() => {
                    fetchConfig(false);
                    templateContainer.current.reload();
                  }}
                  fields={moduleData.fields ?? []}
                  moduleId={moduleData.id}
                />
              </Col>
            </Container>
            <Container style={{ marginTop: 6 }}>
              <Col style={{ width: 250 }}>
                <FieldsSection moduleData={moduleData} onFieldSaved={() => fetchModule()} />
              </Col>
              <Col
                style={{
                  maxHeight: 'calc(100vh - 110px)',
                  overflowY: 'auto',
                  position: 'relative',
                  paddingTop: 0,
                  marginTop: 12,
                  width: 'calc(100vw - 281px)',
                }}
                className="dark-scroll"
              >
                <Paper variant="outlined" style={{ padding: 0, minHeight: 'calc(100vh - 110px)', border: 'none' }}>
                  <ModuleTemplatesTemplate
                    ref={templateContainer}
                    moduleId={moduleId}
                    templateId={selectedTemplate}
                    fields={moduleData.fields ?? []}
                    onFieldSaved={() => fetchModule()}
                  />
                </Paper>
              </Col>
            </Container>
          </>
        )}
      </ModalBody>
    </Modal>
  );
}

export type ModuleTemplatesBuilderHandle = {
  open: () => void;
};

function ModuleTemplatesBuilder(props: ModuleTemplatesBuilderProps, ref): JSX.Element {
  const { children, initialOpened = false, templateId, afterSave } = props;
  const moduleConfiguration = useModuleConfig();
  const [opened, setOpened] = useState<boolean>(initialOpened);

  useImperativeHandle(ref, () => ({
    open() {
      setOpened(true);
    },
  }));
  return (
    <>
      <ModuleTemplatesBuilderModal
        opened={opened}
        afterSave={afterSave}
        onModalToggle={() => setOpened(!opened)}
        moduleId={moduleConfiguration.id}
        templateId={templateId}
      />
      {children}
    </>
  );
}

export default forwardRef<ModuleTemplatesBuilderHandle, ModuleTemplatesBuilderProps>(ModuleTemplatesBuilder);

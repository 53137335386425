import { Department } from '@Accountancy/Department/Department';
import { useModuleContext } from 'context/ModulesContext';
import { useQuery, useQueryClient } from 'react-query';

export const queryKey = 'allDepartments';

export const useFetchDepartment = (id: string) => {
  const DepartmentsModule = useModuleContext<Department, true, Department>('accountancy-department', null, true);
  return useQuery([queryKey, id], () => DepartmentsModule.api.get(id), {
    cacheTime: 10 * 60 * 1000,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: true,
    refetchInterval: false,
  });
};

export const useFetchDepartments = () => {
  const DepartmentsModule = useModuleContext<Department, true, Department>('accountancy-department', null, true);
  return useQuery(queryKey, () => DepartmentsModule.api.getAll({ params: { page: 1, limit: 1000 } }).then(res => res['hydra:member']), {
    cacheTime: 10 * 60 * 1000,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: true,
    refetchInterval: false,
  });
};

export const useInvalidateDepartments = () => {
  const queryClient = useQueryClient();
  return queryClient.invalidateQueries(queryKey);
};

export default { queryKey, useDepartments: useFetchDepartments, useInvalidateDepartments };

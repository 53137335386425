import { Task } from '../../Types/Task';
import { EmployeeModule } from '@Employee/Employee';
import { Employee } from '@Employee/Employee/Types/Employee';
import { CircularProgress } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { TaskModule } from 'Modules/CTMModules';
import ModuleListPicker from 'components/Module/ModuleListPicker';
import { Col, Container } from 'components/Theme/Grid';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addSingleToast } from 'store/Toast/actions';

interface UserPickerProps {
  task: Task;
}

const UserPicker: FC<UserPickerProps> = ({ task }) => {
  const [assignedEmployee, setAssignedEmployee] = useState<null | Employee<string>>(task.assignedEmployee);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const handleChange = newVal => {
    setLoading(true);
    TaskModule.api
      .put({ assignedEmployee: newVal?.['@id'] ?? null }, { id: task.id })
      .then(() => {
        setAssignedEmployee(newVal);
        setLoading(false);
        dispatch(addSingleToast({ title: `Zapisano zmiany`, config: { appearance: 'success' } }));
      })
      .catch(err => {
        setLoading(false);
        dispatch(
          addSingleToast({
            title: err?.response?.['hydra:description'] ?? 'Wystąpił błąd z zapisem',
            config: { appearance: 'error' },
          }),
        );
      });
  };
  if (loading) {
    return (
      <Button style={{ padding: 0 }}>
        <Container alignItems={'center'} spacing={0}>
          <Avatar>
            <CircularProgress />
          </Avatar>
        </Container>
      </Button>
    );
  }

  return (
    <div style={{ textAlign: 'left', minWidth: 140 }}>
      <ModuleListPicker
        moduleName={EmployeeModule.configuration.urlPrefix}
        onChange={handleChange}
        overrideUrl={undefined}
        moduleFormComponent={undefined}
        moduleFormComponentProps={undefined}
        moduleFormWrapperProps={undefined}
        allowMultipleSelect={false}
        onMultipleSelect={console.error}
        defaultFilters={undefined}
      >
        <Button style={{ padding: 0 }}>
          {assignedEmployee && (
            <Container alignItems={'center'} spacing={0}>
              <Col>
                <Avatar>
                  {assignedEmployee.image && (
                    <>
                      <img
                        src={assignedEmployee.image}
                        style={{ maxWidth: '100%' }}
                        alt={`${assignedEmployee.firstName} ${assignedEmployee.lastName}`}
                      />
                    </>
                  )}
                  {!assignedEmployee.image && (
                    <>
                      {assignedEmployee.firstName?.substring(0, 1)}
                      {assignedEmployee.lastName?.substring(0, 1)}
                    </>
                  )}
                </Avatar>
              </Col>
              <Col spacing={0} style={{ paddingLeft: 12, fontSize: '0.8em' }}>
                <Container spacing={0}>
                  <Col>{assignedEmployee.firstName}</Col>
                </Container>
                <Container spacing={0}>
                  <Col>{assignedEmployee.lastName}</Col>
                </Container>
              </Col>
            </Container>
          )}
          {!assignedEmployee && (
            <Container alignItems={'center'} spacing={0}>
              <Avatar></Avatar>
            </Container>
          )}
        </Button>
      </ModuleListPicker>
    </div>
  );
};

export default UserPicker;

import { CTMApiModulePaths, CTMModule, CTMPath, CTMRecord, CTMStrictRecord } from './Types/CTMModule';
import { AxiosRequestConfig } from 'axios';
import { ApiCollection, ApiItem, del, get, post, put } from 'helpers/Axios';

const resolvePath = (ctmPath: CTMPath | CTMPath<{ id: string | number }>, parameters: any): string => {
  if (typeof ctmPath === 'string') {
    return ctmPath;
  }

  return ctmPath(parameters);
};

class CRUDApi<T extends CTMApiModulePaths, ItemModel = any, ListItemModel = Partial<ItemModel>> {
  private readonly item: T['item'];
  private readonly collection: T['collection'];
  public readonly custom: T['custom'];
  getAllUrl: string;
  postUrl: string;
  constructor(item: T['item'], collection: T['collection'], custom: T['custom']) {
    this.item = item;
    this.collection = collection;
    this.getAllUrl = resolvePath(this.collection.get, {});
    this.postUrl = resolvePath(this.collection.post, {});
    this.custom = custom;
  }

  post(data: CTMRecord, axiosConfig?: AxiosRequestConfig) {
    return post<ItemModel>(this.postUrl, data, axiosConfig);
  }

  getAll(axiosConfig?: AxiosRequestConfig) {
    return get<ApiCollection<ListItemModel & ApiItem>>(this.getAllUrl, axiosConfig);
  }

  get(urlParameters?: any, axiosConfig?: AxiosRequestConfig) {
    return get<ItemModel>(resolvePath(this.item.get, urlParameters), axiosConfig);
  }

  getUrl(urlParameters?: any): string {
    return resolvePath(this.item.get, urlParameters);
  }

  put(data: CTMRecord, urlParameters?: any, axiosConfig?: AxiosRequestConfig) {
    return put<Partial<ItemModel>>(resolvePath(this.item.put, urlParameters), data, axiosConfig);
  }

  delete(urlParameters?: any, axiosConfig?: AxiosRequestConfig) {
    return del(resolvePath(this.item.delete, urlParameters), axiosConfig);
  }
}

class CRUDModule<ItemModel extends CTMStrictRecord = object, ListItemModel = Partial<ItemModel>> {
  readonly configuration: CTMModule<ItemModel>;
  readonly api: CRUDApi<CTMModule<ItemModel>['api'], ItemModel, ListItemModel>;
  public readonly listUrl: string;
  public readonly createUrl: string;
  public readonly showUrl: (id: string | number) => string;
  public readonly editUrl: (id: string | number) => string;

  constructor(moduleConfiguration: CTMModule<ItemModel>) {
    this.configuration = moduleConfiguration;
    this.api = new CRUDApi<CTMModule<ItemModel>['api'], ItemModel, ListItemModel>(
      this.configuration.api.item,
      this.configuration.api.collection,
      this.configuration.api.custom,
    );
    this.listUrl = `/modules/${this.configuration.urlPrefix}/list`;
    this.createUrl = `/modules/${this.configuration.urlPrefix}/add`;
    this.showUrl = (id: string | number) => `/modules/${this.configuration.urlPrefix}/show/${id}`;
    this.editUrl = (id: string | number) => `/modules/${this.configuration.urlPrefix}/edit/${id}`;
  }
}

export default CRUDModule;

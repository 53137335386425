/* eslint-disable */
import { get } from 'helpers/Axios';
import { ActionPattern, call, put, select, takeEvery } from 'redux-saga/effects';
import { RELOAD_TASK, SET_TASK_IRI } from 'store/TaskManagement/ActionTypes';
import { openTask, setLoading, setModalOpened, setTask } from 'store/TaskManagement/Actions';

const selectTaskIRI = state => state.TaskManagement.taskIRI;

function* openTaskWithModal({ payload }) {
  if (payload !== null) {
    yield put(setLoading(true));
    yield put(setModalOpened(true));
    try {
      const response = yield call(() => get(payload));
      yield put(setTask(response ?? null));
      yield put(setLoading(false));
    } catch (error) {
      yield put(openTask(null));
      yield put(setLoading(false));
    }
  } else {
    yield put(openTask(null));
  }
}
function* reloadTask() {
  let taskIRI = yield select(selectTaskIRI);
  const response = yield call(() => get(taskIRI));
  yield put(setTask(response ?? null));
}

function* configurationSaga() {
  yield takeEvery<ActionPattern, any>(SET_TASK_IRI, openTaskWithModal);
  yield takeEvery<ActionPattern, any>(RELOAD_TASK, reloadTask);
}

export default configurationSaga;

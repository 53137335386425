import DepartmentBlock from '@Accountancy/Department/Components/DepartmentBlock';
import IconPicker from '@Components/CustomFields/FieldTypes/IconPicker';
import CTMModule, { CTMListColumn, CTMStrictRecord } from 'Modules/Core/Types/CTMModule';

export type Department = CTMStrictRecord & {
  id: string;
  name: string;
  icon: null | string;
  color: null | string;
};

const columns: CTMListColumn<Department>[] = [
  {
    id: 'name',
    Header: 'Departament',
    accessor: department => <DepartmentBlock department={department} />,
    filterable: true,
    sortable: true,
  },
];

export type DepartmentModule = CTMModule<Department>;

const module: DepartmentModule = {
  id: '39f01266-a388-4f62-9113-6951cf5834b7',
  dataClass: 'CTM\\Accountancy\\Entity\\Department',
  urlPrefix: 'accountancy-department',
  name: 'Departamenty',
  role: 'ACCOUNTANCY_INVOICE',
  api: {
    item: {
      get: ({ id }) => `/accountancy/departments/${id}`,
      put: ({ id }) => `/accountancy/departments/${id}`,
      delete: ({ id }) => `/accountancy/departments/${id}`,
    },
    collection: {
      get: `/accountancy/departments`,
      post: `/accountancy/departments`,
    },
  },
  recordLabel: department => department.name,
  form: {
    fieldComponents: {
      icon: IconPicker,
    },
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;

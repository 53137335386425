import * as CTMModules from '../../CTMModules';
import { ModuleConfigResponse } from './useFetchModuleConfig';
import { put } from 'helpers/Axios';
import { MutationOptions, useMutation, useQueryClient } from 'react-query';
import { useAppDispatch } from 'store';
import { addSingleToast } from 'store/Toast/actions';

function useModuleConfigSave<T>(moduleId: string, options?: MutationOptions<ModuleConfigResponse<T>, Error, T>) {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  return useMutation<ModuleConfigResponse<T>, Error, T>(
    async (updatedConfiguration: T) => {
      const response = await put(CTMModules.CoreModuleModule.configuration.api.item.getConfiguration({ id: moduleId }), {
        configuration: updatedConfiguration,
      });
      return response.data;
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([moduleId, `module-config-${moduleId}`]);
        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        } else {
          dispatch(
            addSingleToast({
              title: 'Zmiany zostały zapisane',
              config: { appearance: 'success' },
            }),
          );
        }
      },
      onError: (error, variables, context) => {
        if (options?.onError) {
          options.onError(error, variables, context);
        } else {
          dispatch(
            addSingleToast({
              title: 'Wystąpił błąd przy zapisie konfiguracji',
              config: { appearance: 'error' },
            }),
          );
        }
      },
    },
  );
}

export default useModuleConfigSave;

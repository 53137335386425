import { baseBuilder } from '../ModuleBuilder';
import SourceView from './Components/SourceView';
import IconPicker from '@Components/CustomFields/FieldTypes/IconPicker';
import CTMModule, { CTMListColumn, CTMStrictRecord } from 'Modules/Core/Types/CTMModule';

export type PaymentTypeModel = CTMStrictRecord & {
  name: string;
  icon: null | string;
  color: null | string;
  relationFieldLabel?: null | string;
};

const columns: CTMListColumn<PaymentTypeModel>[] = [
  {
    id: 'name',
    filterable: true,
    sortable: true,
    Header: 'Nazwa',
    accessor: row => <SourceView source={row} />,
  },
];

const module: CTMModule<PaymentTypeModel> = baseBuilder<PaymentTypeModel>({
  id: '6b8a6a3d-b08b-4da5-80c3-73f49dc03462',
  dataClass: 'CTM\\Ecommerce\\Entity\\PaymentType',
  urlPrefix: 'ecommerce-payment-types',
  name: 'Typy płatności',
  role: 'CONFIGURATION',
  baseUrl: '/ecommerce/payment-types',
  columns: columns,
  list: {
    role: 'ROLE_EMPLOYEE',
  },
  form: {
    fieldComponents: {
      icon: IconPicker,
    },
  },
});

export default module;

import useDepartment from '@Accountancy/Department/Hooks/useDepartment';
import { Col, Container } from '@Components/Theme/Grid';
import { useEffect } from 'react';

function DataGridHeader({ PerPageSelectorElement, AddButtonElement, ConfiguratorElement, allowChangeLimit, allowAdd, setFilter }) {
  const { department } = useDepartment();

  useEffect(() => {
    setFilter('department.id', department !== 'undefined' ? department : undefined);
  }, [department]);

  return (
    <div style={{ padding: 6 }}>
      <Container className="w-100 d-flex justify-content-between align-items-center" columnSpacing={0} spacing={0}>
        {allowChangeLimit && (
          <Col className="w-100" style={{ maxWidth: 140 }}>
            <PerPageSelectorElement />
          </Col>
        )}
        <Col className="w-100 d-flex" alignItems="flex-end" justifyContent="center" style={{ maxWidth: 200, textAlign: 'right' }}>
          {allowAdd && <AddButtonElement />}
          <ConfiguratorElement />
        </Col>
      </Container>
    </div>
  );
}

export default DataGridHeader;

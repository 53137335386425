import { ReactNode } from 'react';
import { Options } from 'react-toast-notifications';
import { ADD_TOAST, REMOVE_TOAST_FROM_QUEUE } from 'store/Toast/actionTypes';
import { v4 as uuidv4 } from 'uuid';

export type ToastType = {
  title: ReactNode;
  config?: Options;
};

export type ToastState = {
  queue: Array<ToastType & { __id: string }>;
};
const initialState: ToastState = {
  queue: [],
};

const Toast = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TOAST: {
      state = {
        ...state,
        queue: [...state.queue, ...action.payload.map(el => ({ ...el, __id: uuidv4() }))],
      };
      break;
    }
    case REMOVE_TOAST_FROM_QUEUE:
      state = {
        ...state,
        queue: state.queue.filter(el => el.__id !== action.payload),
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Toast;

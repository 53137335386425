import { useAppDispatch } from '../../../../store';
import { addSingleToast } from '../../../../store/Toast/actions';
import { CustomFieldComponentType, IndexedField } from '@Components/CustomFields/CustomField';
import ListPicker from '@Components/Form/ListPicker';
import TextInput from '@Components/Form/MUI/TextInput';
import ReservationPicker from '@Ecommerce/Components/ReservationPicker';
import StatusPicker from '@Ecommerce/Components/StatusPicker';
import { BaseLinkerIntegrationStatusMapping, BaseLinkerIntegrationWebhook, CoreBaseLinkerStatus } from '@Integration/BaseLinkerIntegration';
import { QuestionMarkRounded } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Alert, Button, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { FC, useEffect, useMemo, useState } from 'react';
import Swal from 'sweetalert2';

const API_URL = process.env.REACT_APP_API_BASE_URL;

interface WebhookRowProps {
  field: IndexedField;
  webhook: BaseLinkerIntegrationWebhook;
  readonly: boolean;
  baseLinkerStatuses: CoreBaseLinkerStatus[];
  onChange: (changedWebhook: BaseLinkerIntegrationWebhook) => void;
  onRemove: () => void;
}

const WebhookRow: FC<WebhookRowProps> = props => {
  const dispatch = useAppDispatch();
  const confirmRemove = () => {
    Swal.fire({
      title: 'Czy na pewno chcesz usunąć ten webhook?',
      showCancelButton: true,
      confirmButtonText: 'Tak',
      cancelButtonText: 'Nie',
    }).then(result => {
      if (result.isConfirmed) {
        props.onRemove();
      }
    });
  };

  const getUrl = () => {
    if (props.webhook.id) {
      return `${API_URL}/core/integration/base-linker-integration-webhooks/call/${props.webhook.id}?externalOrderId=[numer_zamowienia]`;
    }
    return '';
  };
  const copyToClipboard = () => {
    navigator.clipboard.writeText(getUrl());
    dispatch(
      addSingleToast({
        title: `Skopiowano wartość "${getUrl()}" do schowka .`,
        config: { appearance: 'success' },
      }),
    );
  };

  return (
    <TableRow hover={true}>
      <TableCell>
        <ListPicker<CoreBaseLinkerStatus>
          options={props.baseLinkerStatuses}
          name="baseLinkerStatuses"
          value={props.webhook.baseLinkerStatusAfterSuccess}
          optionTrackBy="id"
          optionLabel={(option: CoreBaseLinkerStatus) => (
            <span
              style={{
                textAlign: 'center',
                backgroundColor: option.color ?? '#fff',
                textShadow: '0px 0px 10px white',
                width: '100%',
                display: 'block',
                padding: '10px 8px',
                borderRadius: 10,
              }}
            >
              {option.name}
            </span>
          )}
          onChange={val =>
            props.onChange({
              ...props.webhook,
              baseLinkerStatusAfterSuccess: typeof val === 'number' ? val : null,
            })
          }
        />
      </TableCell>
      <TableCell>
        <ListPicker<CoreBaseLinkerStatus>
          options={props.baseLinkerStatuses}
          name="baseLinkerStatuses"
          value={props.webhook.baseLinkerStatusAfterFailure}
          optionTrackBy="id"
          optionLabel={(option: CoreBaseLinkerStatus) => (
            <span
              style={{
                textAlign: 'center',
                backgroundColor: option.color ?? '#fff',
                textShadow: '0px 0px 10px white',
                width: '100%',
                display: 'block',
                padding: '10px 8px',
                borderRadius: 10,
              }}
            >
              {option.name}
            </span>
          )}
          onChange={val => {
            props.onChange({
              ...props.webhook,
              baseLinkerStatusAfterFailure: typeof val === 'number' ? val : null,
            });
          }}
        />
      </TableCell>
      <TableCell>
        <StatusPicker
          showLabel={false}
          field={props.field}
          fields={[]}
          value={props.webhook.ecmStatus}
          onChange={val =>
            props.onChange({
              ...props.webhook,
              ecmStatus: typeof val === 'number' ? val : null,
            })
          }
          readonly={false}
          errorKey={'_'}
        />
      </TableCell>
      <TableCell>
        <ReservationPicker
          showLabel={false}
          field={props.field}
          fields={[]}
          value={props.webhook.reservationStatus}
          onChange={val =>
            props.onChange({
              ...props.webhook,
              reservationStatus: typeof val === 'number' ? val : null,
            })
          }
          readonly={false}
          errorKey={'_'}
        />
      </TableCell>
      <TableCell>
        <Checkbox
          checked={props.webhook.generateAssembling}
          onChange={e => props.onChange({ ...props.webhook, generateAssembling: e.target.checked })}
        />
      </TableCell>
      <TableCell>
        {props.webhook.id && (
          <TextInput
            value={getUrl()}
            disabled={true}
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    onClick={copyToClipboard}
                    className="btn-sm"
                    style={{
                      background: 'transparent',
                      borderColor: '#dedede',
                    }}
                  >
                    <Tooltip title={'Kopiuj do schowka'}>
                      <i className="mdi mdi-content-copy text-success" style={{ padding: '0 4px' }} />
                    </Tooltip>
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        )}
        {!props.webhook.id && <Alert color="warning">Adres url zostanie wyświetlony po zpaisaniu zmian</Alert>}
      </TableCell>
    </TableRow>
  );
};

const Webhooks: CustomFieldComponentType<true, BaseLinkerIntegrationWebhook[]> = ({ field, value, values, fields, onChange, readonly }) => {
  const statusMappingsFieldId = fields.find(field => field.propertyPath === 'statusMappings')?.id ?? '';
  const baseLinkerStatuses = useMemo<CoreBaseLinkerStatus[]>(() => {
    return (Object.values(values?.[statusMappingsFieldId] ?? []) as BaseLinkerIntegrationStatusMapping[])
      .filter(el => null !== el.baseLinkerStatusData)
      .map(el => el.baseLinkerStatusData) as CoreBaseLinkerStatus[];
  }, [statusMappingsFieldId, values?.[statusMappingsFieldId] ?? []]);
  const [webhooks, setWebhooks] = useState<BaseLinkerIntegrationWebhook[]>(Object.values(value ?? []));

  useEffect(() => {
    if (JSON.stringify(webhooks) !== JSON.stringify(Object.values(value ?? []))) {
      onChange(webhooks, field.id);
    }
  }, [JSON.stringify(webhooks)]);
  useEffect(() => {
    if (JSON.stringify(webhooks) !== JSON.stringify(Object.values(value ?? []))) {
      setWebhooks(value);
    }
  }, [JSON.stringify((value ?? []).map(el => el.id).join(''))]);

  const onRemove = (index: number) => {
    // setBaseLinkerFields(prevState => prevState.filter(field => field !== baseLinkerField));
    // setMappings(prevState => prevState.filter(mapping => mapping.baseLinkerPropertyPath !== baseLinkerField));
  };

  const handleChangeRow = (changedWebhook: BaseLinkerIntegrationWebhook, i: number) => {
    setWebhooks(prevValue => {
      return prevValue.map((item, index) => {
        if (index === i) {
          return changedWebhook;
        }
        return item;
      });
    });
  };

  const addNewWebhook = () => {
    setWebhooks(prevValue => {
      return [
        ...prevValue,
        {
          baseLinkerStatusAfterSuccess: null,
          baseLinkerStatusAfterFailure: null,
          ecmStatus: null,
          reservationStatus: null,
          generateAssembling: false,
        },
      ];
    });
  };

  return (
    <TableContainer sx={{ maxHeight: '600px', maxWidth: '100%' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Status BL po udanej operacji</TableCell>
            <TableCell>Status BL po błędzie</TableCell>
            <TableCell>Status ECM Po akcji</TableCell>
            <TableCell>Status rezerwacji</TableCell>
            <TableCell>Generuj zlecenie kompletacji</TableCell>
            <TableCell>
              <Tooltip title={'Skopiuj zawartość tego pola i wklej ją do akcji "wywołaj URL" w BaseLinker'} placement="top">
                <div>
                  <span>URL</span>
                  <QuestionMarkRounded />
                </div>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {webhooks.map((webhook, i) => (
            <WebhookRow
              key={i}
              field={field}
              webhook={webhook}
              readonly={readonly}
              onChange={changes => handleChangeRow(changes, i)}
              baseLinkerStatuses={baseLinkerStatuses}
              onRemove={() => onRemove(i)}
            />
          ))}
          <TableRow>
            <TableCell colSpan={2}>
              <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={() => addNewWebhook()}>
                Dodaj nowy webhook
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

Webhooks.provideAllValues = true;

export default Webhooks;

import { useAppDispatch, useAppSelector } from 'store';
import { changeDepartmentIri } from 'store/Accountancy/Actions';

function useDepartment() {
  const dispatch = useAppDispatch();

  const department = useAppSelector(state => state.Accountancy.departmentIRI);
  const changeDepartment = async (iri: string | undefined) => {
    dispatch(changeDepartmentIri(iri));
  };

  return { department, changeDepartment };
}

export default useDepartment;

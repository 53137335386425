import CTMModule, { CTMStrictRecord } from 'Modules/Core/Types/CTMModule';
import { pickValueFromRecordByPropertyPath } from 'helpers/ModuleUtils';

export type SectionLayout = {
  i: string;
  x: number;
  y: number;
  h: number;
  w: number;
  minH: number;
  minW: number;
};

export type Section = {
  ['@id']: string;
  id: string;
  name: null | string;
  position: number;
  system: boolean;
  useGridLayout: boolean;
  hideConditions: any[];
  hideRules: any[];
  layout: SectionLayout[];
};

export type Tab = {
  ['@id']: string;
  id: string;
  config: any;
  hideConditions: any[];
  hideRules: any[];
  name: null | string;
  position: number;
  recordId: null;
  sections: Section[];
  system: boolean;
  template?: null | string;
};

export type Template = CTMStrictRecord & {
  ['@id']?: string;
  id?: string;
  name: string;
  system: boolean;
  defaultValues: any[];
  image: any;
  tabs: Tab[];
};

interface CustomTemplateItemPaths {
  getRecord: ({ id }: { id: string }) => string;
}

const module: CTMModule<Template, CustomTemplateItemPaths> = {
  id: 'TemplateModule',
  dataClass: 'CTM\\CustomField\\Entity\\Template',
  urlPrefix: 'core-template',
  name: 'Moduły',
  role: null,
  api: {
    item: {
      get: ({ id }) => `/custom-fields/templates/${id}`,
      getRecord: ({ id }) => `/custom-fields/templates/${id}/record`,
      put: ({ id }) => `/custom-fields/templates/${id}`,
      delete: ({ id }) => `/custom-fields/templates/${id}`,
    },
    collection: {
      get: `/custom-fields/templates`,
      post: `/custom-fields/templates`,
    },
  },
  recordLabel: (record, allFields) => {
    const { name } = pickValueFromRecordByPropertyPath<Template, any>(record, ['name'], allFields);
    return name ? `${name ?? ''}` : null;
  },
};

export default module;

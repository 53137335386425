import { Invoice, InvoiceConfiguration } from '@Accountancy/Invoice/Invoice';
import ModuleListPicker from '@Components/Module/ModuleListPicker';
import { Col, Container } from '@Components/Theme/Grid';
import { Contractor } from '@Contractor/Contractor';
import useModuleConfigSave from '@Core/Hooks/useModuleConfigSave';
import Address from '@Core/Types/Address';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useModule } from 'context/ModuleContext';
import { ChangeEvent, Dispatch, FC, SetStateAction, memo, useCallback, useState } from 'react';

interface ConfigurationFormProps {
  configuration: ControlledConfiguration;
  setConfiguration: Dispatch<SetStateAction<ControlledConfiguration>>;
}

interface AddressFieldProps {
  size: number;
  fieldKey: string;
  label: string;
  configuration: ControlledConfiguration;
  setConfiguration: (props: (prevConfig: ControlledConfiguration) => ControlledConfiguration) => any;
}
const AddressField: FC<AddressFieldProps> = props => {
  const handleChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      props.setConfiguration((prevConfig: ControlledConfiguration) => {
        prevConfig.issueAddress[props.fieldKey] = ev.target.value ?? null;
        return { ...prevConfig };
      });
    },
    [props.fieldKey, props.setConfiguration],
  );

  return (
    <Col xs={12} sm={props.size}>
      <TextField label={props.label} variant="outlined" value={props.configuration.issueAddress[props.fieldKey]} onChange={handleChange} />
    </Col>
  );
};

function ConfigurationForm(props: ConfigurationFormProps) {
  const { configuration, setConfiguration } = props;
  return (
    <Container>
      <Col xs={12} md={6}>
        <Box>
          <Typography variant="h6" textAlign="center">
            Domyślne ustawienia faktury
          </Typography>
          <Container>
            <Col xs={12}>
              <ModuleListPicker<Contractor>
                readerValue={configuration.innerContractor}
                label="Domyślny kontrachent"
                moduleName={'contractor-contractor'}
                onChange={row => setConfiguration({ ...configuration, innerContractor: row?.['@id'] })}
              />
            </Col>
          </Container>
        </Box>
      </Col>
      <Col xs={12} md={6}>
        <Box>
          <Typography variant="h6" textAlign="center">
            Adres wystawienia
          </Typography>
          <Container>
            <AddressField {...props} size={3} fieldKey="post" label="Kod pocztowy" />
            <AddressField {...props} size={9} fieldKey="city" label="Miasto" />
            <AddressField {...props} size={4} fieldKey="country" label="Kraj" />
            <AddressField {...props} size={4} fieldKey="province" label="Województwo" />
            <AddressField {...props} size={4} fieldKey="district" label="Gmina" />
            <AddressField {...props} size={6} fieldKey="street" label="Ulica" />
            <AddressField {...props} size={3} fieldKey="house" label="Budynek" />
            <AddressField {...props} size={3} fieldKey="flat" label="Lokal" />
          </Container>
        </Box>
      </Col>
    </Container>
  );
}

const MemoConfigurationForm = memo(ConfigurationForm);

type ControlledConfiguration = {
  innerContractor: null | string;
  issueAddress: Address;
};

function InvoiceDefaultsConfiguration() {
  const { module, moduleConfig } = useModule<Invoice, InvoiceConfiguration>();
  const { isLoading, mutate: updateModule } = useModuleConfigSave<InvoiceConfiguration>(module.configuration.id);
  const [configuration, setConfiguration] = useState<ControlledConfiguration>({
    innerContractor: moduleConfig.InvoiceDefaults.innerContractor?.['@id'] ?? moduleConfig.InvoiceDefaults.innerContractor,
    issueAddress: moduleConfig.InvoiceDefaults.issueAddress,
  });
  const onSave = () => {
    updateModule({ ...moduleConfig, InvoiceDefaults: configuration });
  };

  return (
    <>
      <Box>
        <MemoConfigurationForm configuration={configuration} setConfiguration={setConfiguration} />
      </Box>

      <Box sx={{ flexGrow: 1 }} justifyContent="end" display="flex" flex="1 100%" padding={2}>
        <Button variant="contained" color="success" onClick={onSave} disabled={isLoading}>
          Zapisz
        </Button>
      </Box>
    </>
  );
}

export default InvoiceDefaultsConfiguration;

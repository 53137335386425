import Products from './Components/Contractor/Products';
import Contractor from './Contractor';
import Employee from '@Employee/Employee/Employee';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import dayjs from 'dayjs';
import { createModuleColumnConfig } from 'helpers/ModuleUtils';

export type Inquiry = CTMRecord & {
  title?: string;
  name?: string;
};

const columns: CTMListColumn<Inquiry>[] = [
  {
    id: 'title',
    Header: 'Tytuł',
    accessor: 'title',
    filterable: true,
    sortable: true,
  },
  {
    id: 'recipientEmail',
    filterable: true,
    sortable: true,
    Header: 'Adres odbiorcy',
    accessor: row => <>{row?.recipientEmail}</>,
  },
  {
    id: 'contractor.id',
    filterable: true,
    sortable: false,
    Header: 'Kontrahent',
    ...createModuleColumnConfig('contractor', Contractor),
  },
  {
    id: 'createdBy.id',
    filterable: true,
    sortable: false,
    Header: 'Wygenerował',
    ...createModuleColumnConfig('createdBy', Employee),
  },
  {
    id: 'createdAt',
    Header: 'Data wygenerowania',
    accessor: ({ createdAt }) => (createdAt ? dayjs(createdAt).locale('pl').format('LLL') : null),
    filterable: false,
    sortable: true,
    minWidth: 160,
  },
];

const module: CTMModule<Inquiry> = {
  id: 'f712fc0b-3162-46a5-8d24-75b09a0b265f',
  dataClass: 'CTM\\Contractor\\Entity\\Inquiry',
  urlPrefix: 'Contractor-Inquiry',
  name: 'Oferty od dostawców',
  role: 'CONTRACTOR_CONTRACTOR',
  api: {
    item: {
      get: ({ id }) => `/contractor/inquiries/${id}`,
      put: ({ id }) => `/contractor/inquiries/${id}`,
      delete: ({ id }) => `/contractor/inquiries/${id}`,
    },
    collection: {
      get: `/contractor/inquiries`,
      post: `/contractor/inquiries`,
    },
  },
  recordLabel: ({ name }) => name,
  form: {
    disableRemoveRecord: true,
    disableCreateNewRecord: true,
    disableEditRecord: true,
    fieldComponents: {
      products: Products,
    },
    prepareRecordToSave: record => ({
      ...record,
    }),
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'createdAt', desc: true }],
  },
};

export default module;

import Loader from '@Components/Theme/Common/Loader';
import { Language as LanguageModel } from '@Core/Language';
import { LanguageModule } from '@Modules/CTMModules';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store';
import { rootSetLanguage } from 'store/Root/Root';

const IconMap = {
  en: 'gb',
};

const LanguagePicker = ({ isReadonly, backgroundColor = 'transparent', border = '1px solid rgb(206, 212, 218)' }) => {
  const selectedLanguage = useAppSelector(state => state.Root.language) as string;
  const dispatch = useDispatch();
  const handleLanguagePick = useCallback((language: string) => {
    dispatch(rootSetLanguage(language));
    setAnchorEl(null);
  }, []);

  const { data: languages, isLoading } = useQuery<Partial<LanguageModel>[]>(
    ['allLanguages'],
    () => LanguageModule.api.getAll({ params: { pagination: false } }).then(res => res['hydra:member']),
    {
      cacheTime: 10 * 60 * 1000,
      staleTime: 10 * 60 * 1000,
      refetchOnWindowFocus: true,
      refetchInterval: false,
    },
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  if (isLoading || !languages) {
    return <Loader />;
  }

  return (
    <div>
      <div
        onClick={handleClick}
        style={{
          width: 35,
          height: '20px',
          border: border,
          backgroundColor: backgroundColor,
          position: 'relative',
          borderRadius: '4px',
          marginRight: 5,
          overflow: 'hidden',
          cursor: 'pointer',
        }}
        title={'Zmień język pola'}
      >
        <div
          className={`fi fi-${IconMap?.[selectedLanguage] ?? selectedLanguage}`}
          style={{
            width: '90%',
            height: 'calc(100% - 2px)',
            position: 'absolute',
            left: '5%',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          &nbsp;
        </div>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuList>
          {languages.map(language => (
            <MenuItem key={language.isoCode} onClick={() => language.isoCode && handleLanguagePick(language.isoCode)}>
              <ListItemIcon>
                <span>
                  <div
                    className={`fi fi-${IconMap?.[language.isoCode ?? ''] ?? language.isoCode}`}
                    style={{
                      width: '30px',
                      height: '20px',
                    }}
                  >
                    &nbsp;
                  </div>
                </span>
              </ListItemIcon>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </div>
  );
};

export default LanguagePicker;

import useErrorHandler from '../../../../helpers/FormHandler';
import TextEditor from './TextEditor';
import { Employee } from '@Employee/Employee/Types/Employee';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TaskModule } from 'Modules/CTMModules';
import dayjs from 'dayjs';
import { FC, useState } from 'react';

interface TaskComment {
  id: string;
  comment: string;
  employee: Employee<string>;
  commentedAt: string;
}

interface NewCommentProps {
  taskIRI: string;
  onCommentAdded: (TaskComment) => void;
}

const NewComment: FC<NewCommentProps> = props => {
  const errorHandler = useErrorHandler();
  const [value, setValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  const onSave = () => {
    setIsLoading(true);
    TaskModule.api.custom
      .addComment(props.taskIRI, value)
      .then(res => {
        setIsLoading(false);
        setValue('');
        props.onCommentAdded(res);
      })
      .catch(err => {
        setIsLoading(false);
        errorHandler(err);
      });
  };

  return (
    <Box sx={{ border: '1px solid #3b4153', padding: '6px' }}>
      Dodaj komentarz:
      <TextEditor disabled={isLoading} value={value} onChange={handleChange} />
      <div style={{ textAlign: 'right' }}>
        <Button disabled={isLoading} style={{ border: '1px #dddddd solid' }} onClick={onSave}>
          Zapisz komentarz
        </Button>
      </div>
    </Box>
  );
};

interface TaskTabCommentsProps {
  taskIRI: string;
  comments: TaskComment[];
}

const TaskTabComments: FC<TaskTabCommentsProps> = props => {
  const [comments, setComments] = useState<TaskComment[]>(props.comments);

  const onCommentAdded = comment => {
    setComments([comment, ...comments]);
  };

  return (
    <div className={'comments'}>
      <NewComment taskIRI={props.taskIRI} onCommentAdded={onCommentAdded} />
      <Timeline
        position="right"
        onResize={console.log}
        onResizeCapture={console.log}
        sx={{
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: 0.1,
          },
        }}
      >
        {comments.map(comment => (
          <TimelineItem key={comment.id}>
            <TimelineOppositeContent sx={{ m: 'auto 0' }} align="right" variant="body2" color="text.secondary">
              {dayjs(comment.commentedAt).locale('pl').format('LLL')}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot>
                <Avatar>
                  {comment.employee.image && (
                    <>
                      <img
                        src={comment.employee.image}
                        style={{ maxWidth: '100%' }}
                        alt={`${comment.employee.firstName} ${comment.employee.lastName}`}
                      />
                    </>
                  )}
                  {!comment.employee.image && (
                    <>
                      {comment.employee.firstName?.substring(0, 1)}
                      {comment.employee.lastName?.substring(0, 1)}
                    </>
                  )}
                </Avatar>
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Typography variant="h6" component="span">
                {comment.employee?.firstName} {comment.employee?.lastName}
              </Typography>
              <br />
              <div
                style={{
                  wordWrap: 'break-word',
                  display: 'inline-block',
                  width: '100%',
                  border: '1px solid #3b4153',
                  padding: 6,
                  borderRadius: 10,
                }}
              >
                <div className="editor ck-content" dangerouslySetInnerHTML={{ __html: comment.comment }} />
              </div>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </div>
  );
};
export default TaskTabComments;

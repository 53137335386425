import { InvoiceGroupProps } from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceDefaultSection';
import InvoiceField from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceField';
import { Col, Container } from '@Components/Theme/Grid';
import { Paper, Typography } from '@mui/material';
import { FC } from 'react';

const InvoiceDateFieldsGroup: FC<InvoiceGroupProps> = props => {
  return (
    <Paper sx={{ marginBottom: 2, minHeight: 180 }}>
      <Container>
        <Col xs={12}>
          <Typography variant="subtitle1" align="center">
            Daty/Terminy
          </Typography>
        </Col>
        <Col xs={12} md={3}>
          <InvoiceField {...props} fieldKey={'issueDate'} />
        </Col>
        <Col xs={12} md={3}>
          <InvoiceField {...props} fieldKey={'sellDate'} />
        </Col>
        <Col xs={12} md={3}>
          <InvoiceField {...props} fieldKey={'paymentDeadline'} />
        </Col>
        <Col xs={12} md={3}>
          <InvoiceField {...props} fieldKey={'paymentDate'} />
        </Col>
      </Container>
    </Paper>
  );
};

export default InvoiceDateFieldsGroup;
